import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom'
/* Dashboard */
import Dashboard from 'Components/Dashboard/Dashboard';
/* My Account */
import MyAccount from 'Components/MyAccount/MyAccount';
/* Staff */
import ViewStaff from 'Components/Staff/ViewStaff/ViewStaff';
import StaffSearch from 'Components/Staff/StaffSearch/StaffSearch';
/* Support  */
import Support from 'Components/Support/Support';
/* Error Handling */
import Error404 from 'Components/Common/ErrorHandling/Error404';
import { handleAppError } from 'Actions/UI/UI';

class AdminRouteSwitcher extends React.Component {
    
    componentDidUpdate(prevProps) {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            this.props.changePath(this.props.location.pathname);
        }
    }

    render() {
        const { pageTitle, scrollToTop } = this.props;
        return (
            <Switch>
                {/* Dashboard */}
                <Route exact path='/'                                                               render={(props) => <Dashboard                           {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path='/dashboard'                                                      render={(props) => <Dashboard                           {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* My Account */}
                <Route exact path='/my-account/:tab'                                                render={(props) => <MyAccount                           {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path='/my-account'                                                     render={(props) => <MyAccount                           {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* Staff */}
                <Route exact path='/staff/:id'                                                      render={(props) => <ViewStaff                           {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                <Route exact path='/staff'                                                          render={(props) => <StaffSearch                         {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
                                
                {/* Support */}
                <Route exact path='/support'                                                        render={(props) => <Support                             {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />

                {/* 404 - Page Not Found */}
                <Route                                                                              render={(props) => <Error404                            {...props} pageTitle={pageTitle} scrollToTop={scrollToTop} />} />
            </Switch>
        );
    }
  
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError: (error, message) => dispatch(handleAppError(error, message))
    };
}

export default withRouter(connect(null, mapDispatchToProps)(AdminRouteSwitcher));