import React from 'react';
import _ from 'lodash';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FormControl, InputLabel } from '@material-ui/core';

const WYSIWYG = ({onChange, editorState, height, label, margin}) => (
    <FormControl margin={margin ?? 'normal'} fullWidth>
        {!_.isEmpty(label) && (
            <InputLabel shrink={true} style={{display: 'block'}}>{label}</InputLabel>
        )}
        <FormControl margin="normal" fullWidth style={{marginTop: 18}}>
            <Editor
                editorState={editorState}
                editorStyle={{border: '1px solid #ddd', paddingLeft: 10, paddingRight: 10, height: height ? height : undefined}}
                onEditorStateChange={onChange}
                toolbar={{
                    options: [
                        'inline',
                        'list',
                        'link',
                        'history'
                    ],
                    inline: {
                        options: ['bold', 'italic', 'underline', 'superscript']
                    },
                }}
                toolbarStyle={{
                    padding: 0,
                    margin: 0,
                    border: 'none',
                    marginLeft: -4
                }}
            />
        </FormControl>
    </FormControl>
)

export default WYSIWYG;