import React from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MaterialLink from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Pagination from './Pagination';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import CopyToClipboardDatagrid from 'Components/Common/CopyToClipboard/CopyToClipboardDatagrid';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { API_URL } from 'Constants';
import { pdfFromBase64 } from 'Helpers/PDFHelper';
import { cloneObject, currencyFormat } from 'Functions/MiscFunctions';
import { getToken } from 'Functions/AuthFunctions';

const styles = theme => ({
    "@global": {
        ".a, .a:active, a:visited": {
            textDecoration: 'none',
        }
    },
    alternatingRowColours: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.background.default,
      },
    },
    listIcon: {
        minWidth: 24,
        marginLeft: 8
    },
    searchTextField: {
        marginLeft: 6, 
        marginTop: 8,
        borderColor: theme.palette.grey[100]
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
});

const TableHeaderCell = withStyles(theme => ({
    head: {
        color: theme.palette.grey[500],
        fontSize: 12,
        fontWeight: 'bold',
        padding: '0 12px 10px 12px'
    },
    body: {
      fontSize: 14,
      whiteSpace: 'nowrap'
    }
}))(TableCell);

const SortableTableHeaderCell = withStyles(theme => ({
    head: {
        color: theme.palette.grey[500],
        fontSize: 12,
        fontWeight: 'bold',
        padding: '0 12px 10px 12px',
        "&:hover": {
            color: '#000',
            cursor: 'pointer',
        }
    },
    body: {
      fontSize: 14,
      whiteSpace: 'nowrap'
    }
}))(TableCell);

const TableBodyCell = withStyles(theme => ({
    body: {
        padding: '12px 12px',
        whiteSpace: 'nowrap',
        color: 'inherit'
    }
}))(TableCell);

const TableBodyCellNoPadding = withStyles(theme => ({
    body: {
        padding: 0,
        color: 'inherit'
    }
}))(TableCell);

const TableBodyCellDense = withStyles(theme => ({
    body: {
        padding: '4px 12px',
        whiteSpace: 'nowrap',
        color: 'inherit'
    }
}))(TableCell);

const initialState = {
    activeColumns: [],
    contextMenu: {
        columnMenu: false,
        filterMenu: false,
        helpAndSupportMenu: false,
        printMenu: false
    },
    exportData: {
        allRows: [],
        displayedRows: [],
        headers: [],
        excelMenu: false,
        pdfMenu: false,
    },
    pagination: {
        page: 0,
        rowsPerPage: 10
    },
    responsiveActionsColumns: null,
    responsiveActionsRow: null,
    rows: [],
    sort: {
        dataRef: '',
        dataOrder: '',
    }
}

class DataTable extends React.Component {
    constructor(props) {
        super(props);
        this.filterMenu = React.createRef();
        this.columnMenu = React.createRef();
        this.excelMenu = React.createRef();
        this.pdfMenu = React.createRef();
        this.printMenu = React.createRef();
        this.helpAndSupportMenu = React.createRef();
        this.state = initialState;
    }
    componentDidMount = () => {
        let exportHeaders = [];
        let activeColumns = [];
        _.each(this.props.columns, column => {
            if(!column.actions && column.heading) {
                exportHeaders.push({ label: column.heading, key: column.dataRef, enabled: true })
                activeColumns[column.dataRef] = true;
            }
        });
        this.setState({
            activeColumns: activeColumns,
            exportData: {
                ...this.state.exportData,
                headers: exportHeaders
            },
            pagination: {
                ...this.state.pagination,
                rowsPerPage: this.props.config.rowsPerPage ? this.props.config.rowsPerPage : this.state.pagination.rowsPerPage,
            },
            rows: this.props.rows
        },
        () => {
            if(this.props.config.options && this.props.config.options.export)
                this.handleExportData();
        })
    }
    componentDidUpdate = (prevProps) => {
        if(this.props.rows !== prevProps.rows) {
            let rows = this.props.rows;
            let dataOrder = this.state.sort.dataOrder === '' ? 'DESC' : this.state.sort.dataOrder;
            if(this.state.sort.dataRef !== '') {
                const sortRef = this.state.sort.dataRef.split('.');
                if(!_.isEmpty(rows)) {
                    rows.sort((a,b) => {
                        let sourceA = a[sortRef[0]];
                        let sourceB = b[sortRef[0]];
                        if(sortRef.length > 1) {
                            for(let i = 1; i < sortRef.length; i++) {
                                sourceA = sourceA[sortRef[i]];
                                sourceB = sourceB[sortRef[i]];
                            }
                        }
                        sourceA = sourceA === null ? '-' : sourceA; 
                        sourceB = sourceB === null ? '-' : sourceB; 
                        if(!isNaN(sourceA) && !isNaN(sourceB)) {
                            return sourceA - sourceB
                        } else {
                            return sourceA.toString().localeCompare(sourceB) 
                        }
                    })
                }
                if(dataOrder === 'ASC')
                    rows.reverse();
            }   
            this.setState({
                sort: {
                    ...this.state.sort,
                    dataOrder: dataOrder
                },
                pagination: {
                    ...this.state.pagination,
                    page: 0
                },
                rows: rows,
            },
            () => {
                if(this.props.config.options && this.props.config.options.export)
                    this.handleExportData();
            })
        }
    }
    handleChangePage = (event, page) => {
        this.setState({ 
            pagination: { 
                ...this.state.pagination, 
                page: page 
            } 
        },
        () => { 
            if(this.props.config.options && this.props.config.options.export)
                this.handleExportData();
        });
    };  
    handleChangeRowsPerPage = event => {
        this.setState({ pagination: { ...this.state.pagination, rowsPerPage: event.target.value, page: 0 } });
    };
    handleResponsiveActionsOpen = (columns, row) => {
        this.setState({
            ...this.state,
            responsiveActionsColumns: columns,
            responsiveActionsRow: row
        })
    }
    handleResponsiveActionsClose = (e) => {
        this.setState({
            ...this.state,
            responsiveActionsColumns: null,
            responsiveActionsRow: null
        })
    }    
    handleColumnToggle = (dataRef) => {
        let exportHeaders = [];
        let activeColumns = this.state.activeColumns;
        activeColumns[dataRef] = !activeColumns[dataRef];
        _.each(this.props.columns, column => {
            if(activeColumns[column.dataRef])
                exportHeaders.push({ label: column.heading, key: column.dataRef })
        });
        this.setState({
            activeColumns: activeColumns,
            exportData: {
                ...this.state.exportData,
                headers: exportHeaders
            }
        })
    }
    handleExportData = () => {
        const { config, columns } = this.props;
        const { pagination } = this.state;  
        let allRows = [];
        let displayedRows = [];
        let rows = _.values(cloneObject(this.state.rows));            
        if(typeof rows !== "undefined" || _.size(rows) > 0) {
            allRows = rows;
        }         
        _.each(columns, field => {
            if(field.fieldFormat) {
                allRows = _.map(allRows, row => {                
                    row[field.dataRef] = this.handleExportFormat(field.fieldFormat, row[field.dataRef])
                    return row;
                })
            }
        });    
        displayedRows = (config.pagination && allRows.slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage)) || allRows; 
        this.setState({
            exportData: {
                ...this.state.exportData,
                allRows: allRows,
                displayedRows: displayedRows
            }
        })
    }
    handleExportFormat = (format, data) => {    
        if( format === 'boolean') {
            if(data === 'active')
                data = 'Active'                        
            if(data === 'inactive')
                data = 'Inactive'
            if(data === 'true' || data === true)
                data = 'Y'                        
            if(data === 'false' || data === false)
                data = 'N'                                       
            if(data > 0)
                data = 'Y'                                    
            if(data === 0)
                data = 'N'       
        } else if (format === 'datetime') {
            data = moment(new Date(data)).format("H:mm DD/MM/YYYY")
        } 
        else if (format === 'date') {
            data = moment(new Date(data)).format("DD/MM/YYYY")
        }
        else if (format.startsWith('percentage')) {
            data = parseFloat(data).toFixed(format.includes(':') ? format.split(":")[1] : 2) + '%'
        } 
        else if(format.startsWith('decimal')) {
            data = parseFloat(data).toFixed(format.includes(':') ? format.split(":")[1] : 2)
        }
        return data;
    }
    handleExportMenuClick = (menu, type) => {
        /* Close Export Menu */
        this.setState({
            exportData: {
                ...this.state.exportData,
                [menu]: !this.state.exportData[menu]
            }
        }, 
            () => {
                /* 
                 * CSV downloads are handled in-line via <CSVLink /> and pass a false value for the type variable here when selected
                 * If type is not false then the user has requested a pdf download; type will be either allRows (all) or displayedRows (current page)
                 */
                if(type !== false) {
                    const { config } = this.props;
                    this.handleExportPDF(type, `${config.options.export.name}-${type === 'allRows' ? 'All' : `Page${(this.state.pagination.page + 1)}`}-${moment().format("DD/MM/YYYY[-]HH:mm:ss")}.pdf`)
                }
            }
        )
    } 
    handleExportMenuToggle = (menu) => {
        this.setState({
            exportData: {
                ...this.state.exportData,
                [menu]: !this.state.exportData[menu]
            }
        })
    }
    handleExportPDF = (type, filename) => {
        const { config } = this.props;
        API.post('/reporting/pdf', {
            title: config.options.export.title,
            rows: this.state.exportData[type],
            headers: this.state.exportData.headers
        })
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, filename);
            }
        });
    }
    handleMenuToggle = (menu) => {
        this.setState({
            contextMenu: {
                ...this.state.contextMenu,
                [menu]: !this.state.contextMenu[menu]
            }
        })
    }
    handlePrint = type => {
        const { config } = this.props;
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", API_URL + '/reporting/print');
        form.setAttribute("target", "printReport");

        var token = document.createElement("input"); 
        token.setAttribute("type", "hidden");
        token.setAttribute("name", "token");
        token.setAttribute("value", btoa(getToken()));
        form.appendChild(token);

        var title = document.createElement("input"); 
        title.setAttribute("type", "hidden");
        title.setAttribute("name", "title");
        title.setAttribute("value", btoa(config.options.export.title));
        form.appendChild(title);

        var headers = document.createElement("input"); 
        headers.setAttribute("type", "hidden");
        headers.setAttribute("name", "headers");
        headers.setAttribute("value", JSON.stringify(this.state.exportData.headers));
        form.appendChild(headers);

        var rows = document.createElement("input"); 
        rows.setAttribute("type", "hidden");
        rows.setAttribute("name", "rows");
        rows.setAttribute("value", JSON.stringify(this.state.exportData[type]));
        form.appendChild(rows);
        
        document.body.appendChild(form);
        window.open('', 'printReport');
        form.submit();
        document.body.removeChild(form);
    }
    handleReset = () => {
        let activeColumns = []
        _.each(this.props.columns, column => {     
            if(!column.actions) { 
                activeColumns[column.dataRef] = true;
            }
        });
        this.setState({
            activeColumns: activeColumns,
            sort: {
                dataRef: '',
                dataOrder: '',
            }
        },
        () => {
            const { config } = this.props;
            config.options.reset()
        })
    }
    handleSort = dataRef => {
        let rows = this.state.rows;
        let dataOrder = this.state.sort.dataOrder !== '' ? this.state.sort.dataOrder : 'DESC';
        if(dataRef === this.state.sort.dataRef) {
            dataOrder = dataOrder === 'DESC' ? 'ASC' : 'DESC';
            rows.reverse();
        } else {
            const sortRef = dataRef.split('.');
            if(!_.isEmpty(rows)) {
                rows.sort((a,b) => {
                    let sourceA = a[sortRef[0]];
                    let sourceB = b[sortRef[0]];
                    if(sortRef.length > 1) {
                        for(let i = 1; i < sortRef.length; i++) {
                            sourceA = sourceA[sortRef[i]];
                            sourceB = sourceB[sortRef[i]];
                        }
                    }
                    sourceA = sourceA === null ? '-' : sourceA; 
                    sourceB = sourceB === null ? '-' : sourceB; 
                    if(!isNaN(sourceA) && !isNaN(sourceB)) {
                        return sourceA - sourceB
                    } else {
                        return sourceA.toString().localeCompare(sourceB) 
                    }
                })
            }
        }
        this.setState({
            sort: {
                dataRef: dataRef,
                dataOrder: dataOrder
            },
            rows: rows,
            pagination: {
                ...this.state.pagination,
                page: 0
            } 
        }, 
        () => {
            if(this.props.config.options && this.props.config.options.export)
                this.handleExportData();
        });
    }
    renderOptions = () => {
        const { classes, columns, config } = this.props;
        const { activeColumns, contextMenu, exportData, pagination } = this.state;
            return (
                <Grid container alignItems='center'>
                    {config.options && config.options.headingInput && (
                        <Grid item xs={12} lg={8} align={!this.props.ui.device.isMobile ? 'left' : 'center'}  style={{padding: config.options ? (config.options.minimalPadding ? '0 12px 36px 12px' : 12) : 12}}>
                            {config.options.headingInput}
                        </Grid>
                    )}
                    <Grid item xs={12} lg={(config.options && config.options.headingInput && 4) || 12} align={!this.props.ui.device.isMobile ? 'right' : 'center'}  style={{padding: config.options ? (config.options.minimalPadding ? '0 12px 6px 12px' : 12) : 12}}>
                        <>   
                            {config.options && config.options.reset && (
                                <Tooltip title="Reset">
                                    <IconButton onClick={() => this.handleReset()} style={{marginLeft: 12}}>
                                        <FAIcon type="light" icon="undo" size="small" noMargin button/>
                                    </IconButton>
                                </Tooltip>
                            )}                             
                            {config.options && config.options.dataRef && ( 
                                <>
                                    <Tooltip title="Display Columns">
                                        <IconButton ref={this.columnMenu} onClick={() => this.handleMenuToggle('columnMenu')} style={{marginLeft: 12}}>
                                            <FAIcon type="light" icon="line-columns" size="small" noMargin button/>
                                        </IconButton>
                                    </Tooltip>
                                    <Popper open={contextMenu.columnMenu} anchorEl={this.columnMenu.current}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={() => this.handleMenuToggle('columnMenu')}>
                                                <MenuList style={{padding:24}}>
                                                    <Typography variant="subtitle1" color="primary">Display Columns</Typography><br />
                                                    {_.map(columns, (column, idx) => {
                                                        if(!column.actions && column.heading)
                                                            return (
                                                                <FormControlLabel
                                                                    checked={activeColumns[column.dataRef]} 
                                                                    control={<Checkbox color="primary" onChange={() => this.handleColumnToggle(column.dataRef)}/>}
                                                                    key={idx}
                                                                    label={column.heading}
                                                                    style={{display: 'block', fontSize: 5}}        
                                                                />
                                                            )
                                                    })}  
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Popper>     
                                </>                           
                            )}
                            {config.options && config.options.export && config.options.export.print && ( 
                                <>
                                    <Tooltip title="Print">
                                        <IconButton ref={this.printMenu} onClick={() => this.handleMenuToggle('printMenu')} style={{marginLeft: 12}}>
                                            <FAIcon type="light" icon="print" size="small" noMargin button/>
                                        </IconButton>
                                    </Tooltip>
                                    <Popper open={contextMenu.printMenu} anchorEl={this.printMenu.current}>
                                        <Paper style={{width: 200, maxWidth: 300}}>
                                            <ClickAwayListener onClickAway={() => this.handleMenuToggle('printMenu')}>
                                                <MenuList>
                                                    <Typography variant="subtitle1" style={{padding: '18px 24px'}} color="primary">Print</Typography>
                                                    <MenuItem onClick={() => { this.handleMenuToggle('printMenu'); this.handlePrint('allRows')}}>  
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <FAIcon type="light" icon="print" size="small" noMargin />
                                                        </ListItemIcon>
                                                        All Results
                                                    </MenuItem>
                                                    <MenuItem onClick={() => { this.handleMenuToggle('printMenu'); this.handlePrint('displayedRows')}}>  
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <FAIcon type="light" icon="print" size="small" noMargin />
                                                        </ListItemIcon>  
                                                        Current Page ({(pagination.page + 1)})
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Popper>
                                </>
                            )}
                            {config.options && config.options.export && config.options.export.pdf && (
                                <>
                                    <Tooltip title="Export to PDF">
                                        <IconButton ref={this.pdfMenu} onClick={() => this.handleExportMenuToggle('pdfMenu')} style={{marginLeft: 12}}>
                                            <FAIcon type="light" icon="file-pdf" noMargin button style={{color: '#d32f2f'}} />
                                        </IconButton>
                                    </Tooltip>
                                    <Popper open={exportData.pdfMenu} anchorEl={this.pdfMenu.current}>
                                        <Paper style={{width: 200, maxWidth: 300}}>
                                            <ClickAwayListener onClickAway={() => this.handleExportMenuToggle('pdfMenu')}>
                                                <MenuList>
                                                    <Typography variant="subtitle1" style={{padding: '18px 24px'}} color="primary">Export to PDF</Typography>
                                                    <MenuItem onClick={() => this.handleExportMenuClick('pdfMenu', 'allRows')}>  
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <FAIcon type="light" icon="download" size="small" noMargin />
                                                        </ListItemIcon>
                                                        All Results
                                                    </MenuItem>
                                                    <MenuItem onClick={() => this.handleExportMenuClick('pdfMenu', 'displayedRows')}>  
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <FAIcon type="light" icon="download" size="small" noMargin />
                                                        </ListItemIcon>  
                                                        Current Page ({(pagination.page + 1)})
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Popper>
                                </>
                            )}          
                            {config.options && config.options.export && config.options.export.excel && (
                                <>
                                    <Tooltip title="Export to Excel">
                                        <IconButton ref={this.excelMenu} onClick={() => this.handleExportMenuToggle('excelMenu')} style={{marginLeft: 12}}>
                                            <FAIcon type="light" icon="file-excel" noMargin button style={{color: '#388E3C'}} />
                                        </IconButton>
                                    </Tooltip>
                                    <Popper open={exportData.excelMenu} anchorEl={this.excelMenu.current}>
                                        <Paper style={{width: 200, maxWidth: 300}}>
                                            <ClickAwayListener onClickAway={() => this.handleExportMenuToggle('excelMenu')}>
                                                <MenuList>
                                                    <Typography variant="subtitle1" style={{padding: '18px 24px'}} color="primary">Export to Excel</Typography>
                                                    <CSVLink
                                                        data={exportData.allRows}
                                                        headers={exportData.headers}
                                                        filename={`${config.options.export.name}-All-${moment().format("DD/MM/YYYY[-]HH:mm:ss")}.csv`}
                                                        target={config.options.export.target ? config.options.export.target : '_blank'}
                                                    >
                                                        <MenuItem style={{color: '#000'}} onClick={() => this.handleExportMenuClick('excelMenu', false)}>        
                                                            <ListItemIcon className={classes.listIcon}>
                                                                <FAIcon type="light" icon="download" size="small" noMargin />
                                                            </ListItemIcon>
                                                            All Results
                                                        </MenuItem>
                                                    </CSVLink>                                                                      
                                                    <CSVLink
                                                        data={exportData.displayedRows}
                                                        headers={exportData.headers}
                                                        filename={`${config.options.export.name}-Page${(this.state.pagination.page + 1)}-${moment().format("DD/MM/YYYY[-]HH:mm:ss")}.csv`}
                                                        target={config.options.export.target ? config.options.export.target : '_blank'}                 
                                                    >
                                                        <MenuItem style={{color: '#000'}} onClick={() => this.handleExportMenuClick('excelMenu', false)}>        
                                                            <ListItemIcon className={classes.listIcon}>
                                                                <FAIcon type="light" icon="download" size="small" />
                                                            </ListItemIcon>      
                                                            Current Page ({(pagination.page + 1)})
                                                        </MenuItem>
                                                    </CSVLink>                                                                      
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Popper>
                                </>
                            )}                                          
                            {config.options && config.options.helpAndSupport && (
                                <>
                                    <Tooltip title="Help and Support">
                                        <IconButton ref={this.helpAndSupportMenu} onClick={() => this.handleMenuToggle('helpAndSupportMenu')} style={{marginLeft: 12}}>
                                            <FAIcon type="light" icon="question" size="small" noMargin button />
                                        </IconButton>
                                    </Tooltip>
                                    <Popper open={contextMenu.helpAndSupportMenu} anchorEl={this.helpAndSupportMenu.current}>
                                        <ClickAwayListener onClickAway={() => this.handleMenuToggle('helpAndSupportMenu')}>
                                            <Paper style={{maxWidth: 330, padding: 24}}>
                                                {config.options.helpAndSupport}
                                            </Paper>
                                        </ClickAwayListener>
                                    </Popper>
                                </>
                            )}                        
                        </>
                    </Grid>
                </Grid>
            )
        
    }
    renderHeader() {
        const { config, columns, rows } = this.props;
        return (
            <>                  
                <TableHead>
                    <TableRow>
                        {_.map(columns, column => {
                            const HeaderCellElement = config.plainHeader ? TableCell : (column.dataRef ? SortableTableHeaderCell : TableHeaderCell);
                            return (
                                ((((this.props.ui.device.isMobile && (column.actions || (config.responsiveImportance && column.important !== true))) || column.hidden === true) || this.state.activeColumns[column.dataRef] === false) && (
                                    <React.Fragment key={uuidv4()} />
                                )) || (column.dataRef && (
                                    <HeaderCellElement key={uuidv4()} onClick={() => this.handleSort(column.sortRef || column.dataRef)} style={{color: this.state.sort.dataRef === column.dataRef && '#ef3340', width: (column.actions || column.sizeToContent) && '1%', whiteSpace: (column.actions || column.sizeToContent) && 'nowrap', textAlign: (column.actions && 'center') || (column.alignment && column.alignment)}} {...(typeof config.headerCellProps === "function" && config.headerCellProps(column, rows))}>
                                        {(column.actions && !column.actionsCustomHeader && 'Actions') || column.heading}
                                        <FAIcon type="duo" icon={this.state.sort.dataOrder === 'DESC' ? 'sort-down' : 'sort-up'} style={{color: this.state.sort.dataRef === column.dataRef && '#ef3340', marginLeft: 3, width: 13, height: 13}} />
                                    </HeaderCellElement>
                                )) || (
                                    <HeaderCellElement key={uuidv4()} style={{width: (column.actions || column.sizeToContent) && '1%', whiteSpace: (column.actions || column.sizeToContent) && 'nowrap', textAlign: (column.actions && 'center') || (column.alignment && column.alignment)}} {...(typeof config.headerCellProps === "function" && config.headerCellProps(column, rows))}>
                                        {(column.actions && !column.actionsCustomHeader && 'Actions') || column.heading}
                                    </HeaderCellElement>
                                )
                            )
                        })}
                    </TableRow>
                </TableHead>
            </>
        );
    }
    renderBody() {
        const { pagination } = this.state;
        const { config, columns } = this.props;
        let rows = _.values(this.state.rows);
        if(typeof rows === "undefined" || _.size(rows) === 0) {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={columns.length} style={{textAlign: 'center'}}>
                            <Typography variant="body2">
                                {(config.noResultsText && (
                                    config.noResultsText
                                )) || (
                                    'No results were found'
                                )}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        } else {
            
                for (let i = 0, l = _.size(rows); i < l; i++) {
                    rows[i]['rowIdx'] = i;
                    rows[i]['rowNumber'] = i + 1;
                    rows[i]['rowNumberReversed'] = (_.size(rows) - i);
                }
            
            rows = (config.pagination && rows.slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage)) || rows;
            return (
                <TableBody>
                    {_.map(rows, (row, bodyIdx) => {
                        return this.renderRow(row, bodyIdx)
                    })}
                </TableBody>
            )
        }
    }
    renderRow(row, bodyIdx, indentDepth = 0) {
        const { classes, config, columns } = this.props;
        let TableCellElement = config.dense ? TableBodyCellDense : (config.noPadding ? TableBodyCellNoPadding : TableBodyCell);
        if(!this.props.ui.device.isMobile) {
            _.map(columns, (column, colIdx) => {
                if(column.actions && config.dense !== false) {
                    TableCellElement = TableBodyCellDense;
                }
            })
        }
        let skipColumns = 0;
        let onDoubleClick = false;
        _.each(columns, column => {
            if(column.actions) {
                if(column.actions(row).length === 1) {
                    let action = column.actions(row)[0];
                    if(!action.disabled) {
                        if(action.link) {
                            onDoubleClick = () => this.props.history.push(action.link)
                        } else if(action.onClick) {
                            onDoubleClick = () => action.onClick(row);
                        }                          
                    }
                } else {
                    _.each(column.actions(row), action => {
                        if(action.doubleClick) {
                            if(!action.disabled) {
                                if(action.link) {
                                    onDoubleClick = () => this.props.history.push(action.link)
                                } else if(action.onClick) {
                                    onDoubleClick = () => action.onClick(row);
                                }                          
                            }
                        }
                    })
                }
            }
        })

        return (
            <React.Fragment key={`fragment${bodyIdx}`}>
            <TableRow key={`body2${bodyIdx}`} className={`${config.alternatingRowColours && classes.alternatingRowColours}${onDoubleClick ? ' link' : ''}`} {...(config.rowProps && config.rowProps(row))} onClick={() => {(this.props.ui.device.isMobile && this.handleResponsiveActionsOpen(columns, row))}}  onDoubleClick={() => {(onDoubleClick && onDoubleClick())}} hover={!config.noRowHover}>
                    {_.map(columns, (column, colIdx) => {
                        if(skipColumns > 0) {
                            skipColumns = skipColumns - 1;
                            return <React.Fragment key={`skip-${colIdx}`} />
                        } else {
                            let cellProps = column.cellProps && column.cellProps(row);
                            if(cellProps) {
                                cellProps = {
                                    ...cellProps,
                                    style: {
                                        ...cellProps.style,
                                        textAlign: column.alignment && column.alignment
                                    }
                                }
                            } else {
                                cellProps = {
                                    style: {
                                        textAlign: column.alignment && column.alignment
                                    }
                                }
                            }
                            if(column.truncate) {
                                cellProps.style = {
                                    ...cellProps.style,
                                    textOverflow: 'ellipsis',
                                    overflowX: 'hidden',
                                    whiteSpace: 'nowrap',
                                    maxWidth: 1
                                }
                            }
                            if(column.colSpan && column.colSpan(row) > 1 && !(this.props.ui.device.isMobile && config.responsiveImportance && column.important !== true)) {
                                skipColumns = column.colSpan(row) - 1;
                            }
                            
                            return (
                                (column.actions && (
                                    (this.props.ui.device.isMobile && (
                                        <React.Fragment />
                                    )) || (
                                        <TableCellElement style={{textAlign: 'center'}} key={`col${colIdx}`}>
                                            {column.actions(row).map((action, actnIdx) => {
                                                let ButtonElement = (action.label && Button) || IconButton;
                                                return (
                                                    (action.type && action.type === "copyToClipboard" && (
                                                        <CopyToClipboardDatagrid key={`col${colIdx}${actnIdx}`} text={action.data} iconSize='medium' disabled={action.disabled} />
                                                    )) || 
                                                    (action.disabled && (
                                                        (!action.hideIfDisabled && (
                                                            <ButtonElement size={action.btnSize} disabled key={`col${colIdx}${actnIdx}`}>
                                                                {action.icon && <FAIcon type="light" icon={action.icon} color={action.color} size={action.size} button noMargin={!action.label} disabled />} {action.label && action.label}
                                                            </ButtonElement>
                                                        )) || (
                                                            <React.Fragment />
                                                        )
                                                    )) || (                                                        
                                                        (action.link && (
                                                            <Link to={`${action.link}`} style={{textDecoration: 'none'}} key={`col${colIdx}${actnIdx}`}>
                                                                <Tooltip title={action.name}>
                                                                    <ButtonElement size={action.btnSize} >
                                                                        {action.icon && <FAIcon type="light" icon={action.icon} color={action.color} size={action.size} button noMargin={!action.label} />} {action.label && action.label}
                                                                    </ButtonElement>
                                                                </Tooltip>
                                                            </Link>
                                                        )) || (action.linkExternal && (
                                                                <MaterialLink href={`${action.linkExternal}`} target='_blank' style={{textDecoration: 'none'}} key={`col${colIdx}${actnIdx}`}>
                                                                    <Tooltip title={action.name}>
                                                                        <ButtonElement size={action.btnSize} >
                                                                            {action.icon && <FAIcon type="light" icon={action.icon} color={action.color} size={action.size} button noMargin={!action.label} />} {action.label && action.label}
                                                                        </ButtonElement>
                                                                    </Tooltip>
                                                                </MaterialLink>                                                            
                                                        )) || (action.onClick && (
                                                            <i onClick={() => action.onClick(row)} key={`col${colIdx}${actnIdx}`}>
                                                                <Tooltip title={action.name}>
                                                                    <ButtonElement size={action.btnSize} >
                                                                        {action.icon && <FAIcon type="light" icon={action.icon} color={action.color} size={action.size} button noMargin={!action.label} />} {action.label && action.label}
                                                                    </ButtonElement>
                                                                </Tooltip>
                                                            </i>
                                                        )) || (
                                                            action.label && 
                                                            action.label
                                                        )
                                                    )
                                                )
                                            })}
                                        </TableCellElement>
                                    )
                                )) || (
                                    ((((this.props.ui.device.isMobile && config.responsiveImportance && column.important !== true) || column.hidden === true) || this.state.activeColumns[column.dataRef] === false) && (
                                        <React.Fragment key={uuidv4()} />
                                    )) || (
                                        <TableCellElement colSpan={(column.colSpan && column.colSpan(row) > 1 && column.colSpan(row)) || 1} {...cellProps} key={`col${colIdx}`}>
                                            {config.nesting && column.nestingDropdown && ((row.children && (
                                                <>
                                                    <div style={{display: 'inline-block', width: (30 * indentDepth) + 'px'}}></div>
                                                    <FAIcon type="light" icon='chevron-down' button />
                                                </>
                                            )) || (
                                                <span style={{display: 'inline-block', width: (30 * indentDepth) + 'px'}}></span>
                                            ))}
                                            {this.renderCellData(column, row)}
                                        </TableCellElement>
                                    )
                                )
                            )
                        }
                    })}
                </TableRow>
                {row.children && this.renderNestedChildren(row.children, indentDepth + 1)}
            </React.Fragment>
        )
    }
    renderNestedChildren(children, depth = 0) {
        return (
            <>
                {_.map(children, (row, bodyIdx) => {
                    return (
                        <>
                            {this.renderRow(row, bodyIdx, depth)}
                        </>
                    )
                })}
            </>
        );
    }
    renderTotals() {
        const { pagination } = this.state;
        const { classes, config, columns } = this.props;
        let TableCellElement = config.dense ? TableBodyCellDense : TableBodyCell;
        if(config.showTotals) {
            let rows = _.values(this.state.rows);
            if(typeof rows === "undefined" || _.size(rows) === 0) {
                return (
                    <React.Fragment />
                )
            } else {
                rows = (config.pagination && rows.slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage)) || rows;
                let totals = [];
                _.each(rows, function (row) {
                    _.each(columns, function (column, idx) {
                        if(column.showTotal) {
                            if(column.totalFormat === 'decimal') {
                                let total = (idx in totals && totals[idx]) || 0;
                                let colTotal = ((typeof column.totalField === 'function' && Number(column.totalField(row))) || Number((column.field(row))));
                                totals[idx] = (parseFloat(total) + parseFloat(colTotal)).toFixed(2);
                            } else {
                                totals[idx] = ((idx in totals && totals[idx]) || 0) + ((typeof column.totalField === 'function' && Number(column.totalField(row))) || Number((column.field(row))));
                            }
                        } else {
                            if(column.showTotalLabel) {
                                totals[idx] = 'Total';
                            } else {
                                totals[idx] = '';
                            }
                        }
                    });
                });
                return (
                    <>
                        <TableBody>
                            <TableRow className={config.alternatingRowColours && classes.alternatingRowColours} key='totals'>
                                {_.map(columns, (column, idx) => {
                                    return (
                                        (this.props.ui.device.isMobile && config.responsiveImportance && column.important !== true && (
                                            <React.Fragment />
                                        )) || (
                                            <TableCellElement key={`totals-${idx}`} style={{textAlign: column.alignment && column.alignment, fontWeight: '500'}}>
                                                {(column.showTotal && (
                                                    <>
                                                        {(column.overwriteTotal && (
                                                            <>
                                                                {column.overwriteTotal}
                                                            </>
                                                        )) ||
                                                        (
                                                            <>
                                                                {column.fieldPrefix}
                                                                {
                                                                (column.fieldFormat === 'currency' && (
                                                                    currencyFormat.format(totals[idx])
                                                                )) ||
                                                                (column.fieldFormat === 'datetime' && (
                                                                    moment(new Date(totals[idx])).format("HH:mm [on] DD/MM/YYYY")
                                                                )) ||
                                                                (column.fieldFormat === 'date' && (
                                                                    moment(new Date(totals[idx])).format("DD/MM/YYYY")
                                                                )) ||
                                                                (column.fieldFormat && column.fieldFormat.startsWith('percentage') && (
                                                                    parseFloat(totals[idx]).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2) + '%'
                                                                )) ||
                                                                (column.fieldFormat && column.fieldFormat.startsWith('decimal') && (
                                                                    parseFloat(totals[idx]).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2)
                                                                )) ||
                                                                (column.totalFormatText && (
                                                                    `${totals[idx]}${column.totalFormatText}`
                                                                )) ||
                                                                    totals[idx]
                                                                }
                                                                {column.fieldSuffix}
                                                            </>
                                                        )}
                                                    </>
                                                )) ||
                                                (column.showTotalLabel && (
                                                    <>
                                                        {totals[idx]}
                                                    </>
                                                ))}
                                            </TableCellElement>
                                        )
                                    )
                                })}
                            </TableRow>
                        </TableBody>
                    </>
                )
            }
        }
    }
    renderFullTotals() {
        const { classes, config, columns } = this.props;
        let TableCellElement = config.dense ? TableBodyCellDense : TableBodyCell;
        if(config.showFullTotals) {
            let rows = _.values(this.state.rows);
            if(typeof rows === "undefined" || _.size(rows) === 0) {
                return (
                    <React.Fragment />
                )
            } else {
                let totals = [];
                _.each(rows, function (row) {
                    _.each(columns, function (column, idx) {
                        if(column.showTotal) {
                            totals[idx] = ((idx in totals && totals[idx]) || 0) + ((typeof column.totalField === 'function' && Number(column.totalField(row))) || Number((column.field(row))));
                        } else {
                            if(column.showTotalLabel) {
                                totals[idx] = 'Total';
                            } else {
                                totals[idx] = '';
                            }
                        }
                    });
                });
                return (
                    <>
                        <TableBody>
                            <TableRow className={config.alternatingRowColours && classes.alternatingRowColours} key='totals'>
                                {_.map(columns, (column, idx) => {
                                    return (
                                        <TableCellElement style={{textAlign: column.alignment && column.alignment, fontWeight: 'bold'}}>
                                            {(column.showTotal && (
                                                <>
                                                    {(column.overwriteTotal && (
                                                        <>
                                                            {column.overwriteTotal}
                                                        </>
                                                    )) ||
                                                    (
                                                        <>
                                                            {column.fieldPrefix}
                                                            {
                                                            (column.fieldFormat === 'currency' && (
                                                                currencyFormat.format(totals[idx])
                                                            )) ||
                                                            (column.fieldFormat === 'datetime' && (
                                                                moment(new Date(totals[idx])).format("HH:mm [on] DD/MM/YYYY")
                                                            )) ||
                                                            (column.fieldFormat === 'date' && (
                                                                moment(new Date(totals[idx])).format("DD/MM/YYYY")
                                                            )) ||
                                                            (column.fieldFormat && column.fieldFormat.startsWith('percentage') && (
                                                                parseFloat(totals[idx]).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2) + '%'
                                                            )) ||
                                                            (column.fieldFormat && column.fieldFormat.startsWith('decimal') && (
                                                                parseFloat(totals[idx]).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2)
                                                            )) ||
                                                                totals[idx]
                                                            }
                                                            {column.fieldSuffix}
                                                        </>
                                                    )}
                                                </>
                                            )) ||
                                            (column.showTotalLabel && (
                                                <>
                                                    {totals[idx]}
                                                </>
                                            ))}
                                        </TableCellElement>
                                    )
                                })}
                            </TableRow>
                        </TableBody>
                    </>
                )
            }
        }
    }
    renderFooter() {
        const { config, columns, rows } = this.props;
        const { pagination } = this.state;
        return (
            <TableFooter>
                <TableRow>
                    {config.pagination && _.size(rows) > 0 && (
                        <Pagination
                            colSpan={columns.length}
                            count={_.size(rows)}
                            rowsPerPage={(config.defaultRowsPerPage && config.defaultRowsPerPage) || pagination.rowsPerPage}
                            page={pagination.page}
                            onChangePage={(this.handleChangePage)}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            isMobile={this.props.ui.device.isMobile}
                            noRowSelector={config.noRowSelector}
                            plainPagination={config.plainPagination}
                            basicPagination={config.basicPagination}
                        />
                    )}
                </TableRow>
            </TableFooter>
        );
    }
    renderTooltip(field, text){
        return(
            <Tooltip
                title={
                    <Typography variant="subtitle">{text}</Typography>
                }
                placement="left"
            >
                <div>
                    {field}
                </div>
            </Tooltip>
        );
    }
    renderCellData(column, row, responsiveDialog = false) {
        let field = (
            (responsiveDialog && (
                <>
                    <Typography variant="body2">
                        {column.heading}:
                    </Typography>
                    <Typography variant="body1" style={{marginBottom: 16}}>
                        {column.fieldPrefix}
                        {
                        (column.fieldFormat === 'currency' && (
                            currencyFormat.format(column.field(row))
                        )) ||
                        (column.fieldFormat === 'boolean' && (
                            (column.field(row) === 'active' && (
                                'Active'                        
                            )) || (column.field(row) === 'inactive' && (
                                'Inactive'
                            )) || ((column.field(row) === 'true' || column.field(row) === true) && (
                                'Y'                        
                            )) || ((column.field(row) === 'false' || column.field(row) === false) && (
                                'N'                                       
                            )) || (column.field(row) > 0 && (
                                'Y'                                    
                            )) || (column.field(row) === 0 && (
                                'N'       
                            )) || (
                                column.field(row)
                            )
                        )) ||
                        (column.fieldFormat === 'datetime' && (
                            isNaN(Date.parse(column.field(row))) ? '-' : moment(new Date(column.field(row))).format("HH:mm [on] DD/MM/YYYY")
                        )) ||
                        (column.fieldFormat === 'date' && (
                            isNaN(Date.parse(column.field(row))) ? '-' : moment(new Date(column.field(row))).format("DD/MM/YYYY")
                        )) ||
                        (column.fieldFormat && column.fieldFormat.startsWith('percentage') && (
                            parseFloat(column.field(row)).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2) + '%'
                        )) ||
                        (column.fieldFormat && column.fieldFormat.startsWith('decimal') && (
                            parseFloat(column.field(row)).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2)
                        )) ||
                            column.field(row)
                        }
                        {column.fieldSuffix}
                    </Typography>
                </>
            )) || (
                <>
                    {column.fieldPrefix}
                    {(column.fieldFormat === 'boolean' && (
                        (column.field(row) === 'Y' && (
                            <FAIcon type="light" icon="check" size="small" style={{color: '#2E7D32'}} />   
                        )) || (column.field(row) === 'N' && (
                            <FAIcon type="light" icon="times" size="small" style={{color: '#c62828'}} /> 
                        )) || (column.field(row) === 'active' && (
                            <FAIcon type="light" icon="check" size="small" style={{color: '#2E7D32'}} />   
                        )) || (column.field(row) === 'inactive' && (
                            <FAIcon type="light" icon="times" size="small" style={{color: '#c62828'}} />   
                        )) || ((column.field(row) === 'true' || column.field(row) === true) && (
                            <FAIcon type="light" icon="check" size="small" style={{color: '#2E7D32'}} />                       
                        )) || ((column.field(row) === 'false' || column.field(row) === false) && (
                            <FAIcon type="light" icon="times" size="small" style={{color: '#c62828'}} />                                    
                        )) || (column.field(row) > 0 && (
                            <FAIcon type="light" icon="check" size="small" style={{color: '#2E7D32'}} />                                  
                        )) || (column.field(row) === 0 && (
                            <FAIcon type="light" icon="times" size="small" style={{color: '#c62828'}} />      
                        )) || (
                            column.field(row)
                        )
                    )) ||
                    (column.fieldFormat === 'currency' && (
                        currencyFormat.format(column.field(row))
                    )) ||
                    (column.fieldFormat === 'datetime' && (
                        isNaN(Date.parse(column.field(row))) ? '-' : moment(new Date(column.field(row))).format("HH:mm [on] DD/MM/YYYY")
                    )) ||
                    (column.fieldFormat === 'date' && (
                        isNaN(Date.parse(column.field(row))) ? '-' : moment(new Date(column.field(row))).format("DD/MM/YYYY")
                    )) ||
                    (column.fieldFormat && column.fieldFormat.startsWith('percentage') && (
                        parseFloat(column.field(row)).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2) + '%'
                    )) ||
                    (column.fieldFormat && column.fieldFormat.startsWith('decimal') && (
                        parseFloat(column.field(row)).toFixed(column.fieldFormat.includes(':') ? column.fieldFormat.split(":")[1] : 2)
                    )) ||
                        column.field(row)
                    }
                    {column.fieldSuffix}
                </>
            )
        )
        if(column.tooltip) {
            return this.renderTooltip(field, column.tooltip(row));
        } else {
            return field;
        }
    }
    renderResponsiveDialog = () => {
        let mainColumnFound = false;
        return (
            <Dialog
                open={Boolean(this.state.responsiveActionsRow)}
                onClose={this.handleResponsiveActionsClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {_.map(this.state.responsiveActionsColumns, (column, colIdx) => {
                        if(column.main)
                            mainColumnFound = true;
                        return column.main && this.renderCellData(column, this.state.responsiveActionsRow)
                    })}
                    {!mainColumnFound && (
                        this.renderCellData(this.state.responsiveActionsColumns[0], this.state.responsiveActionsRow)
                    )}
                    <IconButton aria-label="Close" className={this.props.classes.closeButton} onClick={this.handleResponsiveActionsClose}>
                        <FAIcon type="light" icon='times' noMargin />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component="div">
                        {_.map(this.state.responsiveActionsColumns, (column, colIdx) => {
                            return !column.actions && !column.hideInResponsiveDialog && this.renderCellData(column, this.state.responsiveActionsRow, true)
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {_.map(this.state.responsiveActionsColumns, (column, colIdx) => {
                        return (
                            (column.actions &&
                                <>
                                    {column.actions(this.state.responsiveActionsRow).map((action, actnIdx) => {
                                        return (
                                            <>
                                                {(action.disabled &&
                                                    <Button disabled key={`col${colIdx}${actnIdx}`}>
                                                        {action.icon && <FAIcon type="light" icon={action.icon} size={action.size} button disabled />} {action.name}
                                                    </Button>
                                                ) || (
                                                    (action.link && (
                                                        <Link to={`${action.link}`} style={{textDecoration: 'none'}} key={`col${colIdx}${actnIdx}`}>
                                                            <Button>
                                                                {action.icon && <FAIcon type="light" icon={action.icon} size={action.size} button />} {(action.label && action.label) || action.name}
                                                            </Button>
                                                        </Link>
                                                    )) || (
                                                        action.onClick && 
                                                        <i onClick={() => { this.handleResponsiveActionsClose(); action.onClick(this.state.responsiveActionsRow) }} key={`col${colIdx}${actnIdx}`}>
                                                            <Button>
                                                                {action.icon && <FAIcon type="light" icon={action.icon} size={action.size} button />} {(action.label && action.label) || action.name}
                                                            </Button>
                                                        </i>
                                                    )
                                                )}
                                            </>
                                        )
                                    })}
                                </>
                            )
                        )
                    })}
                </DialogActions>
            </Dialog>
        )
    }
    render() {
        const { config } = this.props;
        return (
            <div style={{maxWidth: '100%', overflowX: 'auto'}}>                
                <div style={{border: config.withBorder && '1px solid rgba(224, 224, 224, 1)', borderRadius: config.withBorderRadius && '4px'}}>                         
                    {(!config.noHeader && !config.inline) && this.renderOptions()}    
                    {config?.options?.action ?? ''}    
                    {(config.isLoading === true && (
                        <LoadingCircle />
                    )) || (
                        <Typography variant="body2" component={Table}>
                            {!config.noHeader && this.renderHeader()}
                            {this.renderBody()}
                            {this.renderTotals()}
                            {this.renderFullTotals()}
                            {this.renderFooter()}
                        </Typography>
                    )}
                    {Boolean(this.state.responsiveActionsRow) && this.renderResponsiveDialog()}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        ui: state.ui,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(withRouter(DataTable)));