import React from 'react';

import Grid from '@material-ui/core/Grid';

import Tile from 'Components/Common/Tiles/Tile';

const ViewStaffActionTiles = props => (
    <>
        <Grid item xs={12} lg={6}>
            <Tile
                icon="alarm-exclamation"
                color="#d32f2f"
                title="Overdue Actions"
                content={props.overdue}
            />
        </Grid>
        <Grid item xs={12} lg={6}>
            <Tile
                icon="alarm-exclamation"
                color="#FF6F00"
                title="Outstanding Actions"
                content={props.outstanding}
            />
        </Grid>
        <Grid item xs={12} lg={6}>
            <Tile
                icon="alarm-clock"
                color="#2196F3"
                title="Actions Completed Today"
                content={props.today}
            />
        </Grid>
        <Grid item xs={12} lg={6}>
            <Tile
                icon="alarm-clock"
                color="#2E7D32"
                title="Total Actions Completed"
                content={props.total}
            />
        </Grid>
    </>
)

export default ViewStaffActionTiles;