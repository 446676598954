import React  from 'react';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import NotificationList from 'Components/Common/Notifications/NotificationList';
import UpdateStaffMfa from '../Staff/UpdateStaff/UpdateStaffMfa';
import ChangeStaffPasswordForm from '../Staff/UpdateStaff/ChangeStaffPasswordForm';
import UploadStaffProfilePhotoForm from '../Staff/UpdateStaff/UploadStaffProfilePhotoForm';
import ViewStaffActionTiles from '../Staff/ViewStaff/ViewStaffActionTiles';
import ViewStaffCreated from '../Staff/ViewStaff/ViewStaffCreated';
import ViewStaffAuditLog from '../Staff/ViewStaff/ViewStaffAuditLog';
import ViewStaffMfaStatus from '../Staff/ViewStaff/ViewStaffMfaStatus';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import Avatar from 'Components/Common/Badges/Avatar';
import { handleTabChange, toggleDialog } from 'Functions/MiscFunctions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}

const styles = theme => ({
    tab: {
        color: '#fff',
        opacity: 1
    },
    tabIndicator: {
        backgroundColor: '#fff'
    },
    card: {
        color: '#fff',
        height: '270px',
        padding: '20px'
    },
    cardContent: {
        padding: '0 16px 16px 16px'
    }
})

const initialState = {
    currentTab: 0,
    dialog: {
        updateAvatar: false,
        updatePassword: false,
    },
    staff: {},
    isLoading: true,
}

class MyAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleTabChange = handleTabChange.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
    }

    componentDidMount() {  
        /* Important for dialog callback function */
        // this.loadComponentData()
        this.props.pageTitle([0, 'My Account']);
        if(this.props.match?.params?.tab) {
            let currentTab = 0;
            if(this.props.match.params.tab === 'notifications') {
                currentTab = 3
                this.setState({
                    currentTab
                })
            }
        }
    }
    
    loadComponentData = () => {
        API.get('/staff/my/profile', { params: { myAccount: true }})
        .then(result => {
            this.setState({
                ...this.state,
                staff: result.data,
                isLoading: false
            });
        })
    }
    
    render() {
        const { classes, loggedInUser, scrollToTop } = this.props;
        const { currentTab, dialog, isLoading, staff } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid container item spacing={3}>
                    <Grid item xs={12}>
                        {(isLoading && (
                            <Paper>
                                <LoadingCircle />
                            </Paper>
                        )) || (
                            <>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3} alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={3} alignItems="center">
                                                    <Grid item style={{padding: '8px 8px 0 12px'}}>
                                                        <Avatar loggedInUser={loggedInUser} size='lg' />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant="h6">
                                                            {staff.staff_first_name} {staff.staff_last_name}
                                                        </Typography>  
                                                        <Typography variant="caption" component="div">
                                                            {staff.staff_email} 
                                                        </Typography>  
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    size="small" 
                                                    onClick={() => this.toggleDialog('updateAvatar')}
                                                >
                                                    <FAIcon type="light" icon="pencil-alt" size="small" button />
                                                    Change Avatar
                                                </Button>
                                            </Grid>    
                                            <Grid item>
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    size="small" 
                                                    onClick={() => this.toggleDialog('updatePassword')}
                                                >
                                                    <FAIcon type="light" icon="pencil-alt" size="small" button />
                                                    Change Password
                                                </Button>
                                            </Grid>    
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{padingTop: 0}}>
                                        <Paper>
                                            <AppBar color="primary" position="static" elevation={0} >
                                                <Tabs classes={{indicator: classes.tabIndicator}} variant='scrollable' value={currentTab} onChange={this.handleTabChange}>
                                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Overview" />
                                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={3} label="Notifications" />
                                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="Multi-Factor Authentication" />
                                                    <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label="Login History" />
                                                </Tabs>   
                                            </AppBar>   
                                        </Paper>
                                    </Grid>     
                                    <Grid item xs={12}>  
                                        <TabPanel value={currentTab} index={0}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} lg={6}>
                                                    <Grid container spacing={3}>     
                                                        <ViewStaffActionTiles
                                                            today={staff.actions_completed_today}
                                                            outstanding={staff.actions_outstanding}
                                                            overdue={staff.actions_overdue}
                                                            total={staff.actions_total}
                                                        />   
                                                        <Grid item xs={12} lg={6}>
                                                            <ViewStaffMfaStatus status={[staff.staff_mfa,staff.staff_mfa_activation_datetime]} isloading={isLoading ? true : undefined} />                                                        
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <ViewStaffCreated staff={staff} isLoading={isLoading} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={currentTab} index={1}>
                                            <UpdateStaffMfa myAccount={true} staff={staff} staffId={staff.staff_id} scrollToTop={scrollToTop} callback={() => this.componentDidMount()} />
                                        </TabPanel>
                                        <TabPanel value={currentTab} index={2}>
                                            <Paper>
                                                <ViewStaffAuditLog staff={staff} isLoading={isLoading} />
                                            </Paper>
                                        </TabPanel>
                                        <TabPanel value={currentTab} index={3}>
                                            <NotificationList />
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                                <SmallFormDialog
                                    open={dialog.updateAvatar}
                                    onClose={() => this.toggleDialog('updateAvatar')}
                                    title="Change Avatar"
                                    content={<UploadStaffProfilePhotoForm staffId={staff.staff_id} toggleDialog={() => this.toggleDialog('updateAvatar')} />}
                                    maxWidth='xs'
                                />
                                <SmallFormDialog
                                    open={dialog.updatePassword}
                                    onClose={() => this.toggleDialog('updatePassword')}
                                    title="Change Password"
                                    content={<ChangeStaffPasswordForm staffId={staff.staff_id} myPassword={true} toggleDialog={() => this.toggleDialog('updatePassword')} />}
                                    maxWidth='xs'
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

function mapStateToProps(state){
    return {
        loggedInUser: state.auth.user,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(MyAccount));