import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

const PaddedError = ({inline, children}) => (
    <PaddedPaper elevation={inline ? 0 : undefined} noPadding={inline && true}>
        <Grid container spacing={1} alignItems='center'>
            {!inline && (
                <Grid item>
                    <FAIcon type="light" icon="exclamation-circle" color="error" noMargin />
                </Grid>
            )}
            <Grid item xs>
                <Typography variant="body2">
                    {children}
                </Typography>
            </Grid>
        </Grid>
    </PaddedPaper>
)

export default PaddedError;