import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const Textarea = (props) => (
    <FormControl margin={props.margin || 'none'} fullWidth>
        <TextField
            ref={props.innerRef}
            inputRef={props.inputRef}
            id={props.id}
            color={props.color}
            className={props.className}
            name={props.name}
            label={props.label}
            value={props.value}
            rows={props.rows}
            rowsMax={props.rowsMax || Infinity}
            error={props.error && true}
            helperText={props.error || props.helperText}
            onChange={props.onChange}
            multiline
            fullWidth
            style={props.style}
            InputProps={props.InputProps}
            InputLabelProps={props.InputLabelProps}
            disabled={props.disabled}
            variant={props.variant}
            placeholder={props.placeholder}
            onKeyDown={props.onKeyDown}
        />
    </FormControl>
)

export default Textarea;