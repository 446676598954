import decode from 'jwt-decode';

// Staff token authorisation functions
export function isLoggedIn() {
    const token = getToken();
    return !!token && !isTokenExpired(token);
}

export function isAdmin() {
    const token = getDecodedToken();
    return token?.type === 'ADMIN' ? true : false;
}

export function isClient() {
    const token = getDecodedToken();
    return token?.type === 'CLIENT' ? true : false;
}

export function getToken() {
    return localStorage.getItem('staff_auth_token');
}

export function setToken(token) {
    localStorage.setItem('staff_auth_token', token);
}

export function clearToken() {
    localStorage.removeItem('staff_auth_token');
}

export function getDecodedToken(){    
    const token = getToken();
    if(token) {
        const decoded = decode(token);
        if(decoded.exp < Date.now() / 1000) {
            return false;
        } else {
            return decoded;
        }
    } else {
        return false;
    }
}

export function isTokenExpired(token) {
    try {
        const decoded = decode(token);
        if (decoded.exp < Date.now() / 1000) { // Checking if token is expired.
            return true;
        }
        else
            return false;           
    }
    catch (err) {
        return false;
    }
}

// Login staff profile storage functions
export function hasStoredProfile(){
    const profile = getStoredProfile();
    return !!profile;
}

export function getStoredProfile(){
    return JSON.parse(localStorage.getItem('staff_stored_profile'));
}

export function setStoredProfile(profile) {
    localStorage.setItem('staff_stored_profile', JSON.stringify(profile));
}

export function forceStoredProfile(){
    if(hasStoredProfile()) {
        const profile = getStoredProfile();
        if(profile.active === false){
            const savedProfile = {
                ...profile,
                temp: true,
            }
            setStoredProfile(savedProfile);
        }        
    }
}

export function clearStoredProfile() {
    localStorage.removeItem('staff_stored_profile');
}

// Last active page
export function hasLastActivePage(){
    const path = getLastActivePage();
    return !!path;
}

export function getLastActivePage(){
    return localStorage.getItem('staff_last_page');
}

export function setLastActivePage(path) {
    if(path !== '/messaging') {
        localStorage.setItem('staff_last_page', path);
    }
}

export function clearLastActivePage() {
    localStorage.removeItem('staff_last_page');
}