import API from 'API'

export const getPresignedUrl = url => {
    API.post('/cloud/presignedUrl', 
        { 
            download: true, 
            request: btoa(url)
        }
    )
    .then(result => {
        if(result?.data?.url) {
            window.open(atob(result.data.url));
        }
    });
}