import { combineReducers } from 'redux';
import AuthReducer from 'Reducers/Auth/AuthReducer';
import CiServiceWorkerReducer from 'Reducers/CiServiceWorker/CiServiceWorkerReducer';
import ConfirmationReducer from 'Reducers/Confirmation/ConfirmationReducer';
import DialogReducer from 'Reducers/Dialog/DialogReducer';
import LoadingReducer from 'Reducers/Loading/LoadingReducer';
import PulseReducer from 'Reducers/Pulse/PulseReducer';
import SnackBarReducer from 'Reducers/SnackBar/SnackBarReducer';
import SoundReducer from 'Reducers/Sounds/SoundReducer';
import UIReducer from 'Reducers/UI/UIReducer';

const rootReducer = combineReducers({
    auth: AuthReducer,
    loading: LoadingReducer,
    ciSw: CiServiceWorkerReducer,
    pulse: PulseReducer,
    confirmation: ConfirmationReducer,
    dialog: DialogReducer,
    snackbar: SnackBarReducer,
    sound: SoundReducer,
    ui: UIReducer
});

export default rootReducer;