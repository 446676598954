import React from 'react';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const ThreadOpenGraph = ({openGraph:{og}, openGraph, handleClearOpenGraph}) => (
    <Box mt={1}>
        <Grid container alignItems='center' spacing={1}>
            <Grid item xs>
                <Card>
                    {(og === "email" && (
                        <>
                            <CardContent>
                                <Grid container alignItems='center'>
                                    <Grid item xs align='left'>
                                        <Typography variant="body1">
                                            {openGraph.name}
                                        </Typography>
                                        <Typography variant="caption" component="div">
                                            {openGraph.email}
                                        </Typography>
                                        <Typography variant="caption" component="div" color="textSecondary">
                                            {openGraph.type}
                                        </Typography>
                                    </Grid>
                                    <Grid item className="pl-2">
                                        <CardActions>
                                            <Link className="textDefault" href={`mailto:${openGraph.email}`}>
                                                <Button size="small">
                                                    <FAIcon type="light" icon="envelope" button /> E-mail
                                                </Button>
                                            </Link>
                                        </CardActions>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </>
                    )) || (og === "url" && (
                        <Link className="textDefault" href={!handleClearOpenGraph && '#'} onClick={() => { !handleClearOpenGraph && window.open(openGraph.url) }} target="_blank">
                            {!_.isEmpty(openGraph.image) && (
                                <CardMedia
                                    style={{height: 0, paddingTop: '56.25%'}}
                                    image={openGraph.image}
                                    title={openGraph.site_name ?? openGraph.title}
                                />
                            )}
                            <CardContent>
                                <Typography variant="body1" align='left' style={{whiteSpace: 'break-spaces'}}  className="textDefault">
                                    {openGraph.title}
                                </Typography>
                                {!_.isEmpty(openGraph.description) && (
                                    <Grid item xs={12} align='left' style={{whiteSpace: 'break-spaces'}} className="textDefault">
                                        <Typography variant="caption" component="div" gutterBottom>
                                            {openGraph.description}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12} align='left'>
                                    <Typography variant="caption" component="div" color="textSecondary">
                                        {openGraph.url}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Link>
                    ))}
                </Card>
            </Grid>
            {handleClearOpenGraph && (
                <Grid item>
                    <IconButton onClick={handleClearOpenGraph}>
                        <FAIcon type="light" icon="times" noMargin button />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    </Box>
)

export default ThreadOpenGraph;