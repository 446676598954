import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import AddStaffForm from '../AddStaff/AddStaffForm';
import Avatar from 'Components/Common/Badges/Avatar';
import { toggleDialog } from 'Functions/MiscFunctions';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';

const initialState = {
    searchResults: {},
    searchString: '',
    access: {
        updateStaff: false,
        staffAccess: false,
        viewStaff: false,
        addStaff: false
    },
    dialog: {
        addStaff: false
    },
    dataLoading: true
}

class StaffSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.toggleDialog = toggleDialog.bind(this);
        this.timeout = false;
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'Staff']);
        this.getSearchData(true);
        this.getAccess();  
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    loadComponentData = () => {
        /* Callback for add staff */
        this.getSearchData(false);
    }

    getAccess = () => {
        Promise.all([
            API.access('add-staff'),
            API.access('update-staff'), 
            API.access('view-staff'),
            API.access('staff-access')
        ])
        .then(([addStaffRes, updateStaffRes, viewStaffRes, staffAccessRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    updateStaff: (updateStaffRes.data && updateStaffRes.data.has_access) || false,
                    viewStaff: (viewStaffRes.data && viewStaffRes.data.has_access) || false,
                    staffAccess: (staffAccessRes.data && staffAccessRes.data.has_access) || false,
                    addStaff: (addStaffRes.data && addStaffRes.data.has_access) || false
                }
            });
        })
    }

    getSearchData = (dataLoading) => {
        this.setState({
            dataLoading
        }, () => {
            API.get('/staff/all', { 
                props: {
                    cancellation: true
                },
                params: { 
                    searchString: this.state.searchString 
                } 
            })
            .then(result => {
                if(result && result.data) {
                    this.setState({
                        dataLoading: false,
                        searchResults: result.data
                    });
                } 
            });
        });
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.setSearch();
        });
    }

    resetSearch = () => {
        this.setState({
            searchString: ''
        }, 
        () => {
            this.getSearchData(true);
        });
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.getSearchData(true), 200);
    }

    render() {
        const { access, dataLoading, dialog } = this.state;
        return (
            <>
                <Grid container spacing={1}>      
                    <Grid container item xs={12} alignItems='center'>
                        <Grid item xs={4} style={{marginBottom: 12}}>
                            <TextField 
                                fullWidth
                                onChange={this.onSearchStringChange}
                                placeholder='Search:'
                                value={this.state.searchString} 
                                variant="filled"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><FAIcon type="light" icon="search" size="small" /></InputAdornment>
                                }}
                            /> 
                        </Grid>      
                        {access.addStaff && (
                            <Grid item xs={8} align='right'> 
                                <Button 
                                    color="primary" 
                                    size="small"
                                    variant="text"
                                    onClick={() => this.toggleDialog('addStaff')}
                                >   
                                    <FAIcon type="light" icon="plus-circle" size="small" button />
                                    Add Staff Member
                                </Button>
                            </Grid>
                        )}
                    </Grid>           
                    <Grid item xs={12}>
                        <Paper>
                            <DataTable  
                                config={{
                                    key: 'staff_id',
                                    pagination: true,
                                    alternatingRowColours: true,
                                    isLoading: dataLoading,
                                    responsiveImportance: true,
                                    options: {
                                        dataRef: true,
                                        reset: this.resetSearch,
                                        export: {
                                            title: `Staff List`,
                                            name: `Staff`,
                                            excel: true,
                                            pdf: true,
                                            print: true
                                        }
                                    }
                                }}
                                columns={[
                                    {
                                        heading: '',
                                        field: rowData => {
                                            <Avatar model={rowData} />
                                        },
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Name',
                                        field: rowData => (
                                            <>
                                                {rowData.staff_first_name} {rowData.staff_last_name}
                                            </>
                                        ),
                                        dataRef: 'staff_first_name',
                                        important: true,
                                        main: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Email',
                                        field: rowData => rowData.staff_email,
                                        dataRef: 'staff_email',
                                        important: true,
                                        truncate: true
                                    },
                                    {
                                        heading: 'Active',
                                        field: rowData => rowData.staff_status,
                                        dataRef: 'staff_status',
                                        fieldFormat: 'boolean',
                                        sizeToContent: true,                                
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'MFA',
                                        field: rowData => rowData.staff_mfa,
                                        dataRef: 'staff_mfa',
                                        fieldFormat: 'boolean',
                                        sizeToContent: true,
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Breathe HR',
                                        field: rowData => rowData.staff_breathe_hr_ref,
                                        dataRef: 'staff_breathe_hr_ref',
                                        fieldFormat: 'boolean',
                                        sizeToContent: true,                                
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Quotes',
                                        field: rowData => rowData.quotes,
                                        dataRef: 'quotes',
                                        sizeToContent: true,                                
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Orders',
                                        field: rowData => rowData.orders,
                                        dataRef: 'orders',
                                        sizeToContent: true,                                
                                        alignment: 'center'
                                    },
                                    // {
                                    //     heading: 'Outstanding Actions',
                                    //     field: rowData => rowData.outstanding,
                                    //     dataRef: 'outstanding',
                                    //     sizeToContent: true,                                
                                    //     alignment: 'center'
                                    // },
                                    // {
                                    //     heading: 'Overdue Actions',
                                    //     field: rowData => rowData.overdue,
                                    //     dataRef: 'overdue',
                                    //     sizeToContent: true,                                
                                    //     alignment: 'center',
                                    //     cellProps: rowData => ({
                                    //         className: rowData.overdue > 0 && 'textError'
                                    //     })
                                    // },
                                    {
                                        heading: 'Last Quote',
                                        field: rowData => rowData?.latest_quote?.quote_created_datetime ?? '-',
                                        fieldFormat: 'date',
                                        sizeToContent: true,                                
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Last Order',
                                        field: rowData => rowData?.latest_order?.order_created_datetime ?? '-',
                                        fieldFormat: 'date',
                                        sizeToContent: true,                                
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Last Active',
                                        field: rowData => rowData.staff_last_active_datetime,
                                        dataRef: 'staff_breathe_hr_ref',
                                        fieldFormat: 'datetime',
                                        sizeToContent: true,                                
                                        alignment: 'center'
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: 'View', icon: 'binoculars', link: '/staff/' + rowData.staff_id, disabled: !access.viewStaff}
                                            ]
                                        }
                                    }
                                ]}
                                rows={this.state.searchResults}
                            />
                        </Paper>
                    </Grid>
                </Grid>
                {access.addStaff && (
                    <SmallFormDialog
                        open={dialog.addStaff}
                        onClose={() => this.toggleDialog('addStaff')}
                        title="Add Staff Member"
                        content={<AddStaffForm toggleDialog={() => this.toggleDialog('addStaff')} />}
                        maxWidth='xs'
                    />
                )}
            </>
        );
    }
}

function mapStateToProps(state){
    return {
        status: state.pulse.status
    };
}

export default connect(mapStateToProps)(StaffSearch);