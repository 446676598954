import React from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import ThreadHeader from 'Components/Messages/ThreadHeader';
import ThreadLoading from 'Components/Messages/ThreadLoading';
import ThreadMessage from 'Components/Messages/ThreadMessage';
import ThreadNew from 'Components/Messages/ThreadNew';
import ThreadSend from 'Components/Messages/ThreadSend';
import { getFormData } from 'Functions/FormFunctions';

const styles = theme => ({
    fileUpload: {
        display: 'none'
    },
    fileDetails: {
        whiteSpace: 'break-spaces',
        wordBreak: 'break-all',
        color: '#fff'
    },
    filePaper: {
        background: '#039BE5'
    },
    giphyLogo: {
        paddingLeft: 3, 
        paddingRight: 3, 
        paddingTop: 0.5, 
        paddingBottom: 0.5
    },
    giphyContainer: {
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    header: {  
        backgroundColor: '#777',
        borderBottom: '1px solid #ddd',
        color: '#fff',
        paddingLeft: 12,
        paddingRight: 16,
        height: 72.5
    },
    image: {
        width: 'auto',
        height: 200,
        borderRadius: 4,
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    container: {
        position: 'relative',
    },
    messageSent: {
        display: 'inline-block',
        maxWidth: '70%',
        backgroundColor: '#ddd',
        borderRadius: 4,
        textAlign: 'left',
        color: '#000!important',
        whiteSpace: 'break-spaces',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    messageReceived: {
        display: 'inline-block',
        maxWidth: '70%',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        textAlign: 'left',
        color: '#fff!important',
        whiteSpace: 'break-spaces',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    message: {
        wordBreak: 'break-word'
    },
    nudge: {
        background: '#673AB7'
    },
    send: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        borderTop: '1px solid #ddd',
        padding: theme.spacing(1),
        backgroundColor: '#fff',
        width: '100%'
    },
    textArea: {
        color: '#000',
    },
    thread: {
        background: '#fafafa',
        height: 'calc(100% - 238px)',
        paddingBottom: 24,
        marginBottom: 165,
        overflowX: 'hidden',
        overflowY: 'auto'
    }
})

const initialState = {
    formData: {
        message: '',
        openGraph: false,
        image: false,
        fileImg: null,
        fileMisc: null
    },
    blockOpenGraph: false,
    loading: true,
    sending: false,
    staffId: 0,
    lm: 0,
    thread: []
}

class Thread extends React.PureComponent {
    constructor(props) {
        super(props);
        this.pulse = false;
        this.scrollout = false;
        this.timeout = false;
        this.state = initialState;
        this.threadList = React.createRef();
        this.getFormData = getFormData.bind(this);
    }

    componentDidMount = () => {
        if(this.props.selectedStaff) {
            if(this.props.selectedStaff > 0) {
                this.setState({
                    staffId: this.props.selectedStaff
                }, () => {
                    this.handleGetThread();
                    this.pulse = setInterval(this.handleGetThread, 2000);
                })
            }
        }
    }

    componentDidUpdate = () => {
        if(this.state.staffId !== this.props.selectedStaff) {
            this.setState({
                formData: initialState.formData,
                // blockOpenGraph: initialState.blockOpenGraph,
                loading: true,
                staffId: this.props.selectedStaff,
                lm: 0
            }, () => {
                this.handleGetThread();
                if(!this.pulse) {
                    this.pulse = setInterval(this.handleGetThread, 2000);
                }
            })
        }
    }

    componentWillUnmount = () => {
        if(this.pulse) {
            clearInterval(this.pulse);
        }
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
        if(this.scrollout) {
            clearTimeout(this.scrollout);
        }
    }

    handleEmojiPick = (emoji, e) => {
        const { formData:{message} } = this.state;
        if(emoji?.native) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    message: `${message} ${emoji.native}`
                }
            })
        }
    }

    handleFileChange = (name, event) => {
        const file = event.target.files[0];
        if(file) {
            if(name === "fileImg") {
                const fileType = file['type'];
                const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
                if(validImageTypes.includes(fileType)) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            [name]: file,
                            image: URL.createObjectURL(file)
                        }
                    });
                } else {
                    return;
                }
            } else {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [name]: file
                    }
                });
            }
        }
    }

    handleClearImage = () => {
        if(this.state.formData.image) { 
            this.setState({
                formData: {
                    ...this.state.formData,
                    image: initialState.image,
                    fileImg: null
                }
            })
        }
    }

    handleClearFile = () => {
        if(this.state.formData.fileMisc) { 
            this.setState({
                formData: {
                    ...this.state.formData,
                    fileMisc: null
                }
            })
        }
    }

    handleGiphyPick = giphy => {
        this.setState({
            formData: {
                ...this.state.formData,
                image: giphy?.images?.downsized?.url ?? false
            }
        })
    }

    handleMessageChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                message: e.target.value
            }
        }, () => {
            const { formData:{message}, formData:{openGraph}, blockOpenGraph } = this.state;
            if(message.length > 0 && openGraph === false && !blockOpenGraph) {
                let ogPattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim,
                    ogPattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim,
                    ogPattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim,
                    ogCheck1 = message.match(ogPattern1),
                    ogCheck2 = message.match(ogPattern2),
                    ogCheck3 = message.match(ogPattern3);
                
                if(!_.isEmpty(ogCheck1)) {
                    this.handleSetOpenGraphData("url", ogCheck1[0])
                } else if(!_.isEmpty(ogCheck2)) {
                    this.handleSetOpenGraphData("url", `http://${ogCheck2[0]}`)
                } else if(!_.isEmpty(ogCheck3)) {
                    this.handleSetOpenGraphData("email", ogCheck3[0])
                }
                
            }
        })
    }

    handleClearOpenGraph = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                openGraph: false
            },
            // blockOpenGraph: true
        })
    }

    handleSetOpenGraphData = (type, resource) => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.handleGetOpenGraphData(type, resource), 250);
    }

    handleGetOpenGraphData = (type, resource) => {
        API.post(`/messaging/og`, { type, resource })
        .then(result => {
            if(result?.data) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        openGraph: result.data
                    },
                })
            }
        })
    }

    handleGetThread = (loadMessageCounts = false) => {
        const { lm, staffId } = this.state;
        if(staffId) {
            API.get(`/messaging/${staffId}`, { 
                props: { 
                    cancellation: true, 
                    noLoading: true
                }, 
                params: { 
                    lm: lm 
                }
            })
            .then(result => {
                if(result?.data) {
                    if(this.state.staffId === staffId) {
                        if(lm === 0) {
                            this.setState({
                                loading: false,
                                thread: result.data.thread ?? [],
                                lm: parseInt(result.data.lm) ?? 0
                            }, () => {
                                this.handleScroll();
                            })
                        } else {
                            if(lm !== parseInt(result.data.lm)) {
                                this.setState({
                                    thread: {
                                        ...this.state.thread,
                                        ...result.data.thread
                                    },
                                    lm: parseInt(result.data.lm) ?? 0
                                }, () => {
                                    this.handleScroll();
                                })
                            }
                        }
                    }
                    if(loadMessageCounts) {
                        this.props.getMessageCounts();
                    }
                }
            })
        }
    }

    handleScroll = () => this.scrollout = setTimeout(() => this.threadList.current.scrollTop = this.threadList.current.scrollHeight, 100)

    handleSendMessage = () => {
        const { staffId } = this.state;
        if(!this.state.sending) {
            this.setState({
                sending: true
            }, () => {
                API.post(`/messaging/${staffId}`, this.getFormData())
                .then(result => {
                    if(result?.data) {
                        this.setState({
                            formData: initialState.formData,
                            sending: false,
                            uuid: uuidv4(),
                            // blockOpenGraph: initialState.blockOpenGraph
                        }, () => {
                            this.handleGetThread(true);
                        });
                    }
                })
            })
        }
    }

    handleSendNudge = () => {
        const { staffId } = this.state;
        API.post(`/messaging/${staffId}`, { message: 'nudge' })
        .then(result => {
            if(result?.data) {
                this.handleGetThread(true);
            }
        })
    }

    render = () => {
        const { classes, contentHeight, staff, toggleDrawer } = this.props;
        const { formData, loading, sending, staffId, thread, uuid } = this.state;
        return (
            <Grid container className={classes.container} style={{height: contentHeight}}>
                <Grid item xs={12} className={classes.header}>
                    <ThreadHeader 
                        staff={staff?.[staffId]}
                        toggleDrawer={toggleDrawer}
                    />
                </Grid>
                <Grid item xs={12} className={classes.thread} ref={this.threadList}>
                    {(loading && (
                        <ThreadLoading />
                    )) || (_.isEmpty(thread) && (
                        <ThreadNew />
                    )) || (
                        <Grid container>
                            {_.map(thread, (msg, idx) => (
                                <ThreadMessage 
                                    key={idx} 
                                    classes={classes}
                                    myMessage={msg.to === staffId}
                                    from={msg.from}
                                    to={msg.to}
                                    img={msg.img}
                                    fileName={msg.fn}
                                    fileSize={msg.fs}
                                    fileUrl={msg.fu}
                                    msg={msg.msg}
                                    og={msg.og}
                                    read={msg.read}
                                    sent={msg.sent}
                                />
                            ))}
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} className={classes.send}>
                    <ThreadSend 
                        key={uuid}
                        classes={classes}
                        formData={formData}
                        handleClearFile={this.handleClearFile}
                        handleClearImage={this.handleClearImage}
                        handleClearOpenGraph={this.handleClearOpenGraph}
                        handleEmojiPick={this.handleEmojiPick}
                        handleFileChange={this.handleFileChange}
                        handleGiphyPick={this.handleGiphyPick}
                        handleImageClear={this.handleImageClear}
                        handleMessageChange={this.handleMessageChange}
                        handleSendMessage={this.handleSendMessage}
                        handleSendNudge={this.handleSendNudge}
                        sending={sending}
                    />
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        staff: state.pulse.status,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Thread));