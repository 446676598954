import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Avatar from 'Components/Common/Badges/Avatar';

const Notification = ({data, enhanced, handleDismiss, handleAction, status}) => {
    if(data) {

        let from = false, 
            icon = "info-circle", 
            color = "textInfo";

        if(status && data.f > 0 && status[data.f]) {
            from = status[data.f];
        }

        if(data.b) {
            let b = data.b.split(":");
            if(b[0] && b[1]) {
                icon = b[0];
                color = b[1];
            }
        }

        return (
            <ListItem 
                button={data.a ? true : false}
                className={enhanced ? `list-item-borderless${!data.a ? ' list-disabled':''}` : `list-item${!data.a ? ' list-disabled':''}`}
                onClick={() => data.a && handleAction(data.i, data.a)} 
                disableGutters
                style={{borderLeft: (data.r && enhanced && ('2px solid #9E9E9E')) || (!data.r && enhanced && ('2px solid #ef3340'))}}
            >
                <Grid container spacing={2} alignItems='center'>
                    <Grid item>
                        {(from && (
                            <Avatar userStatus={from} />
                        )) || (
                            <FAIcon type="light" icon={icon} className={color} size="avatar" noMargin />
                        )}
                    </Grid>
                    <Grid item xs>
                        <Grid container alignItems={enhanced ? 'center' : 'flex-start'}>
                            <Grid item xs>
                                <Typography variant="body2">
                                    {data.t}
                                </Typography>
                                <Typography variant="caption" component="div" className="pr-1">
                                {data.c && data.c.split('\n').map((item, key) => (
                                    <span key={key}>{item}<br /></span>
                                ))}
                                </Typography>
                                <Typography variant="overline" color="textSecondary" component="div" style={{paddingTop: 8}}>
                                    {data.w}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {!data.r && (
                                    <Tooltip title="Dismiss">
                                        <IconButton
                                            onClick={(e) => handleDismiss(data.i, e)}
                                            size={!enhanced ? 'small' : 'medium'}
                                        >
                                            <FAIcon type="light" icon="times" size={!enhanced && 'small'} noMargin button />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItem>
        )

    } else {
        return <React.Fragment />
    }    
}

export default Notification;