/* 
 * Crushed Ice - React Pagination
 * Version 1.00
 */
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const actionsStyles = theme => ({
    root: {
      position: 'absolute',
      left: '50%',
      width: 300,
      marginLeft: -150,
      flexShrink: 0,
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    [theme.breakpoints.down('sm')]: {
        root: {
            marginLeft: 0,
            width: '100%',
            textAlign: 'center'
        },
    },
});

class TablePaginationActions extends React.PureComponent {
    handleFirstPageButtonClick = event => {
      this.props.onChangePage(event, 0);
    };
  
    handleBackButtonClick = event => {
      this.props.onChangePage(event, this.props.page - 1);
    };
  
    handleNextButtonClick = event => {
      this.props.onChangePage(event, this.props.page + 1);
    };
  
    handleLastPageButtonClick = event => {
      this.props.onChangePage(
        event,
        Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
      );
    };
  
    render() {
      const { classes, count, page, rowsPerPage, theme } = this.props;
  
      return (
        <div className={classes.root}>
          <IconButton
            onClick={this.handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="First Page"
          >
            {theme.direction === 'rtl' ? <FAIcon type="light" icon="step-forward" size="small" disabled={page === 0} button noMargin /> : <FAIcon type="light" icon="step-backward" size="small" disabled={page === 0} button noMargin />}
          </IconButton>
          <IconButton
            onClick={this.handleBackButtonClick}
            disabled={page === 0}
            aria-label="Previous Page"
          >
            {theme.direction === 'rtl' ? <FAIcon type="light" icon="chevron-right" size="small" disabled={page === 0} button noMargin /> : <FAIcon type="light" icon="chevron-left" size="small" disabled={page === 0} button noMargin />}
          </IconButton>
          <IconButton
            onClick={this.handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="Next Page"
          >
            {theme.direction === 'rtl' ? <FAIcon type="light" icon="chevron-left" size="small" disabled={page >= Math.ceil(count / rowsPerPage) - 1} button noMargin /> : <FAIcon type="light" icon="chevron-right" size="small" disabled={page >= Math.ceil(count / rowsPerPage) - 1} button noMargin />}
          </IconButton>
          <IconButton
            onClick={this.handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="Last Page"
          >
            {theme.direction === 'rtl' ? <FAIcon type="light" icon="step-backward" size="small" disabled={page >= Math.ceil(count / rowsPerPage) - 1} button noMargin /> : <FAIcon type="light" icon="step-forward" size="small" disabled={page >= Math.ceil(count / rowsPerPage) - 1} button noMargin />}
          </IconButton>
        </div>
      );
    }
}

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
    TablePaginationActions,
);

class Pagination extends React.PureComponent {
    render() {
        return (
            <>
                <TablePagination
                    colSpan={this.props.colSpan}
                    count={this.props.count}
                    rowsPerPage={this.props.rowsPerPage}
                    page={this.props.page}
                    onChangePage={this.props.onChangePage}
                    onChangeRowsPerPage={this.props.onChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}   
                    labelDisplayedRows={({ from, to, count }) => { return this.props.isMobile ? '' :  <Typography variant="caption" style={{position: 'absolute', left: 12, top: 16.25}}>{`${from} - ${to} of ${count}`}</Typography>}}
                    labelRowsPerPage={(this.props.isMobile || this.props.noRowSelector) ? '' : <Typography variant="caption" style={{position: 'absolute', right: 70, top: 15.25}}>Results per page</Typography>}                   
                    rowsPerPageOptions={(this.props.isMobile || this.props.noRowSelector)  ? [] : [5, 10, 25, 50, 100]} 
                    style={{borderBottom: this.props.plainPagination ? 0 : '', fontSize: '0.75rem'}}
                />
            </>
        );
    }
}
  
export default Pagination;