import React from 'react';
import { withRouter } from 'react-router';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { clearToken, setLastActivePage, forceStoredProfile, clearLastActivePage } from 'Functions/AuthFunctions';
import { handleClick, handleClose } from 'Functions/PopperFunctions';

const styles = theme => ({
    dropDownItem: {
        padding: theme.spacing(2, 3, 2, 2),
        fontSize: 12
    }
})

const initialState = {
    anchorEl: null
}

class Menu extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleClick = handleClick.bind(this);
        this.handleClose = handleClose.bind(this)
    }
    
    handleLockSession = () => {
        forceStoredProfile();        
        setLastActivePage(this.props.location.pathname);
        clearToken();
        this.props.history.push('/login');
    }

    handleLogout = () => {
        clearToken();
        clearLastActivePage();
        this.props.history.push('/login');
    }

    handleMyAccount = () => {
        this.props.history.push('/my-account');
        this.handleClose();
    }

    handleUndockMessaging = () => {
        window.open(`/messaging`,`Messaging`,`directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,resizable=no,width=958,height=750`);
        this.handleClose();
    }

    render() {
        const { classes } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            <ClickAwayListener onClickAway={this.handleClose}>
                <div>                                        
                    <IconButton
                        aria-label="More"
                        aria-owns={anchorEl ? 'dropdown-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        className={classes.iconButton}
                    >
                        <FAIcon type="light" icon='ellipsis-v' buttonPrimary noMargin />
                    </IconButton>
                    <Popper 
                        id={open ? 'dropdown-menu' : undefined} 
                        open={open}
                        anchorEl={anchorEl}
                        className="dd-menu"
                        disablePortal={true} 
                    >
                        <Paper>
                            {/* <MenuItem onClick={this.handleUndockMessaging} className={`${classes.dropDownItem} not-xs`}>
                                <FAIcon type="light" icon='comments-alt' button size={15} /> Messaging
                            </MenuItem> */}
                            <Divider />
                            <MenuItem onClick={this.handleMyAccount} className={`${classes.dropDownItem} not-xs`}>
                                <FAIcon type="light" icon='user' button size={15} /> My Account
                            </MenuItem>
                            <MenuItem onClick={this.handleLockSession} className={classes.dropDownItem}>
                                <FAIcon type="light" icon='lock-alt' button size={15} /> Lock Session
                            </MenuItem>
                            <MenuItem onClick={this.handleLogout} className={classes.dropDownItem}>
                                <FAIcon type="light" icon='sign-out-alt' button size={15} /> Logout
                            </MenuItem>
                        </Paper>
                    </Popper>
                </div>
            </ClickAwayListener>
        )
    }
}

export default withStyles(styles)(withRouter(Menu));