import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

const initialState = {
    access: [],
    isLoading: true
}

const Support = ({pageTitle}) => {

    let [state, setState] = useState(initialState);
    const { access, isLoading } = state;

    useEffect(() => {
        pageTitle([0, 'Support']);
        API.get('/access/all', 
            { 
                params: { 
                    noChildren: true
                }
            }
        )
        .then(result => {
            if(result?.data) {
                setState(state => ({
                    ...state,
                    access: result.data,
                    isLoading: false
                }))
            }
        })
    }, [pageTitle]);

    return (
        <Grid container spacing={3}>
            {(isLoading && (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            )) || (
                <>  
                    <Grid item xs={12} lg={7}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>                                            
                                            <Grid container spacing={3}>
                                                <Grid item xs>
                                                    <Typography variant="h6">
                                                        Help Guides
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={3}>                                        
                                                {_.map(_.filter(access, function(el) { return el.access_parent_id === 0 }), (item, idx) => (
                                                    <Grid item xs={6} key={idx}>
                                                        <Grid container alignItems='center'>
                                                            <Grid item>
                                                                <FAIcon type="light" icon={item.access_icon} size="small" />
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Typography variant="body2" style={{cursor: 'pointer'}}>
                                                                    {item.access_name}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <PaddedPaper>   
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        Support Desk
                                    </Typography>    
                                    <Typography variant="caption">
                                        For help and support please contact Crushed Ice
                                    </Typography>    
                                </Grid>
                                <Grid item xs={12}> 
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <FAIcon type="light" icon="phone" noMargin />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="body2">
                                                01933 664 344
                                            </Typography>  
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <FAIcon type="light" icon="envelope" noMargin />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="body2">
                                            support@crushedice.biz
                                            </Typography>  
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                </>
            )}
        </Grid>
    )
}

export default Support;