import React from 'react';
import moment from 'moment';

import DataTable from 'Components/Common/DataTables/DataTable';
class ViewStaffAuditLog extends React.Component {
    render() {
        const { isLoading, staff } = this.props;
        return (
            <DataTable  
                config={{
                    key: 'audit_id',
                    pagination: true,
                    alternatingRowColours: true,
                    isLoading: isLoading,
                    responsiveImportance: true,
                    plainPagination: true,
                    rowsPerPage: 10,
                    options: {
                        dataRef: true,
                        export: {
                            title: `${staff.staff_first_name} ${staff.staff_last_name} Login History`,
                            name: `LoginHistory${staff.staff_first_name}${staff.staff_last_name}`,
                            excel: true,
                            pdf: true,
                            print: true
                        }
                    }
                }}
                columns={[
                    {
                        heading: 'Date',
                        field: rowData => moment(rowData.audit_date_time).format("DD/MM/YY HH:mm"),
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'IP Address',
                        field: rowData => rowData.audit_ip_address,
                        important: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Type',
                        field: rowData => rowData.audit_type,  
                        sizeToContent: true,    
                        main: true     
                    },
                    {
                        heading: 'MFA Code',
                        field: rowData => rowData.audit_mfa_code === 0 ? '-' : rowData.audit_mfa_code,
                        sizeToContent: true,
                        alignment: 'center'
                    },
                    {
                        heading: 'Login Success',
                        field: rowData => rowData.audit_login_success,
                        fieldFormat: 'boolean',
                        sizeToContent: true,                                
                        alignment: 'center'
                    },
                    {
                        heading: 'IP Blocked?',
                        field: rowData => rowData.audit_ip_blocked,
                        fieldFormat: 'boolean',
                        sizeToContent: true,                                
                        alignment: 'center'
                    },
                ]}
                rows={staff.login_audit}
            />
        )
    }
}

export default ViewStaffAuditLog;