import React from 'react';
import logo from 'Assets/Images/avatar.png';

const ImageWithError = ({ alt, src, width, height, style, className, onClick}) => (
    <img 
        alt={alt ? alt : 'Image'}
        src={src ? src : 'NoImgSrcDeclaredFallback'}
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        onError={(e) => {e.target.onerror=null;e.target.src=logo}}
    />
)

export default ImageWithError;