import React from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { Avatar } from '@material-ui/core';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = () => ({
    isLoading: true,
    blog: {},
    posts: []
})

class Blog extends React.Component {

    constructor(props) {
        super(props)
        this.state = initialState()
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'Blogs']);
        this.getBlogs();
    }

    getBlogs = () => {
        const blogUuid = this.props.match.params.blogUuid;
        Promise.all([
            API.get(`/blogs/${blogUuid}`, 
                {
                    params: {
                        cd: 1
                    }
                }
            ),
            API.get(`/blogs/${blogUuid}/posts`)
        ])
        .then(([bResult, bpResult]) => {
            if(bResult?.data) {
                this.setState({
                    isLoading: false,
                    blog: bResult.data.blog,
                    posts: bpResult?.data?.posts
                }, () => {
                    this.props.pageTitle([0, 'Blogs', bResult.data.blog?.nm])
                })
            }
        })
        .catch(error => {
        })
    }

  	render() {
        const { isLoading, blog, posts } = this.state;
		return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12} lg={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Blog Posts
                                        </Typography> 
                                        <DataTable  
                                            config={{
                                                key: 'uuid',
                                                alternatingRowColours: true,
                                                responsiveImportance: true,
                                                pagination: true,
                                                plainPagination: true,
                                            }}
                                            columns={[
                                                {
                                                    heading: '',
                                                    field: rowData => (
                                                        <Avatar alt={rowData.dImg?.t || rowData.t} src={rowData.dImg?.src} size="large">
                                                            {(rowData.t.charAt(0) + rowData.t.charAt(1)).toUpperCase()}
                                                        </Avatar>
                                                    ),
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Post',
                                                    field: rowData => (
                                                        <Box pt={1} pb={1}>
                                                            <div className="fw-400">{rowData.t}</div>
                                                            <Typography variant="caption">
                                                                By {rowData.created.by}
                                                            </Typography>
                                                        </Box>
                                                    ),
                                                    important: true,
                                                    truncate: true
                                                },
                                                {
                                                    heading: 'Status',
                                                    field: rowData => (
                                                        <> 
                                                            <FAIcon
                                                                className={`${rowData.pub === 1 ? 'textSuccess' : 'textError'} mr-1`}
                                                                icon={rowData.pub === 1 ? 'check' : 'exclamation-triangle'}
                                                                size={15}
                                                            />
                                                            <span className={`mr-1`}>
                                                                {rowData.pub === 1 ? `Published` : `Draft`}
                                                            </span>
                                                        </>
                                                    ),
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Headline',
                                                    field: rowData => rowData.hl,
                                                    fieldFormat: 'boolean',
                                                    alignment: 'center',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Featured',
                                                    field: rowData => rowData.fp,
                                                    fieldFormat: 'boolean',
                                                    alignment: 'center',
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Created',
                                                    field: rowData => moment(rowData.created.raw).format('DD/MM/YYYY [at] HH:mm'),
                                                    sizeToContent: true
                                                },
                                                {
                                                    actions: rowData => {
                                                        return [
                                                            {name: 'Edit', icon: 'pencil', link: '/blogs/' + blog.uuid + '/post/' + rowData.uuid}
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={posts}
                                        />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Blog Categories
                                        </Typography> 
                                        <DataTable  
                                            config={{
                                                key: 'uuid',
                                                alternatingRowColours: true,
                                                responsiveImportance: true,
                                                pagination: true,
                                                plainPagination: true,
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Category',
                                                    field: rowData => (
                                                        <Box pt={1} pb={1}>
                                                            <div className="fw-400">{rowData.t}</div>
                                                            <Typography variant="caption" component="div">
                                                                {rowData.desc}
                                                            </Typography>
                                                            <Typography variant="caption" component="div">
                                                                <em>Slug: {rowData.slug}</em>
                                                            </Typography>
                                                        </Box>
                                                    ),
                                                    important: true,
                                                    truncate: true
                                                },
                                                {
                                                    heading: 'Posts',
                                                    field: rowData => rowData.posts,
                                                    sizeToContent: true
                                                },
                                                {
                                                    actions: rowData => {
                                                        return [
                                                            {name: 'Edit', icon: 'pencil', link: '/blogs/' + blog.uuid + '/post/' + rowData.uuid}
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={blog.c}
                                        />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
        
      }

}

export default Blog;