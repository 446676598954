import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    buttons: {
        color: '#ef3340',
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ConfirmationDialog extends React.Component {
  render() {
    return (
      <>
            <Dialog
                open={this.props.open}
                TransitionComponent={Transition}
                onClose={this.props.close}
            >
                <DialogTitle disableTypography><Typography variant="subtitle1"></Typography></DialogTitle>
                <DialogContent style={{padding: '0 24px 0 24px', minWidth: 400, maxWidth: 500}}>
                    {this.props.message && (
                        <DialogContentText variant="body2" color="textPrimary">
                            {this.props.message}
                        </DialogContentText>
                    )}
                    {this.props.content && (
                        this.props.content
                    )}
                </DialogContent>
                {this.props.message && (
                    <DialogActions>
                        <Button onClick={this.props.close} variant="text">No</Button>
                        <Button onClick={this.props.success} variant="text" color="primary">Yes</Button>
                    </DialogActions>
                )}
            </Dialog>
        </>
    );
  }
}

export default withStyles(styles)(ConfirmationDialog);