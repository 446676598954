import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import ErrorLogin from './ErrorLogin';
import ErrorUpdatePassword from './ErrorUpdatePassword';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { handleAppError } from 'Actions/UI/UI';

const initialState = {
    credible: false,
    ready: false,
    swUpdated: false
}

class ErrorDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.updateApp = false;
        this.updateCheck = false;
        this.updateComplete = false;
        this.updateStuck = false;
    }

    componentDidMount = () => {
        if(this.props.state === 'APP_UPDATE') {
            this.credible = setTimeout(
                () => {
                    this.setState({
                        credible: true
                    },
                    () => { 
                            this.updateCheck = setInterval(this.updateCheck, 2000);
                            this.updateStuck = setTimeout(() => { window.location.reload(true) }, 15000);
                    })
                }
            , 20000);           
        }
        window.addEventListener('keydown', this.handleKeyPress);
    }
    
    componentDidUpdate = () => {
        if(this.state.credible) {
            if(!this.state.swUpdated) {
                if(this.props.ciSw) {
                    this.setState({
                        ready: true,
                        swUpdated: true
                    }, 
                    () => {        

                        if(this.updateApp) 
                            clearTimeout(this.updateApp);

                        if(this.updateCheck)
                            clearInterval(this.updateCheck);

                        if(this.updateStuck) 
                            clearTimeout(this.updateStuck);        

                        this.updateComplete = setTimeout(() => { window.location.reload(true) }, 1500);
                        
                    });
                } else if(!this.props.ui.updateNotification) {    
                    if(!this.state.ready) {
                        this.setState({
                            ready: true
                        },
                        () => {         
                            if(this.updateCheck)
                                clearInterval(this.updateCheck);       

                            this.updateApp = setTimeout(
                                () => {
                                    if('serviceWorker' in navigator) {
                                        navigator.serviceWorker.ready.then(registration => {
                                            registration.update();
                                        });
                                    }
                                }
                            , 2500);
                        });
                    }
                }
            }
        }
    }
    
    componentWillUnmount = () => {
        if(this.updateApp) 
            clearTimeout(this.updateStuck);

        if(this.updateCheck)
            clearInterval(this.updateCheck);

        if(this.updateComplete) 
            clearTimeout(this.updateStuck);

        if(this.updateStuck) 
            clearTimeout(this.updateStuck);

        window.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = e => {
        let message = 'Developer Mode';
        
        if(this.props.detail && this.props.detail.config && this.props.detail.config.requestId) 
            message += '\n\nID: ' + this.props.detail.config.requestId;

        message += '\n\nError: ' + this.props.detail.message;
        
        if(this.props.detail && this.props.detail.config && this.props.detail.config.url) 
            message += '\n\nEndpoint: ' + this.props.detail.config.url;

        if(this.props.detail && this.props.detail.request && this.props.detail.request.responseURL) 
            message += '\n\nRequest: ' + this.props.detail.request.responseURL;

        if(this.props.detail && this.props.detail.config && this.props.detail.config.params) { // eslint-disable-next-line
            for (let [key, value] of Object.entries(this.props.detail.config.params)) {
                message += '\n\nParameter (' + key + '): ' + value;
            }
        }

        if(e.keyCode === 115) {
            alert(message)
        }
    }

    updateCheck = () => {
        API.get('/pulse', {
            props: {
                noLoading: true,
                ignoreErrors: true
            }
        });
    }

    getAppError = (state) => {
        switch(state) {
            case "UNAUTHORIZED":
                return [
                    "Unauthorized Access", 
                    "Please contact Crushed Ice if you need to access this part of the client area",
                    "Requested path: " + this.props.detail
                ]
            case "BAD_REQUEST":
                return [
                    "Something Went Wrong", 
                    "For help and support, please contact Crushed Ice on 01933 664 344",
                    "400 | Bad Request"
                ]
            case "FORBIDDEN":
                return [
                    "Something Went Wrong", 
                    "For help and support, please contact Crushed Ice on 01933 664 344",
                    "403 | Forbidden"
                ]    
            case "API_NOT_FOUND":
                return [                        
                    "Something Went Wrong", 
                    "For help and support, please contact Crushed Ice on 01933 664 344",
                    "404 | API Not Found"
                ]    
            case "INTERNAL_SERVER_ERROR":
                return [
                    "Something Went Wrong", 
                    "For help and support, please contact Crushed Ice on 01933 664 344",
                    "500 | Internal Server Error"
                ]                
            case "SERVICE_UNAVAILABLE":
                return [
                    "Something Went Wrong", 
                    "For help and support, please contact Crushed Ice on 01933 664 344",
                    "503 | Service Unavailable"
                ]    
            case "NETWORK_OFFLINE":
                return [
                    "Connectivity Issues", 
                    "Please check your network connection, it appears you are currently offline!", 
                    false
                ]    
            case "UNKNOWN_ERROR":
                return [
                    "Something Went Wrong", 
                    "For help and support, please contact Crushed Ice on 01933 664 344",
                    "500 | Internal Server Error | Network Error"
                ]    
            case "CLOUD_SERVICE":
                return [
                    "Something Went Wrong", 
                    "For help and support, please contact Crushed Ice on 01933 664 344",
                    "0 | Cloud Service Issue"
                ]    
            case "UPLOAD_FILE_INVALID":
                return [
                    "Invalid File Type",
                    "Please ensure you select or drop a file with a " + this.props.detail + " extension",
                    "For help and support, please contact Crushed Ice on 01933 664344"
                ]
            default:
                return ['Something Went Wrong', 'For help and support, please contact Crushed Ice on 01933 664 344', false]
        }
    }

    render(){
        const error = this.getAppError(this.props.state)
        return (
            <Dialog 
                open={this.props.state}
                maxWidth='sm' 
                style={{
                    backgroundColor: 'rgba(0,0,0,0.5)'
                }}
            >
                {(this.props.state !== "APP_UPDATE" && this.props.state !== "NETWORK_OFFLINE" && this.props.state !== "TOKEN_EXPIRED" && this.props.state !== "PASSWORD_UPDATE" && (
                    <DialogTitle disableTypography>
                        <Tooltip title='Help and Support'>
                            <IconButton aria-label="close" style={{position: 'absolute', right: 48, top: 8}} onClick={() => window.open("https://www.crushedice.biz")}>
                                <FAIcon type="light" icon="question-circle" noMargin button/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Close'>
                            <IconButton aria-label="close" style={{position: 'absolute', right: 8, top: 8}} onClick={this.props.closeError}>
                                <FAIcon type="light" icon="times" noMargin button/>
                            </IconButton>
                        </Tooltip>
                    </DialogTitle>
                )) || (
                    <DialogTitle disableTypography>
                        <Tooltip title='Help and Support'>
                            <IconButton aria-label="close" style={{position: 'absolute', right: 8, top: 8}} onClick={() => window.open("https://www.crushedice.biz")}>
                                <FAIcon type="light" icon="question-circle" noMargin button/>
                            </IconButton>
                        </Tooltip>
                    </DialogTitle>
                )}
                <DialogContent style={{padding: '75px 24px 100px 24px', width: 550, maxWidth: '100%'}}>
                    <Grid container spacing={1}>
                        {(this.props.state === 'APP_UPDATE' && (
                            <>
                                <Grid item xs={12} align='center'>                                    
                                    <CircularProgress size={50} />       
                                </Grid>
                                <Grid item xs={12} align='center'>
                                    <Typography variant="h6" gutterBottom>
                                        Software Update
                                    </Typography>
                                    <Typography variant="caption" component="div" gutterBottom>
                                        {(this.state.swUpdated === true && (
                                            `Crushed Ice Client Area has successfully updated`
                                        )) || (this.state.ready === true && this.state.swUpdated === false && (
                                            `Please wait whilst the Crushed Ice Client Area is updated on your device`
                                        )) || (
                                            `Waiting for confirmation that the update is ready to install`
                                        )}
                                    </Typography>  
                                </Grid>
                            </>
                        )) || (this.props.state === 'TOKEN_EXPIRED' && (
                            <ErrorLogin 
                                deploySnackBar={this.props.deploySnackBar}
                                handleAppError={this.props.handleAppError} 
                                history={this.props.history}
                            />
                        )) || (this.props.state === 'PASSWORD_UPDATE' && (
                            <ErrorUpdatePassword
                                deploySnackBar={this.props.deploySnackBar}
                                handleAppError={this.props.handleAppError} 
                            />
                        )) || (
                            <>
                                <Grid item xs={12} align='center'>
                                    <FAIcon type="light" 
                                        icon="exclamation-triangle"
                                        size="xlarge"
                                        style={{color: '#c62828'}}
                                        noMargin
                                        heading
                                    />
                                </Grid>
                                <Grid item xs={12} align='center'>
                                    <Typography variant="h6" style={{color: '#c62828'}} gutterBottom>
                                        {error[0]}
                                    </Typography>
                                    <Typography variant="caption" component="div" gutterBottom>
                                        {error[1]}
                                    </Typography>                            
                                    {error[2] && (
                                    <Typography variant="caption" style={{fontSize: '0.75em'}} color="textSecondary" component="div">
                                        {error[2]}
                                    </Typography>
                                    )}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }
}

function mapStateToProps(state){
    return {
        ciSw: state.ciSw.installed,
        ui: state.ui,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError: (error, message) => dispatch(handleAppError(error, message)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog);