import React from 'react';
import _ from 'lodash';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import defaultAvatar from 'Assets/Images/defaultUser.png';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        spacing: 3,
    },
    avatar: {
        width: 125,
        height: 125,
        marginBottom: 12,
    },
    [theme.breakpoints.down('sm')]: {
        root: {
            marginTop: 0,
            spacing: 2,
        },
        avatar: {
            width: 75,
            height: 75,
        },
    },
});

const StoredProfile = ({classes, darkTheme, user:{firstName, lastName, email, avatar}}) => (
    <Grid container className={classes.root}>
        <Grid item align="center" xs={12}>
            <Avatar alt={`${firstName} ${lastName}`} src={!_.isEmpty(avatar) ? avatar : defaultAvatar} className={classes.avatar} />
        </Grid>
        <Grid item align="center" xs={12}>
            <Typography variant="h5" className={darkTheme && 'textLight'}>
                {firstName} {lastName}
            </Typography>               
            <Typography variant="subtitle1" className={darkTheme && 'textLight'}>
                {email}
            </Typography>
        </Grid>
    </Grid>
)

export default withStyles(styles)(StoredProfile);