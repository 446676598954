import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import NotificationBadge from 'Components/Common/Badges/NotificationBadge';
import Notification from 'Components/Common/Notifications/Notification';
import { putNotificationStatus } from 'Actions/Pulse/Pulse';
import { handleClick, handleClose } from 'Functions/PopperFunctions';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

const initialState = {
    anchorEl: null
}

class Notifications extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleClick = handleClick.bind(this);
        this.handleClose = handleClose.bind(this);
    }

    handleAction = (id, a) => {
        this.props.putNotificationStatus(id, "read");
        this.handleClose();

        let type = a.split(':')[0],
            action = a.split(':').slice(1).join(':');

        switch(type) {
            case "url":
                this.props.history.push(action);
            break;
            case "external":
                window.open(action);
            break;
            default:
        }
    }

    handleDismiss = (id, e) => {
        e.stopPropagation();
        this.props.putNotificationStatus(id, "dismissed");
    }

    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        let { notifications, status } = this.props;
        notifications = _.sortBy(notifications, function(el) { return el.i });
        if(!_.isEmpty(notifications)) {
            notifications.reverse()
        }
        return (
            <>
                <ClickAwayListener onClickAway={this.handleClose}>
                    <div>
                        <Tooltip title="New Notifications">
                            <IconButton
                                aria-controls='notification-menu'
                                aria-haspopup="true"
                                disabled={!notifications}
                                onClick={this.handleClick}
                            >
                                {(notifications && _.size(notifications) > 0 && (
                                    <NotificationBadge
                                        badgeContent={_.size(notifications)}
                                        variant='standard'
                                        color='secondary'
                                        showZero={false}
                                    >
                                        <FAIcon type="solid" icon="bell" noMargin buttonPrimary/>
                                    </NotificationBadge>
                                )) || (
                                    <FAIcon type="solid" icon="bell" noMargin buttonPrimary/>
                                )}
                            </IconButton>
                        </Tooltip>
                        <Popper 
                            id={open ? 'notification-menu' : undefined} 
                            open={open}
                            anchorEl={anchorEl}
                            className="dd-menu"
                            disablePortal={true} 
                        >
                            <Paper>
                                {(!_.isEmpty(notifications) && (
                                    <List disablePadding className='sm-scrollbar'>
                                        {_.map(notifications, (notification, idx) => (
                                            <Notification
                                                key={idx}
                                                data={notification}
                                                handleDismiss={this.handleDismiss}
                                                handleAction={this.handleAction} 
                                                status={status}
                                            />
                                        ))}
                                        <ListItem disableGutters>
                                            <Grid container alignItems='center' className='menu-text'>
                                                <Grid item xs={12} align="right">
                                                    <Button 
                                                        color="primary"
                                                        size="small"
                                                        variant="text"
                                                        onClick={
                                                            () => { 
                                                                this.handleClose(); 
                                                                this.props.history.push('/my-account/notifications')
                                                            }
                                                        }
                                                    >
                                                        View All
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                )) || (
                                    <Grid container alignItems='center' className='menu-text'>
                                        <Grid item xs={12}>
                                            <Typography component="div" variant="caption" paragraph>
                                                You have no new notifications
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} align="right">
                                            <Button 
                                                color="primary"
                                                size="small"
                                                variant="text"
                                                onClick={
                                                    () => { 
                                                        this.handleClose(); 
                                                        this.props.history.push('/my-account/notifications')
                                                    }
                                                }
                                            >
                                                View All
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </Paper>
                        </Popper>
                    </div>
                </ClickAwayListener>
            </>
        )
    }
}

function mapStateToProps(state){
    return {
        notifications: state.pulse.notifications,
        status: state.pulse.status
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableContent, title, variant, size) => dispatch(deployDialog(content, disableContent, title, variant, size)),
        putNotificationStatus: (id, action) => dispatch(putNotificationStatus(id, action))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Notifications));