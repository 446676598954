import * as dialogActionTypes from 'Actions/Dialog/Types/Types';

export const deployDialog = (content, disableDialogContent, title, variant = 'standard', size = 'md', fullscreen = false, disableExit = false) => {
    return dispatch => {
        dispatch({
            type: dialogActionTypes.DIALOG_OPEN, 
            content, 
            disableDialogContent, 
            title, 
            variant, 
            size, 
            fullscreen,
            disableExit
        });
    }
}

export const closeDialog = () => {
    return dispatch => {
        dispatch({type: dialogActionTypes.DIALOG_CLOSE});
    }
}