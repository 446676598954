import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import NotificationBadge from 'Components/Common/Badges/NotificationBadge';

import { playSound } from 'Actions/Sounds/Sounds';

const AdminChat = ({toggleDrawer}) => {
    const dispatch = useDispatch();

    let [messageCount, setMessageCount] = useState(null);

    const   badges      = useSelector(state => state.pulse.badges),
            count       = badges?.['unread-messages'] ?? 0;

    useEffect(() => {
        if(count !== messageCount) {
            if(messageCount !== null && parseInt(count) > parseInt(messageCount))
                dispatch(playSound('msg', messageCount))
            setMessageCount(count)
        }
    }, [count, dispatch, messageCount])

    if(badges) {
        return (
            <Tooltip title="Messages">
                <IconButton
                    onClick={toggleDrawer}
                >
                    {(badges?.['unread-messages'] && (
                        <NotificationBadge
                            badgeContent={count}
                            variant='standard'
                            color='secondary'
                            showZero={false}
                        >
                            <FAIcon type="solid" icon="comments-alt" noMargin buttonPrimary/>
                        </NotificationBadge>
                    )) || (
                        <FAIcon type="solid" icon="comments-alt" noMargin buttonPrimary/>
                    )}
                </IconButton>
            </Tooltip>
        )
    } else {
        return null
    }
}

export default AdminChat;