import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import InsightChip from 'Components/Common/Chips/InsightChip';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { S3_BUCKET_URL } from 'Constants';

const styles = theme => ({
    container: {
        width: 520, 
        marginLeft: -42
    },
    paper: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginTop: 0,
        textAlign: 'left',
        maxHeight: 520,
        overflowY: 'scroll',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
    },
    header: {
        backgroundColor: '#eee'
    },
    icon: {
        border: '1px solid #fff',
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            borderColor: '#ddd'
        }
    },
    result: {
        overflowX: 'hidden',
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        }
    },
    resultGrid: {
        padding: theme.spacing(0, 2, 0, 2)
    },
    popper: {
        zIndex: 999
    },
    search: {
        margin: '0!important',
        width: 520
    }
})

const initialState = {
    anchorEl: null,
    categories: {
        quotes: false,
        orders: false,
        products: false,
        customers: false,
        suppliers: false,
        contacts: false
    },
    filter: null,
    isLoading: false,
    searchResults: [],
    searchString: ''
}

const Welcome = ({classes, handleRedirect, isLoading}) => (
    <Box p={3}>
        {(isLoading && (
            <LoadingCircle />
        )) || (
            <>
                <Typography variant="body1" gutterBottom>
                    Start typing to search...
                </Typography>
            </>
        )}
    </Box>
)

const Results = ({categories, classes, filter, handleAction, handleClose, handleFilterResults, history, searchResults, searchString}) => (
    (!_.isEmpty(searchResults) && (
        <>
            <Box pl={2} pr={2} pt={2} pb={1}>
                <Typography variant="h6" gutterBottom>
                    Search Results ({_.size(searchResults)})
                </Typography>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item xs>
                        {categories.quotes && (
                            <InsightChip
                                color={filter === "Quote" ? '#ef3340' : undefined}
                                paddingLeft={8}
                                marginRight={4}
                                onClick={() => handleFilterResults('Quote')} 
                                icon="quote"
                                label={`Quotes`}
                            />
                        )}
                        {categories.orders && (
                            <InsightChip
                                color={filter === "Sales Order" ? '#ef3340' : undefined}
                                paddingLeft={8}
                                marginRight={4}
                                onClick={() => handleFilterResults('Sales Order')} 
                                icon="order"
                                label={`Orders`}
                            />
                        )}
                        {categories.products && (
                            <InsightChip
                                color={filter === "Product" ? '#ef3340' : undefined}
                                paddingLeft={8}
                                marginRight={4}
                                onClick={() => handleFilterResults('Product')} 
                                icon="boxes"
                                label={`Products`}
                            />
                        )}
                        {categories.customers && (
                            <InsightChip
                                color={filter === "Customer" ? '#ef3340' : undefined}
                                paddingLeft={8}
                                marginRight={4}
                                onClick={() => handleFilterResults('Customer')} 
                                icon="users"
                                label={`Customers`}
                            />
                        )}
                        {categories.suppliers && (
                            <InsightChip
                                color={filter === "Supplier" ? '#ef3340' : undefined}
                                paddingLeft={8}
                                marginRight={4}
                                onClick={() => handleFilterResults('Supplier')} 
                                icon="box-check"
                                label={`Suppliers`}
                            />
                        )}
                        {categories.contacts && (
                            <InsightChip
                                color={filter === "Contacts" ? '#ef3340' : undefined}
                                paddingLeft={8}
                                marginRight={4}
                                onClick={() => handleFilterResults('Contacts')} 
                                icon="user"
                                label={`Contacts`}
                            />
                        )}
                    </Grid>
                </Grid>
            </Box>
            {_.map(searchResults, (result, idx) => (
                    <Result 
                        key={idx} 
                        classes={classes} 
                        handleAction={handleAction}
                        handleClose={handleClose}
                        history={history}
                        {...result} 
                    />
            ))}
        </>
    )) || (
        <Box pl={3} pr={3} pt={2} pb={2.5}>
            <Grid container alignItems='center'>
                <Grid item>
                    <FAIcon type="light" icon="info-circle" className="textError" size="large" />
                </Grid>
                <Grid item xs>
                    <Box pl={2.5}>
                        <Typography variant="h6" gutterBottom>
                            Sorry
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            There were no results found matching 
                        </Typography>
                        <Typography variant="body2">
                            "{searchString}"
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
)

const Result = ({action, email, history, icon, id, img, handleAction, handleClose, title, type, subtitle1, subtitle2, classes}) => (
    <Box pt={1} pb={1} className={classes.result} onClick={() => handleAction(action)}>
        <Grid container spacing={1} alignItems='center' className={classes.resultGrid}>
            <Grid item>
                {(img && (
                    <Avatar variant="square" className="mr-1" style={{background: 'transparent', width: 30, height: 30}}><ImageWithError alt="Product Image" width="100%" height="100%" src={`${S3_BUCKET_URL}${img}`} /></Avatar>
                )) || (
                    <FAIcon type="light" icon={icon} className="mr-1" style={{background: 'transparent', width: 25, height: 25, marginLeft: 4}} noMargin />
                )}
            </Grid>
            <Grid item xs>
                <Typography variant="caption" component="div" color="textSecondary">
                    {type}
                </Typography>
                <Typography variant="body2">
                    {title}
                </Typography>
                {subtitle1 && ( 
                    <Typography variant="caption" component="div" >
                        {subtitle1}
                    </Typography>
                )}
                {subtitle2 && ( 
                    <Typography variant="caption" component="div" >
                        {subtitle2}
                    </Typography>
                )}
            </Grid>
        </Grid>
    </Box>
)

class AdminSmartSearch extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.timeout = false;
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    handleAction = action => {
        if(!_.isEmpty(action)) {
            Object.keys(action).forEach(key => {
                if(key === "href") {
                    this.handleRedirect(action[key])
                }
            })
        }
    }

    handleRedirect = href => {
        this.handleClose()
        this.props.history.push(href)
    }

    getSearchResults = () => {
        const { searchString } = this.state;
        if(searchString.length >= 2) {
            API.get('/smartSearch', { 
                props: {
                    cancellation: true
                },
                params: { 
                    searchString: searchString
                } 
            })
            .then(result => {
                if(result && result.data) {
                    let isLoading = false,
                        categories = result.data?.categories ?? [],
                        searchResults = result.data?.results ?? [];
                    this.setState({
                        isLoading,
                        categories,
                        searchResults
                    });
                } 
            });
        }
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchResults, 250);
    }

    onSearchStringChange = (event) => {
        const searchString = event.target.value,
              searchResults = [],
              filter = null,
              isLoading = searchString.length >= 2 ? true : false;
        this.setState({
            isLoading,
            filter,
            searchString,
            searchResults
        }, () => {
            this.setSearch();
        });
    }
    
    handleOpen = event => {
        const anchorEl = event.currentTarget
        this.setState({
            anchorEl
        });
    }

    handleClose = () => {
        if(document?.activeElement?.id === 'search') {
            return;
        }
        const anchorEl = null,
                filter = null,
                searchResults = initialState.searchResults,
                searchString = initialState.searchString;
        this.setState({
            anchorEl,
            filter,
            searchResults,
            searchString
        });
    }

    handleFilterResults = filter => {
        this.setState({
            filter: this.state.filter === filter ? null : filter
        })
    }

    render() {
        const { classes } = this.props;
        const { anchorEl, categories, filter, isLoading, searchResults, searchString } = this.state;
        let open = Boolean(anchorEl),
            results = searchResults;
            
        if(filter) {
            if(filter === "Contacts") {
                results = _.filter(results, el => el.type === "Customer Contact" || el.type === "Supplier Contact");
            } else {
                results = _.filter(results, el => el.type === filter);
            }
        }

        return (
            <ClickAwayListener onClickAway={this.handleClose}>
                <div>
                    <TextField
                        id='search'
                        fullWidth
                        onChange={this.onSearchStringChange}
                        onFocus={this.handleOpen}
                        value={searchString} 
                        classes={{
                            root: classes.search,
                            input: classes.searchInput
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><FAIcon type="light" icon="search" size="small" button /></InputAdornment>,
                        }}
                        variant="filled"
                    /> 
                    <Popper 
                        open={open} 
                        anchorEl={anchorEl} 
                        className={classes.popper}
                        disablePortal={true} 
                        id={open ? 'smartSearchResults' : undefined} 
                    >
                        <div className={classes.container}>
                            <Paper className={classes.paper}>
                                {(!isLoading && _.size(searchString) >= 2 && (
                                    <Results
                                        categories={categories}
                                        classes={classes} 
                                        filter={filter}
                                        history={this.props.history}
                                        searchResults={results} 
                                        searchString={searchString}
                                        handleAction={this.handleAction}
                                        handleClose={this.handleClose}
                                        handleFilterResults={this.handleFilterResults}
                                    />
                                )) || (
                                    <Welcome
                                        classes={classes}
                                        isLoading={isLoading}
                                        handleRedirect={this.handleRedirect}
                                    />
                                )}
                            </Paper>
                        </div>
                    </Popper>
                </div>
            </ClickAwayListener>
        )
    }
}

export default withRouter(withStyles(styles)(AdminSmartSearch));