import * as ConfirmationActionTypes from 'Actions/Confirmation/Types/Types';

export const deployConfirmation = (message, success = false, cancel = false) => {
    return dispatch => {
        dispatch({
            type: ConfirmationActionTypes.CONFIRMATION_OPEN, 
            close: () => { dispatch(closeConfirmation()); if(cancel) { cancel(); }},
            success: () => { dispatch(closeConfirmation()); if(success) { success(); }},
            message
        });
    }
}

export const closeConfirmation = () => {
    return dispatch => {
        dispatch({type: ConfirmationActionTypes.CONFIRMATION_CLOSE});
    }
}