import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Avatar from 'Components/Common/Badges/Avatar';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = {
    isLoading: false,
}

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'Dashboard']);
    }

  	render() {
        const { isLoading } = this.state;
        const { loggedInUser } = this.props;
		return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={3} alignItems='center'>
                                <Grid item xs>
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item>
                                            <Avatar loggedInUser={loggedInUser} size='lg' />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="h5">
                                                {loggedInUser?.firstName} {loggedInUser?.lastName}
                                            </Typography>  
                                            <Typography variant="body2" component="div">
                                                {loggedInUser?.email} 
                                            </Typography>  
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Typography variant="h6" paragraph>
                                Dashboard
                            </Typography> 
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    Welcome
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
        
      }
      
}

function mapStateToProps(state){
    return {
		loggedInUser: state.auth.user
    };
}

export default connect(mapStateToProps)(Dashboard);