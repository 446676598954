import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';

import Avatar from 'Components/Common/Badges/Avatar';
import StaffMember from 'Components/Common/Staff/StaffMember';
import { handleClick, handleClose } from 'Functions/PopperFunctions';

const initialState = {
    anchorEl: null
}

class AdminUser extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleClick = handleClick.bind(this);
        this.handleClose = handleClose.bind(this);
    }
    
    render() {
        const   { anchorEl }            = this.state,
                { pulse, loggedInUser}  = this.props,
                open                    = Boolean(anchorEl);

        let clients = null,
            team    = null;

        if(!_.isEmpty(pulse.status) && loggedInUser?.id > 0) {

            clients = _.filter(pulse?.status?.user, function (el) { return el.status !== 0 });
            team = _.filter(pulse?.status?.admin, function (el) { return el.status !== 0 && el.id !== loggedInUser?.id});

        }

        return (
            <>
                <ClickAwayListener onClickAway={this.handleClose}>
                    <div>
                        <IconButton
                            aria-controls='team-menu'
                            aria-haspopup="true"
                            size="small"
                            onClick={this.handleClick}
                        >
                            <Avatar loggedInUser={loggedInUser} size='md' />
                        </IconButton>
                        <Popper 
                            id={open ? 'team-menu' : undefined} 
                            open={open}
                            anchorEl={anchorEl}
                            className="dd-menu"
                            disablePortal={true} 
                        >
                            <Paper>
                                <Box pl={2} pt={2}>
                                    <Typography component="div" variant="h6" paragraph={(!_.isEmpty(team) || !_.isEmpty(clients))}>
                                        Online Users
                                    </Typography>
                                </Box>
                                {((!_.isEmpty(team) || !_.isEmpty(clients)) && (
                                    <>
                                        {!_.isEmpty(team) && (
                                            <>
                                                <Typography variant="body2" className="fw-400" style={{background: '#eee', padding: '10px 10px 10px 18px'}}>
                                                    Admin Users
                                                </Typography>
                                                <List disablePadding>
                                                    {_.map(team, (user, idx) => (
                                                        <StaffMember
                                                            key={idx}
                                                            staff={user}
                                                        />
                                                    ))}
                                                </List>
                                            </>
                                        )}
                                        {!_.isEmpty(clients) && (
                                            <>
                                                <Typography variant="body2" className="fw-400" style={{background: '#eee', padding: '10px 10px 10px 18px'}}>
                                                    Client Users
                                                </Typography>
                                                <List disablePadding>
                                                    {_.map(clients, (user, idx) => (
                                                        <StaffMember
                                                            key={idx}
                                                            staff={user}
                                                        />
                                                    ))}
                                                </List>
                                            </>
                                        )}
                                    </>
                                )) || (
                                    <Grid container alignItems='center' className='sm-menu-text'>
                                        <Grid item xs={12}>
                                            <Typography component="div" variant="body2">
                                                No other users are online
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Paper>
                        </Popper>
                    </div>
                </ClickAwayListener>
            </>
        )
    }
}

function mapStateToProps(state){
    return {
		loggedInUser: state.auth.user,
        pulse: state.pulse
    };
}

export default connect(mapStateToProps)(AdminUser);