import * as PulseActionTypes from 'Actions/Pulse/Types/Types';
import API from 'API';

export function handleIamActive(userId) {
    return (dispatch) => {
        dispatch({
            type: PulseActionTypes.I_AM_ACTIVE,
            payload: userId
        });
    }
}

export function handleLoadStatus(status) {
    return (dispatch) => {
        dispatch({
            type: PulseActionTypes.LOAD_STAFF_STATUS,
            payload: status
        });
    }
}

export function handlePulse(result) {
    return (dispatch) => {
        dispatch({
            type: PulseActionTypes.HANDLE_PULSE,
            payload: result
        });
    }
}
export function handleMinusMsgBadge(count) {
    return (dispatch) => {
        dispatch({
            type: PulseActionTypes.MINUS_UNREAD_MESSAGE,
            payload: count
        });
    }
}
export function handleDiscardNotification(id) {
    return (dispatch) => {
        dispatch({
            type: PulseActionTypes.DISCARD_NOTIFICATION,
            payload: id
        });
    }
}

export function handleDiscardAllNotifications() {
    return (dispatch) => {
        dispatch({
            type: PulseActionTypes.DISCARD_ALL_NOTIFICATIONS
        });
    }
}

export function loadPulse() {
    return (dispatch, getState) => {  
        const state = getState().pulse,
                ln = state.ln;

        API.get('/pulse',
        {
            params: {
                ln
            },
            props: {
                noLoading: true,
                handlePulse: true,
                ignoreErrors: true
            }
        })
    }
}

export function loadStatus() {
    return dispatch => {    
        API.get('/pulse/status', 
            {
                props: {
                    noLoading: true,
                    ignoreErrors: true
                }
            }
        )
        .then(res => {
            if(res && res.data) {
                dispatch(handleLoadStatus(res.data))
            }
        })
    }
}

export function dismissAllNotifications() {
    return dispatch => {    
        API.put(`/misc/notifications/dismiss`, 
            {
                props: {
                    noLoading: true
                }
            }
        )
        .then(res => {
            if(res && res.data) {      
                dispatch(handleDiscardAllNotifications())
            }
        })
    }
}

export function putNotificationStatus(id, action) {
    return dispatch => {    
        API.put(`/misc/notifications/${id}`, 
            { 
                status: action 
            },
            {
                props: {
                    noLoading: true
                }
            }
        )
        .then(res => {
            if(res && res.data) {      
                dispatch(handleDiscardNotification(id))
            }
        })
    }
}