import decode from 'jwt-decode';
import * as authActionTypes from 'Actions/Auth/Types/Types';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { loadStatus } from 'Actions/Pulse/Pulse';
import API from 'API';

export function authStaff(token) {
    return (dispatch) => {

        dispatch(deploySnackBar("success", ""))

        const user = decode(token);

        if(user.type === 'ADMIN') {
            dispatch(loadStatus())
            dispatch({
                type: authActionTypes.EXTRACT_TOKEN,
                payload: {
                    ...user,
                    token: token
                }
            })
        } else {
            API.get(`/config`)
            .then(result => {
                if(result?.data){
                    dispatch({
                        type: authActionTypes.EXTRACT_TOKEN,
                        payload: {
                            ...user,
                            token: token,
                            config: result.data
                        }
                    })
                }
            });
        }

    }
}

export function updateProfilePhoto(payload) {
    return (dispatch) => {
        dispatch({
            type: authActionTypes.UPDATE_PICTURE,
            payload
        })
    }
}