import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = {
    formData: {
        currentPassword: '',
        password:'',
        confirmPassword: '',
    },
    formErrors: {},
    confirmationOpen: false,
}

class ChangeStaffPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    handleSubmit = () => {
        API.put(this.props.staffId ? '/staff/' + this.props.staffId + '/password' : '/staff/my/password', this.state.formData).then(result => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                })
            } else {
                this.setState({
                    ...initialState
                }, () => {
                    this.props.deploySnackBar("success", "The staff member's password has been changed")
                    this.props.toggleDialog()
                })
            }
        })
    }

    handleChange = (event) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.value
            }
        })
    }

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    }

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    }

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }

    render() {
        const { myPassword } = this.props;
        const { formErrors, formData } = this.state;
        return (
            <>
                <form onChange={this.handleChange} noValidate autoComplete="off">
                    <Typography variant="body2" paragraph>
                        {myPassword ? 'Your' : 'The'} password must be at least 8 characters in length and contain a letter and a number
                    </Typography>
                    {myPassword &&
                        <TextField
                            type="password"
                            name="currentPassword"
                            label="Current Password"
                            margin="none"
                            autoComplete="off"
                            value={(formData.currentPassword && formData.currentPassword) || ''}
                            error={formErrors && formErrors['currentPassword'] && true}
                            helperText={formErrors && formErrors['currentPassword']}
                            fullWidth
                        />
                    }
                    <TextField
                        type="password"
                        name="password"
                        label="New Password"
                        margin="none"
                        autoComplete="off"
                        value={(formData.password && formData.password) || ''}
                        error={formErrors && formErrors['password'] && true}
                        helperText={formErrors && formErrors['password']}
                        fullWidth
                    />
                    <TextField
                        type="password"
                        name="confirmPassword"
                        label="Confirm New Password"
                        margin="none"
                        autoComplete="off"
                        value={(formData.confirmPassword && formData.confirmPassword) || ''}
                        error={formErrors && formErrors['confirmPassword'] && true}
                        helperText={formErrors && formErrors['confirmPassword']}
                        fullWidth
                    />
                    <ConfirmationDialog 
                        open={this.state.confirmationOpen} 
                        success={this.handleConfirmationSuccess} 
                        close={this.handleConfirmationClose} 
                        title="Update Password?" 
                        message={`Are you sure you want to update ${!this.props.myPassword ? "this staff member's" : "your"} password?`}
                    />
                </form>
                <DialogActions className='pr-0 pb-0'>
                    <Button 
                        onClick={() => this.props.toggleDialog()}
                        variant="text"
                    >
                        Cancel
                    </Button>
                    <Button 
                        color="primary"
                        disabled={!(formData.password && formData.confirmPassword)}
                        onClick={this.handleConfirmationOpen} 
                        variant="text" 
                    >
                        Modify
                    </Button>
                </DialogActions>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(ChangeStaffPasswordForm);