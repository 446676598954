import toUint8Array from 'base64-to-uint8array';

export function pdfFromBase64(base64Data, filename) {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    let blob = new Blob([new toUint8Array(base64Data)]);
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
}