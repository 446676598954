import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles'

import ClientChat from 'Components/Layout/ClientLayout/ClientChat';
import ClientSmartSearch from 'Components/Layout/ClientLayout/ClientSmartSearch';
import ClientUser from 'Components/Layout/ClientLayout/ClientUser';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingBar from 'Components/Layout/Common/LoadingBar';
import logo from 'Assets/Images/logo-dark.png';
import Menu from 'Components/Layout/Common/Menu';
import Notifications from 'Components/Layout/Common/Notifications';
import UserDetails from 'Components/Layout/Common/UserDetails';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'fixed',
        top: 0,
        webkitOverflowScrolling: 'touch',
        webkitTransform: 'translateZ(0)',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: '0 4px 2px -2px rgba(0,0,0,0.2)'
    },
    toolbar: {
        height: 57.5,
        minHeight: 57.5,
        paddingTop: 'env(safe-area-inset-top)',
        paddingRight: theme.spacing(3),
        backgroundColor: theme.palette.primary.main
    },   
    logo:{
        width: 155,
        height: 'auto',
        marginTop: 5,
        marginRight: 16
    }, 
    iconContainer: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1)
    },
    searchContainer: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1)
    },
    menuButton: {
        marginLeft: 5
    },
    [theme.breakpoints.down('sm')]: {
        iconContainer: {
            display: 'none'
        },
    },
    [theme.breakpoints.down('xs')]: {
        toolbar: {
            paddingRight: theme.spacing(1)
        },
        logo: {
            width: 182
        },
    }    
  }));

const ClientHeader = ({toggleDrawer}) => {
    const classes = useStyles()
    return (
        <AppBar
            position="absolute"
            className={classes.appBar}
        >
            <Toolbar disableGutters={true} className={classes.toolbar}>
                <Grid container alignItems="center">
                    <Grid item>
                        <IconButton
                            color="inherit"
                            onClick={() => toggleDrawer('nav')}
                            className={classes.menuButton}
                        >
                            <FAIcon type="light" icon='bars' button noMargin />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <img alt="Crushed Ice" className={classes.logo} src={logo} />
                    </Grid>
                    <Grid item xs align="right">
                        <Grid container alignItems="center">
                            {/* <Grid item align="right" className={classes.searchContainer}>
                                <ClientSmartSearch />
                            </Grid> */}
                            <Grid item xs />
                            <Grid item align="right" className={classes.iconContainer}>
                                <Notifications />
                            </Grid>
                            {/* <Grid item align="right" className={classes.iconContainer}>
                                <ClientChat toggleDrawer={() => toggleDrawer('chat')} />
                            </Grid> */}
                            <Grid item align="right" className={classes.iconContainer}>
                                <ClientUser />
                            </Grid>
                            <Grid item className={classes.iconContainer}>            
                                <UserDetails />
                            </Grid>
                            <Grid item align="left" className={classes.iconContainer}>
                                <Menu />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> 
            </Toolbar>
            <LoadingBar />
        </AppBar>
    )
}

export default ClientHeader;