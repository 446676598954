import React from 'react';
import { useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';

const UserDetails = () => {
    const loggedInUser = useSelector(state => state.auth.user)
    return (
        (loggedInUser && (
            <>
                <Typography
                    align="left"
                    variant="body2"
                    noWrap
                    className={`not-xs`}
                    style={{color: '#fff'}}
                > 
                    {loggedInUser.firstName} {loggedInUser.lastName}
                </Typography>             
                <Typography
                    align="left"
                    variant="caption"
                    className={`not-xs`}
                    style={{color: '#fff'}}
                > 
                    {loggedInUser.email}
                </Typography> 
            </>
        )) || (
            null
        )
    )
}

export default UserDetails;