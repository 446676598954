import * as SoundActionTypes from './Types/Types';
import Message from 'Assets/Sounds/Message.mp3';

export function handlePlaySound(src, state) {
    return dispatch => {
        dispatch({
            type: SoundActionTypes.PLAY_SOUND,
            payload: {
                src,
                state,
                status: 'playing'
            }
        });
    }
}

export function playSound(type, state = false) {
    let src = null;
    switch(type) {
        case 'msg':
            src = Message;
        break;
        default:
            src = null;
        break;
    }
    return dispatch => {
        dispatch(handlePlaySound(src, state));
    }
}

export function handleStopSound() {
    return dispatch => {
        dispatch({
            type: SoundActionTypes.STOP_SOUND,
            payload: {
                src: null,
                status: 'stop'
            }
        });
    }
}

export function stopSound() {
    return dispatch => {
        dispatch(handleStopSound());
    }
}