import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const drawerWidth = 235;

const styles = theme => ({
    pwaBarSpacer: {
        minHeight: 'calc(env(safe-area-inset-top) + 60px)',
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
        whiteSpace: 'nowrap',
    },
    drawerPaperClose: {
        width: theme.spacing(6.5),  
        overflowX: 'hidden',
    },
    notchMenuFix: {
        paddingTop: 0,
        paddingBottom:0
    },
    link: {
        paddingTop: 1,
        marginLeft: 0,
        textDecoration: 'none !important',
        color: 'rgba(0, 0, 0, 0.8)'
    },
    [theme.breakpoints.down('sm')]: {
        drawerPaper: {
            position: 'absolute',
            width: '100%',
            height: '100vh',
            minHeight: '100vh',
            borderRight: 0,
        },
        drawerPaperClose: {
            width: theme.spacing(6.5),
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        },
        logoContainer: {
            width: '100%',
            borderRight: 0,
        },
    },
    [theme.breakpoints.down('xs')]: {
        logoContainer: {
            height: 60,
        },
        drawerPaper: {
            borderRight: 0,
        },
        drawerPaperClose: {
            width: 0,
        },
    },
    ['@media (orientation: landscape) and (max-height: 767px)']: { // eslint-disable-line no-useless-computed-key
        drawerPaper: {
            borderRight: 0,
        },
        drawerPaperClose: {
            width: 0,
        },
        notchMenuFix: {
            marginLeft: 'env(safe-area-inset-left)',
            marginRight: 'env(safe-area-inset-right)',
        },
    },
});

const initialState = {
    selectedNavId: false,
}

class AdminNav extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleClick = (navId) => {
        if(this.state.selectedNavId !== navId) {
            this.setState({
                selectedNavId: navId,
            });
        } else {
            this.setState({
                selectedNavId: false,
            });
        }
    }

    NavItem = props => {
        const { classes } = this.props;
        return (
            <Link 
                className={classes.link}
                to={props.link}
            > 
                <ListItem button>
                    <ListItemIcon> 
                        <FAIcon 
                            button 
                            fixedWidth 
                            icon={props.icon}
                            noMargin 
                        />
                    </ListItemIcon>
                    <ListItemText 
                        disableTypography
                        primary={<Typography variant="overline" style={{fontSize: '0.95em'}}>{props.title}</Typography>} 
                    />
                </ListItem>
            </Link>
        )
    }

    render() {
        const { classes, navOpen, openNav, scrollHeight } = this.props;
        return (
            <Drawer
                classes={{
                    paper: classNames(classes.drawerPaper, !navOpen && classes.drawerPaperClose),
                }}
                open={navOpen}
                style={{height: window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${scrollHeight ?? window.innerHeight}px)` : `${scrollHeight ?? window.innerHeight}px`}}
                variant="permanent"
            >
                <div className={classes.pwaBarSpacer} />
                <List 
                    onClick={() => !navOpen && openNav()} 
                    className={classes.notchMenuFix}
                >
                    <this.NavItem
                        icon='home'
                        link='/dashboard'
                        title='Dashboard'
                    />
                </List>
            </Drawer>
        );
    }
}

const mapStateToProps = (state) =>  {
    return {
		loggedInUser: state.auth.user
    };
}

export default connect(mapStateToProps)(withStyles(styles)(AdminNav));