import React  from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ActionMenu from 'Components/Common/Activity/ActionMenu';
import AddNoteForm from 'Components/Common/Activity/AddNoteForm';
import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import StaffAccess from '../StaffAccess/StaffAccess';
import UpdateStaffMfa from '../UpdateStaff/UpdateStaffMfa';
import ChangeStaffPasswordForm from '../UpdateStaff/ChangeStaffPasswordForm';
import UpdateStaffDetailsForm from '../UpdateStaff/UpdateStaffDetailsForm';
import UploadStaffProfilePhotoForm from '../UpdateStaff/UploadStaffProfilePhotoForm';
import ViewStaffAuditLog from './ViewStaffAuditLog';
import ViewStaffMfaStatus from './ViewStaffMfaStatus';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import Avatar from 'Components/Common/Badges/Avatar';
import { toggleDialog, handleTabChange } from 'Functions/MiscFunctions';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

function TabPanel(props) {
    const { children, value, index, ...other } = props;  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}

const styles = theme => ({
    tab: {
        color: '#fff',
        opacity: 1
    },
    tabIndicator: {
        backgroundColor: '#fff'
    },
})

const initialState = {
    access: {
        addNote: false,
        updateStaff: false,
        staffAccess: false,
        updateMfa: false,
        updatePassword: false,
        updateAvatar: false,
        manageActions: false,
        assignActions: false
    }, 
    currentTab: 0,
    dialog: {
        updateStaff: false,
        updatePassword: false,
        updateAvatar: false 
    },
    staff: {},
    staffId: 0,
    isLoading: true,
    staffOrdersGraph: {
        labels: [
            "Jan 2019",
            "Feb 2019",
            "Mar 2019",
            "Apr 2019",
            "May 2019",
            "Jun 2019",
            "Jul 2019",
            "Aug 2019",
            "Sep 2019",
            "Oct 2019",
            "Nov 2019",
            "Dec 2019"
        ],
        datasets: [
            {
                label: "Staff Orders",
                type:'line',
                lineTension: 0.1,
                fill: false,
                borderColor: '#00E676',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#00E676',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 4,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#00E676',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                data: [
                    "47",
                    "50",
                    "43",
                    "45",
                    "47",
                    "55",
                    "55",
                    "58",
                    "61",
                    "66",
                    "63",
                    "65"
                ]
            },
            {
                label: "Staff Quotes",
                type:'line',
                lineTension: 0.1,
                fill: false,
                borderColor: '#ef5350',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#ef5350',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 4,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#ef5350',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                data: [
                    "59",
                    "60",
                    "52",
                    "55",
                    "62",
                    "67",
                    "70",
                    "71",
                    "69",
                    "76",
                    "75",
                    "79"
                ]
            }
        ],
    },
    staffSalesGraph: {
        labels: [
            "Jan 2019",
            "Feb 2019",
            "Mar 2019",
            "Apr 2019",
            "May 2019",
            "Jun 2019",
            "Jul 2019",
            "Aug 2019",
            "Sep 2019",
            "Oct 2019",
            "Nov 2019",
            "Dec 2019"
        ],
        datasets: [
            {
                label: "Staff Sales",
                type:'line',
                lineTension: 0.1,
                fill: false,
                borderColor: '#00E676',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#00E676',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 4,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#00E676',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                data: [
                    "15256.04",
                    "12864.96",
                    "18345.80",
                    "9845.22",
                    "15934.74",
                    "16245.35",
                    "18645.75",
                    "18345.28",
                    "15988.39",
                    "19868.17",
                    "17786.40",
                    "20015.69"
                ]
            },
            {
                label: "Staff Target",
                type:'line',
                lineTension: 0.1,
                fill: false,
                borderColor: '#ef5350',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#ef5350',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 4,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#ef5350',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                data: [
                    "14256.04",
                    "11864.96",
                    "17345.80",
                    "10845.22",
                    "13934.74",
                    "15245.35",
                    "19645.75",
                    "17345.28",
                    "13988.39",
                    "18868.17",
                    "19786.40",
                    "19891.45"
                ]
            }
        ],
    }
}

class ViewStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.toggleDialog = toggleDialog.bind(this);
        this.handleTabChange = handleTabChange.bind(this);
        this.timeout = false;
    }

    componentDidMount = () => {
        Promise.all([
            'update-staff',
            'update-staff:multi-factor-auth',
            'update-staff:change-password',
            'update-staff:upload-profile-photo',
            'staff-access',
            'view-staff:add-note',
            'view-staff:manage-actions',
            'action-center:assign-actions'
        ])
        .then(([
            updateStaff, 
            updateMfa, 
            updatePassword, 
            updateAvatar, 
            staffAccess, 
            addNote, 
            manageActions, 
            assignActions
        ]) => {  
            let     staffId     = this.props.id ? this.props.id : this.props.match.params.id,
                    currentTab  = this.props.defaultTab ? this.props.defaultTab : 0;
            this.setState({
                access: {
                    addNote,
                    staffAccess,
                    updateStaff,
                    updateMfa,
                    updatePassword,
                    updateAvatar,
                    manageActions,
                    assignActions
                },
                currentTab,
                staffId
            }, () => this.loadComponentData());
        })
    }

    componentDidUpdate = (prevProps) => {
        if(!this.props.dialog.dialogOpen && prevProps.dialog.dialogOpen) {
            this.loadComponentData();
        }
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout)
    }

    refreshData = () => {
        this.timeout = setTimeout(() => this.loadComponentData(), 250);
    }

    loadComponentData = () => {
        API.get('/staff/' + this.state.staffId, { params: { viewStaff: true } })
        .then(result => {  
            if(result && result.data.errors) {
                this.props.history.push('/staff')
            } else if(result && result.data) {
                this.setState({
                    ...this.state,
                    staff: result.data,
                    isLoading: false,
                }, () => {
                    if(this.props.pageTitle)
                        this.props.pageTitle([1, 'Staff', `${this.state.staff.staff_first_name} ${this.state.staff.staff_last_name}`])
                });
            }
        })
    }

    handleDeployAddNote = () => this.props.deployDialog(
        <AddNoteForm
            id={this.state.staffId}
            relation="staff"
            toggleDialog={() => { this.props.closeDialog(); }}
            standardOnly
        />, true, "", "standard","xs");

    render() {
        const { classes, defaultTab, scrollToTop } = this.props;
        const { access, currentTab, dialog, isLoading, staff, staffId } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid container item spacing={3}>
                    <Grid item xs={12}>
                        {(isLoading && (
                            <LoadingCircle />
                        )) || (
                            <>
                                <Grid container spacing={3}>
                                    {!defaultTab && (
                                        <>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item xs>
                                                        <Grid container spacing={3} alignItems="center">
                                                            <Grid item style={{padding: '8px 8px 0 12px'}}>
                                                                <Avatar model={staff} size='lg' />
                                                            </Grid>
                                                            <Grid item xs>
                                                                <Typography variant="h6">
                                                                    {staff.staff_first_name} {staff.staff_last_name}
                                                                </Typography>  
                                                                <Typography variant="caption" component="div">
                                                                    {staff.staff_email} 
                                                                </Typography>  
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {access.addNote && (
                                                        <Grid item>
                                                            <Tooltip title="Add Note" placement="top">
                                                                <IconButton onClick={() => this.handleDeployAddNote()}>
                                                                    <FAIcon type="light" icon="comment-alt-plus" button noMargin />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    )}
                                                    <Grid item>
                                                        <ActionMenu
                                                            rows={[
                                                                {
                                                                    icon: "comment-alt-plus",
                                                                    label: "Add Note",
                                                                    onClick: () => this.handleDeployAddNote(),
                                                                    display: access.addNote,
                                                                    divider: true
                                                                },
                                                                {
                                                                    icon: "portrait",
                                                                    label: "Change Avatar",
                                                                    onClick: () => this.toggleDialog('updateAvatar'),
                                                                    display: access.updateAvatar
                                                                },
                                                                {
                                                                    icon: "key",
                                                                    label: "Change Password",
                                                                    onClick: () => this.toggleDialog('updatePassword'),
                                                                    display: access.updatePassword
                                                                },
                                                                {
                                                                    icon: "pencil-alt",
                                                                    label: "Modify Details",
                                                                    onClick: () => this.toggleDialog('updateStaff'),
                                                                    display: access.updateStaff
                                                                },
                                                            ]}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} style={{padingTop: 0}}>
                                                <Paper>
                                                    <AppBar color="primary" position="static" elevation={0} >
                                                        <Tabs classes={{indicator: classes.tabIndicator}} variant='scrollable' value={currentTab} onChange={this.handleTabChange}>
                                                            <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={0} label="Overview" />
                                                            {access.manageActions && (
                                                                <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={7} label="Actions" />
                                                            )}
                                                            {access.updateMfa && (
                                                                <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={3} label="Multi-Factor Authentication" />
                                                            )}
                                                            {access.staffAccess && (
                                                                <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={1} label="Staff Access" />
                                                            )}
                                                            <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={2} label="Login History" />
                                                            <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={6} label="Notes" />
                                                            <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={4} label="Quotes" />
                                                            <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={5} label="Orders" />
                                                        </Tabs>   
                                                    </AppBar>   
                                                </Paper>
                                            </Grid>  
                                        </>
                                    )}   
                                    <Grid item xs={12}>  
                                        <TabPanel value={currentTab} index={0}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} lg={6}>
                                                    <Grid container spacing={3}>   
                                                        <Grid item xs={12} lg={6}>
                                                            <ViewStaffMfaStatus status={[staff.staff_mfa,staff.staff_mfa_activation_datetime]} isloading={isLoading ? true : undefined} />                                                        
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <PaddedPaper>
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Line 
                                                                            data={this.state.staffSalesGraph} 
                                                                            options={{
                                                                                legend: {
                                                                                    display: false,
                                                                                },
                                                                                scales: {
                                                                                    xAxes: [{
                                                                                        gridLines: {
                                                                                            color: "rgba(0,0,0,.03)",
                                                                                        }
                                                                                    }],
                                                                                    yAxes: [{
                                                                                        gridLines: {
                                                                                            color: "rgba(0,0,0,.03)",
                                                                                        },
                                                                                        ticks: {
                                                                                            callback: (value, index, values) => {
                                                                                                return value.toLocaleString("en-GB", {
                                                                                                    style: "currency",
                                                                                                    currency: "GBP"
                                                                                                });
                                                                                            },
                                                                                            beginAtZero: true,
                                                                                            min: 0
                                                                                        }
                                                                                    }]
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} align='center'>
                                                                        <Typography variant="subtitle1">
                                                                            Staff Sales vs Target
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </PaddedPaper>
                                                        </Grid>
                                                        {/* <Grid item xs={12}>
                                                            <PaddedPaper>
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Line 
                                                                            data={this.state.staffOrdersGraph} 
                                                                            options={{
                                                                                legend: {
                                                                                    display: false,
                                                                                },
                                                                                scales: {
                                                                                    xAxes: [{
                                                                                        gridLines: {
                                                                                            color: "rgba(0,0,0,.03)",
                                                                                        }
                                                                                    }],
                                                                                    yAxes: [{
                                                                                        gridLines: {
                                                                                            color: "rgba(0,0,0,.03)",
                                                                                        },
                                                                                        ticks: {
                                                                                            beginAtZero: true,
                                                                                            min: 0
                                                                                        }
                                                                                    }]
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} align='center'>
                                                                        <Typography variant="subtitle1">
                                                                            Staff Quotes vs Orders
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </PaddedPaper>
                                                        </Grid> */}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        {access.staffAccess && (
                                            <TabPanel value={currentTab} index={1}>
                                                <StaffAccess id={staffId} staff={staff} scrollToTop={scrollToTop} />
                                            </TabPanel>
                                        )}
                                        <TabPanel value={currentTab} index={2}>
                                            <Paper>
                                                <ViewStaffAuditLog staff={staff} isLoading={isLoading} />
                                            </Paper>
                                        </TabPanel>
                                        {access.updateMfa && (
                                            <TabPanel value={currentTab} index={3}>
                                                <UpdateStaffMfa staff={staff} staffId={staffId} scrollToTop={scrollToTop} callback={this.loadComponentData} />
                                            </TabPanel>
                                        )}
                                        <TabPanel value={currentTab} index={6}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={6}>
                                                    <ViewNotes notes={staff.notes} />
                                                </Grid>
                                                {access.addNote && (
                                                    <Grid item xs={12} lg={6}>
                                                        <AddNoteForm 
                                                            relation="staff"
                                                            id={staffId}
                                                            toggleDialog={this.loadComponentData}
                                                            standardOnly
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                                <SmallFormDialog
                                    open={dialog.updateAvatar}
                                    onClose={() => this.toggleDialog('updateAvatar')}
                                    title="Change Avatar"
                                    content={<UploadStaffProfilePhotoForm staffId={staffId} current={staff.staff_profile_photo} toggleDialog={() => this.toggleDialog('updateAvatar')} />}
                                    maxWidth='xs'
                                />
                                <SmallFormDialog
                                    open={dialog.updatePassword}
                                    onClose={() => this.toggleDialog('updatePassword')}
                                    title="Change Password"
                                    content={<ChangeStaffPasswordForm staffId={staffId} myPassword={false} toggleDialog={() => this.toggleDialog('updatePassword')} />}
                                    maxWidth='xs'
                                />
                                <SmallFormDialog
                                    open={dialog.updateStaff}
                                    onClose={() => this.toggleDialog('updateStaff')}
                                    title="Modify Staff Member"
                                    content={<UpdateStaffDetailsForm staffId={staffId} toggleDialog={() => this.toggleDialog('updateStaff')} />}
                                    maxWidth='xs'
                                />     
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

function mapStateToProps(state){
    return {
        dialog: state.dialog,
        notifications: state.pulse.notifications,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md') => dispatch(deployDialog(content, disableDialogContent, title, variant, size))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewStaff));