import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fas as fasp } from '@fortawesome/pro-solid-svg-icons';
import FACustomIcon from 'Components/Common/Icons/FontAwesome/FACustomIcon';

library.add(fab, fad, fal, fas, fasp);

const iconType = (type) => {
    return  (type === 'solid' && 'fas') ||
            (type === 'brand' && 'fab') ||
            (type === 'duo' && 'fad') ||
            'fal'
}

const customIcons = [
    "address-card-primary",
    "invoice",
    "location-circle-primary",
    "purchase",
    "purchase-slash",
    "quote",
    "quote-slash",
    "order",
    "order-slash",
    "sack-pound",
    "search-pound",
    "vat"
];

const FAIcon = ({
        button, 
        buttonPrimary, 
        color, 
        className, 
        disabled, 
        error, 
        fixedWidth,
        fixedHeight,
        icon,
        light,
        noMargin,
        rotation, 
        size, 
        spin, 
        style, 
        transform,
        type
    }) => {
        
    const iconSize = (!isNaN(size) && size)           ||
                        (size === 'avatar' && 32)     || 
                        (size === 'xxlarge' && 75)    || 
                        (size === 'xlarge' && 50)     || 
                        (size === 'large' && 35)      || 
                        (size === 'small' && 15)      || 
                        20;

    const width = (fixedHeight && 'auto') || iconSize,
          height = (fixedWidth && 'auto') || iconSize;

          return (
        (customIcons.includes(icon) && (
            <FACustomIcon 
                className={className} 
                icon={icon} 
                size={iconSize} 
                noMargin={noMargin && true}
                style={style} 
            />
        )) || (
            <FontAwesomeIcon    
                icon={[
                    iconType(type), 
                    icon
                ]}
                rotation={
                    rotation && rotation
                } 
                spin={
                    spin && spin
                }
                transform={
                    transform && transform
                }
                className={
                    (className && className) ||
                    (disabled && 'iconStyleDisabled') ||
                    (error && 'iconStyleError') ||
                    (light && 'iconStyleLight') || 
                    'iconStyle'
                }
                style={{
                    width: width,
                    height: height,
                    margin:
                        (noMargin) && 0,
                    cursor: 
                        (button || buttonPrimary) && 'pointer',
                    color: 
                        (disabled && (
                            undefined      
                        )) || (
                            (buttonPrimary && !disabled && 'white') || 
                            (color === 'primary' && '#ef3340')      || 
                            (color === 'info' && '#0288D1')         || 
                            (color === 'error' && '#d32f2f')        ||  
                            (color === 'success' && '#388E3C')      ||  
                            (color === 'white' && '#fff')
                        ),
                    ...style
                }}
            />
        )
    )
}

export default FAIcon;