import _ from 'lodash';
import * as PulaseActionTypes from 'Actions/Pulse/Types/Types';

const initialState = {
    badges: {},
    ln: 0,
    notifications: {},
    status: {
        admin: {},
        user: {}
    }
};

function PulseReducer(state = initialState, action) {
    switch (action.type) {
        case PulaseActionTypes.LOAD_STAFF_STATUS:
            return {
                ...state,                   
                status: action.payload
            }
        case PulaseActionTypes.I_AM_ACTIVE:
            if(state.status.admin[action.payload]?.status !== 2) {
                return {
                    ...state,                   
                    status: {
                        ...state.status,
                        admin: {
                        ...state.status.admin,
                            [action.payload]: {
                                ...state.status.admin[action.payload],
                                status: 2
                            }
                        }
                    } 
                }
            } else {
                return state
            }
        case PulaseActionTypes.MINUS_UNREAD_MESSAGE:
            let count = (state.badges['unread-messages'] - action.payload);
            if(count < 0) {
                count = 0;
            }
            return {
                ...state,                   
                badges: {
                    ...state.badges,
                    'unread-messages': count
                } 
            }
        case PulaseActionTypes.HANDLE_PULSE:
            if(JSON.stringify(action.payload) !== JSON.stringify(state)) {
                if(JSON.stringify(action.payload.badges) !== JSON.stringify(state.badges)) {
                    state = {
                        ...state,
                        badges: action.payload.badges
                    }
                }
                if(action.payload.ln !== state.ln) {
                    state = {
                        ...state,
                        ln: action.payload.ln
                    }
                }
                if(!_.isEmpty(action.payload.notifications)) {
                    state = {
                        ...state,
                        notifications: {
                            ...state.notifications,
                            ...action.payload.notifications
                        }
                    }
                }
                // Object.keys(action.payload.status.admin).forEach((staffId) => {
                //     if(state.status?.admin?.[staffId]?.status !== action.payload?.status?.admin?.[staffId]) {
                //         state = {
                //             ...state,
                //             status: {
                //                 ...state.status,
                //                 admin: {
                //                     ...state.status.admin,
                //                     [staffId]: {
                //                         ...state.status.admin.[staffId],
                //                         status: action.payload.status.admin.[staffId]
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // });
                // Object.keys(action.payload.status.user).forEach((staffId) => {
                //     if(state.status?.user?.[staffId]?.status !== action.payload?.status?.user?.[staffId]) {
                //         state = {
                //             ...state,
                //             status: {
                //                 ...state.status,
                //                 user: {
                //                     ...state.status.user,
                //                     [staffId]: {
                //                         ...state.status.user.[staffId],
                //                         status: action.payload.status.user.[staffId]
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // });
            }
            return state;
        case PulaseActionTypes.DISCARD_NOTIFICATION:
            if(state.notifications[action.payload]) {
                state = {
                    ...state,
                    notifications: _.omit(state.notifications, action.payload),
                }
            }
            return state;
        case PulaseActionTypes.DISCARD_ALL_NOTIFICATIONS:
            state = {
                ...state,
                notifications: initialState.notifications
            }
            return state;
        default:
            return state;
    }
}

 export default PulseReducer;