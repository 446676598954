import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ClientRouteSwitcher from 'Components/Routes/ClientRoutes/ClientRouteSwitcher';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { setLastActivePage } from 'Functions/AuthFunctions';
import { handleAppError } from 'Actions/UI/UI';

const styles = theme => ({
    pwaBarSpacer: {
        minHeight: 'env(safe-area-inset-top)',
    },
    appBarSpacer: {
        minHeight: 104
    },
    chevronIcon: {
        width: '12px!important', 
        height: '12px!important', 
        marginLeft: 7,
        marginRight: 7
    },
    pageIcon: {
        width: '17.5px!important', 
        height: '17.5px!important', 
        marginRight: 7
    },
    content: {
        width: '100%',
        height: '100vh',
        paddingBottom: 24,
        overflowX: 'hidden',
        overflowY: 'auto',
        "&::-webkit-scrollbar": { 
            width: "initial!important"
        }
    },
    container: {
        padding: theme.spacing(3)
    },
    page: {
        height: 44,
        marginTop: 60,
        padding: theme.spacing(1.5, 3, 1.5, 3),
        background: '#fff',
        borderBottom: '1px solid #ddd'
    },
    [theme.breakpoints.down('sm')]: {
        content: {
            marginLeft: theme.spacing(7),
        },
        servicePane: {
            display: 'none'
        }
    },
    [theme.breakpoints.down('xs')]: {
        container: {
            padding: theme.spacing(2.5),
        },
        content: {
            marginLeft: 0,
            webkitOverflowScrolling: 'touch',
        }
    },
    ['@media (orientation: landscape) and (max-height: 767px)']: { // eslint-disable-line no-useless-computed-key
        content: {
            marginLeft: 'env(safe-area-inset-left)',
            marginRight: 'env(safe-area-inset-right)',
        }
    },
});

const initialState = {
    scrollHeight: window.innerHeight,
    scheduledUpdateColor: '#d32f2f',
    scheduledUpdateTime: false,
    scheduledUpdateTimeCountdown: false,
}

class ClientContentWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.countdown = false;
        this.main = null;
    }

    componentDidMount = () => {
        this.setState({
            scrollHeight: this.props.scrollHeight,
            scheduledUpdateTime: this.props.ui.updateNotification
        }, () => {
            if(this.state.scheduledUpdateTime !== false) {
                this.startScheduledUpdateTime();
            }
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.ui.updateNotification !== prevProps.ui.updateNotification) {
            this.setState({
                scheduledUpdateTime: this.props.ui.updateNotification
            }, () => {                
                if(this.state.scheduledUpdateTime !== false) {
                    this.startScheduledUpdateTime();
                }
            });
        }        
        if(this.props.scrollHeight !== prevProps.scrollHeight){
            this.setState({
                scrollHeight: this.props.scrollHeight,
            });
        }
        if(this.props.pagePath !== prevProps.pagePath){
            this.main.scrollTop = 0;
        }
    }

    componentWillUnmount = () => {
        if(this.countdown)          
            clearInterval(this.countdown);
    }
    
    startScheduledUpdateTime = () => {
        this.countdown = setInterval(this.getScheduledUpdateTimeCountdown, 1000);
    }

    getScheduledUpdateTimeCountdown = () => {
        var scheduled = moment(this.state.scheduledUpdateTime, 'X').subtract(10, 'seconds');
        const now = moment();
        if(now.unix() < scheduled.unix()) {
            const countdown = moment(scheduled - now);
            const mins = parseInt(countdown.format('m'));
            const countdownText = mins !== 0 ? countdown.format("m [ minutes] [and] s [ seconds]") : countdown.format("s[ seconds]");
            this.setState({                
                scheduledUpdateColor: mins > 1 ? '#F4511E' : '#d32f2f',
                scheduledUpdateTimeCountdown: this.state.updateNotification !== false ? countdownText : ''
            });
        } else {      
            if(this.countdown) {      
                clearInterval(this.countdown);
            }
            setLastActivePage(this.props.location.pathname);
            this.setState({                
                scheduledUpdateTimeCountdown: ''
            }, () => this.props.handleAppError("APP_UPDATE", ""))    
        }
    }

    scrollToTop = () => {
        this.main.scrollTop = 0;
    }

    pageTitleLink = (idx, title, pageTitle) => {
        let { classes } = this.props,
            path, 
            defaultText, 
            icon, 
            iconType = 'light',
            link = idx <= pageTitle[0],
            showDefaultText = pageTitle.length === 2 ? true : false,
            seperator = idx !== (pageTitle.length - 1) ? true : false,
            chevron = <FAIcon type="light" icon="chevron-right" className={classes.chevronIcon} />

        switch (title) {  
            case "Dashboard" :
                icon = "home"; 
                path = "/dashboard";
                defaultText = false;
            break;
            case "Blogs" :
                icon = "newspaper"; 
                path = "/blogs";
                defaultText = false;
            break;
            case "My Account":
                icon = "user";
                path = false;
                defaultText = false;
            break;
            default:
                icon = "exclamation-triangle";
                path = false;
                defaultText = false;
        }
        return (
            <React.Fragment key={idx}>
                {idx === 1 && (
                    <Grid item>
                        <FAIcon type={iconType} icon={icon} className={classes.pageIcon} button={(path && link && true) || false} />
                    </Grid>
                )}
                {(path && link && (
                    <>
                        <Grid item>
                            <Link 
                                to={path} 
                                style={{
                                    color: '#000',
                                    textDecoration: 'none'
                                }}
                            >
                                {title}
                            </Link>
                        </Grid>
                        {seperator && (                            
                            <Grid item>
                                {chevron}
                            </Grid>
                        )}
                        {showDefaultText && defaultText && (
                            <>
                                <Grid item>
                                    {chevron}
                                </Grid>
                                <Grid item>
                                    {defaultText}
                                </Grid>
                            </>
                        )}
                    </>
                )) || (
                    <>
                        <Grid item>
                            {title}
                        </Grid>
                        {seperator && (                            
                            <Grid item>
                                {chevron}
                            </Grid>
                        )}
                        {showDefaultText && defaultText && (
                            <>
                                <Grid item>
                                    {chevron}
                                </Grid>
                                <Grid item>
                                    {defaultText}
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </React.Fragment>
        )
    }

    render() {
        const { scrollHeight, scheduledUpdateTime, scheduledUpdateColor, scheduledUpdateTimeCountdown } = this.state;
        const { classes, changePath, page, pageTitle } = this.props;
        const isMobile = this.props.ui.device.isMobile;
        return (
            <main ref={el => this.main = el} className={classes.content} style={{height: window.matchMedia('(display-mode: standalone)').matches ? `100vh` : `${scrollHeight}px`}}>
                <div className={classes.pwaBarSpacer} />
                <div className={classes.appBarSpacer} />
                <AppBar color="secondary" position="absolute" elevation={0} className={classes.page} style={{paddingLeft: this.props.drawers?.nav ? 259 : 77}}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems='center'>
                                {_.map(page, (el, idx) => {
                                    if(idx >= 1) {                                    
                                        return (
                                            this.pageTitleLink(idx, el, page)
                                        )
                                    }
                                })}
                            </Grid>
                        </Grid>
                        {scheduledUpdateTime !== false && scheduledUpdateTimeCountdown !== false && (
                            <Grid item className={classes.servicePane} md={6} align="right" style={{cursor: 'pointer'}}>
                                <Grid container spacing={2} alignItems='center'>
                                    <Grid item xs />
                                    <Grid item style={{paddingTop: 7.5}}>
                                        <FAIcon type="light" icon='exclamation-triangle' size={isMobile ? 'small' : ''} style={{color: scheduledUpdateColor}} noMargin /> 
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='caption' style={{color : scheduledUpdateColor}}>
                                            {(scheduledUpdateTimeCountdown !== '' && ( 
                                                <>
                                                    {`An update ${!isMobile ? `for the Crushed Ice Client Area ` : ``} will begin in ${scheduledUpdateTimeCountdown}`}
                                                </>
                                            )) || (
                                                <>
                                                    An update for the Crushed Ice Client Area is starting now...
                                                </>
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </AppBar>
                <div className={classes.container}>
                    <ClientRouteSwitcher changePath={changePath} pageTitle={pageTitle} scrollToTop={this.scrollToTop} deploying={this.props.deploying} deploymentId={this.props.deploymentId}/>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state){
    return {
        ui: state.ui
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError: (error, message) => dispatch(handleAppError(error, message))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientContentWrapper));