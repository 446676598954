import React from 'react';

import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import Avatar from 'Components/Common/Badges/Avatar';

const StaffMember = ({badge, border, className, content, disabled, inlineContent, minimal, noPadding, selected, staff, size, stacked, handleAction, vertical}) => {
    if(staff && staff.name) {
        let name = staff.name;
        // if(minimal) {
        //     name = staff.name.split(' ');
        //     if(name.length > 1) {
        //         name = `${name[0]} ${name[1].charAt(0)}.`
        //     } else {
        //         name = name[0]
        //     }
        // }

        return (
            <ListItem 
                className={`${className ? className : (minimal ? "sm-list-item-min" : (border ? "sm-list-item" : "sm-list-item-borderless"))}${noPadding ? ` p-0`:``}${selected ? ` selected`:``}`}
                disableGutters
                onClick={() => { if(!disabled && handleAction) { handleAction(); }}}
                button={!disabled && handleAction ? true : false}
            >
                <Grid container spacing={2} alignItems='center'>
                    {(vertical && (
                        <>
                            <Grid item xs={12} align='center'>
                                <Badge
                                    badgeContent={badge ? parseInt(badge) : 0}
                                    className={parseInt(badge) > 0 ? 'mt-1' : ''}
                                    color='primary'
                                    max={99}
                                >
                                    <Avatar userStatus={staff} size={size ?? 'md'} />
                                </Badge>
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Typography variant={((size === "sm") && 'caption') || 'subtitle1'}>
                                    {name}
                                </Typography>
                            </Grid>
                        </>
                    )) || (content && (
                        <>
                            <Grid item xs={12} className='gap-2' />
                            <Grid item xs={12} align='center'>
                                <Badge
                                    badgeContent={badge ? parseInt(badge) : 0}
                                    className={parseInt(badge) > 0 ? 'mt-1' : ''}
                                    color='primary'
                                    max={99}
                                >
                                    <Avatar userStatus={staff} size={size ?? 'md'} />
                                </Badge>
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Typography variant={(size === "sm" && 'body2') || 'subtitle1'}>
                                    {name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} align='center'>
                                {content}
                            </Grid>
                        </>
                    )) || (
                        <>
                            <Grid item>
                                <Badge
                                    badgeContent={badge ? parseInt(badge) : 0}
                                    className={parseInt(badge) > 0 ? 'mt-1' : ''}
                                    color='primary'
                                    max={99}
                                >
                                    <Avatar userStatus={staff} size={size ?? 'md'} />
                                </Badge>
                            </Grid>
                            <Grid item xs>
                                <Typography variant={(size === "sm" && 'body2') || 'subtitle1'}>
                                    {name}
                                </Typography>
                                {stacked && inlineContent}
                            </Grid>
                            {!stacked && inlineContent}
                        </>
                    )}
                </Grid>
            </ListItem>
        )
    } else {
        return <React.Fragment />
    }    
}

export default StaffMember;