import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import _ from 'lodash';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';

import './index.css';
import * as serviceWorker from './serviceWorker';
import Layout from 'Components/Layout/Layout';
import rootReducer from './Reducers/Reducers';
import { handleAppError, handleWindowResize } from './Actions/UI/UI';
import { handleCiServiceWorker } from './Actions/CiServiceWorker/CiServiceWorker';

/* Redux Store */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

/* Service Worker Callback */
const CiSwCallback = installed => {
    store.dispatch(handleCiServiceWorker(installed));
}

/* Window Event Listeners */
window.addEventListener('online', () => store.dispatch(handleAppError(false, "")))
window.addEventListener('offline', () => store.dispatch(handleAppError("NETWORK_OFFLINE", "")))
window.addEventListener('resize', _.debounce(() => store.dispatch(handleWindowResize(window.innerWidth, window.innerHeight)), 200));

/* Create Application */
const App = () => (
    <Provider store={store}>
        <Layout />
    </Provider>
)

/* Render Red */
ReactDOM.render(<App />, document.getElementById('root'));

/* Register service worker for PWA */
serviceWorker.register({CiSwCallback: CiSwCallback});