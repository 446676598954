import React, { useState } from 'react';

import MaterialAlert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

 const Alert = ({action, children, className, closeable, margin, severity, style, variant}) => {
    const [open, setOpen] = useState(true);
    return (
        <Collapse in={open}>
            <MaterialAlert 
                action={action}
                classes={{root: className}}
                elevation={0}
                onClose={closeable ? () => setOpen(false) : undefined}
                severity={severity}
                style={{
                    ...style,
                    marginBottom: margin 
                }}
                variant={variant ?? "standard"}
            >
                <Typography variant="caption">
                    {children}
                </Typography>
            </MaterialAlert>
        </Collapse>
    )
}

export default Alert;