import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { handleAppError } from 'Actions/UI/UI';

const styles = () => ({
    content: {
        cursor: 'pointer', 
        height: '100%', 
        paddingTop: 24,
        paddingBottom: 16
    },
    disabled: {
        backgroundColor: 'rgba(187, 186, 186, 0.1)',
        color: 'rgba(0,0,0,0.5)',
        cursor: 'default'
    },  
    input: {
        display: 'none'
    },
    zone: {
        background: '#fafafa',
        border: '1px solid #ddd',
        whiteSpace: 'nowrap', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis',
        textAlign: 'center',
    },  
    hasFile: {
        backgroundColor: '#E8F5E9!important'
    },  
    hover: {
        backgroundColor: '#ffebee!important'
    }, 
});

const initialState = {
    innerKey: 1,
    hover: false
}

class DragFileInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState;
        this.input = React.createRef();
    }

    componentDidMount = () => {
        this.setState({
            innerKey: Math.random().toString(36),
        });
    }

    handleChange = (drop, name, e) => {

        const   { disabled, multiple, extensions, onChange }    = this.props,
                file                                            = drop === true ? (multiple ? e?.dataTransfer?.files : e?.dataTransfer?.files[0]) : (multiple ? e?.target?.files : e?.target?.files[0]);

        if(drop) {
            e.preventDefault(); 
        }

        if(disabled) {
            return;
        }

        if(extensions) {
            if(multiple) {
                let invalid = 0;
                Array.from(file).forEach(f => {
                    if(!extensions.includes(f?.name?.substring(f?.name?.lastIndexOf('.') + 1))) {
                        invalid++;
                    }   
                })
                if(invalid === 0) {
                    onChange(drop, name, file)
                } else {
                    this.props.deploySnackBar?.(`error`, `There was an issue uploading ${invalid} file(s), please check valid file types and try again`)
                }
            } else {
                if(extensions.includes(file?.name?.substring(file?.name?.lastIndexOf('.') + 1))) {
                    onChange(drop, name, file)
                }
            }
        } else {
            onChange(drop, name, file)
        }

        this.setState({
            hover: false
        });

    }

    handleClearFile = () => {

        const { cancelOnClick } = this.props;

        this.setState({
            innerKey: Math.random().toString(36),
        }, () => {
            if(cancelOnClick) {
                cancelOnClick()
            }
        });

    }
    
    handleDragOver = e => {

        const { disabled } = this.props;

        e.preventDefault();      

        if(disabled) {
            return;
        }

        this.setState({
            hover: true 
        });

    }

    handleDragLeave = () => {

        this.setState({
            hover: false
        });

    }
    
    handleOpenDialog = () => {

        const { disabled } = this.props;

        if(disabled) {
            return;
        }

        this.input.current.click();

    }

    render() {
        const { innerKey, hover } = this.state;
        const { classes, disabled, errorText, file, icon, id, images, label, name, multiple, placeholder, primary } = this.props;
        return (
            <FormControl 
                error={!_.isEmpty(errorText)}
                fullWidth 
                key={innerKey}
                margin="normal"                         
            >                
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div
                            className={`${classes.zone} ${hover ? classes.hover : ''} ${file && !multiple ? classes.hasFile : ''} ${disabled ? classes.disabled : ''}`}
                            onDragOver={this.handleDragOver}
                            onDragLeave={this.handleDragLeave}
                            onDrop={(e) => this.handleChange(true, name, e)} 
                        >
                            <div onClick={this.handleOpenDialog} className={classes.content}>
                                <input
                                    accept={images ? 'image/*' : undefined}
                                    className={classes.input}
                                    disabled={disabled}
                                    file={file}       
                                    id={id}
                                    name={name}
                                    multiple={multiple}
                                    onChange={e => this.handleChange(false, name, e)} 
                                    ref={this.input}
                                    type="file"
                                />
                                {(hover && (
                                    <FAIcon type="light" icon="cloud-upload" size={25} button noMargin />
                                )) || (
                                    ((primary || icon) && (
                                        <Grid container spacing={1} justify='center' alignItems='center'>
                                            <Grid item>
                                                <FAIcon type="light" icon={icon ? icon : primary} size={25} button noMargin />
                                            </Grid>
                                        </Grid>
                                    )) || (
                                        <Grid container spacing={1} justify='center' alignItems='center'>
                                            <Grid item>
                                                <FAIcon type="light" icon="file-excel" size={20} button noMargin />
                                            </Grid>
                                            <Grid item>
                                                <FAIcon type="light" icon="file-video" size={22.5} button noMargin />
                                            </Grid>
                                            <Grid item>
                                                <FAIcon type="light" icon="file-image" size={25} button noMargin />
                                            </Grid>
                                            <Grid item>
                                                <FAIcon type="light" icon="file-pdf" size={22.5} button noMargin />
                                            </Grid>
                                            <Grid item>
                                                <FAIcon type="light" icon="file-csv" size={20} button noMargin />
                                            </Grid>
                                        </Grid>
                                    )
                                )}
                                <Box pt={0.5}>
                                    {(!multiple && file?.name && (
                                        <Typography variant="body2" onClick={this.handleClearFile}>
                                            {file?.name}
                                        </Typography>
                                    )) || (placeholder && (                                 
                                        <Typography variant="body2">
                                            {placeholder}
                                        </Typography>
                                    )) || (
                                        (hover && (
                                            <Typography variant="body2">
                                                Drop file to upload it
                                            </Typography>
                                        )) || (
                                            <Typography variant="body2">
                                                Choose a file or drag it here
                                            </Typography>
                                        )
                                    )}
                                </Box>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                {errorText && (
                    <Grid item xs={12} className="pt-2">
                        <FormHelperText>{errorText}</FormHelperText>
                    </Grid>
                )}
            </FormControl>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError: (error, message) => dispatch(handleAppError(error, message))
    };
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(DragFileInput));