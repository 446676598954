import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export function cloneObject(obj) {
    var clone = {};
    for(var i in obj) {
        if(obj[i] != null &&  typeof(obj[i])=="object")
            clone[i] = cloneObject(obj[i]);
        else
            clone[i] = obj[i];
    }
    return clone;
}

export function handleStepChange(offset) {
    this.setState({
        currentStep: (this.state.currentStep + offset)
    })
}   

export function handleTabChange(event, tabIndex) {
    this.setState({
        currentTab: !isNaN(event) ? event : tabIndex 
    })
}   

export function TabPanel(props) {
    const { children, value, index, ...other } = props;  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}

export function toggleDialog(dialog, loadOnExit) {
    let closing = this.state.dialog[dialog]
    this.setState({
        dialog: {
            ...this.state.dialog,
            [dialog]: !this.state.dialog[dialog]
        }
    }, () => {
        if(closing && !loadOnExit) {
            this.loadComponentData()
        }
    })
}

export const currencyFormat = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2
})

export const isNumeric = n => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const hex2rgba = (hex, alpha = 1) => {
    if(hex.length === 4) {
        hex = `${hex}${hex.substr(1,4)}`
    }
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))
    return `rgba(${r},${g},${b}, ${alpha})`
}

export const getLeadTime = (leadTime, short = false) => {

    let leadTimeText = 'Unknown';

    if(leadTime && leadTime.length > 0) {
        switch(leadTime) {
            case "CHECK": 
                leadTimeText = `Check${!short ? ' Lead Time' : ''}`
            break;
            case "SAME": 
                leadTimeText = "Same Day"
            break;
            default:
                leadTimeText = leadTime.split(":");
                leadTimeText = `${leadTimeText[0]} ${leadTimeText[1].charAt(0)}${leadTimeText[1].slice(1).toLowerCase()}`
            break;
        }
    }

    return leadTimeText;

}

export const accountCreditPosition = idx => {
    idx = parseInt(idx)
    switch(idx) {
        case 0:
            return "Good"
        case 1:
            return "Task"
        case 2: 
            return "Warning"
        case 3:
            return "Legal"
        default:
            return "-"
    }
}

export const accountPaymentDueFrom = idx => {
    idx = parseInt(idx)
    switch(idx) {
        case 0:
            return "days"
        case 1:
            return "days from the first day of the following month"
        case 2: 
            return "days before the last day of the following month"
        case 3:
            return "day of the month"
        case 4:
            return "days from 1 month (from invoice)"
        default:
            return "-"
    }
}

export const accountStatus = idx => {
    idx = parseInt(idx)
    switch(idx) {
        case 0:
            return "Open"
        case 1:
            return "See Notes [On Hold]"
        case 2: 
            return "Closed [On Hold]"
        case 3:
            return "Liquidation [On Hold]"
        case 4:
            return "Expired Credit Limit [On Hold]"
        case 5:
            return "Exceeded Credit Limit [On Hold]"
        case 6:
            return "Bad Debt [On Hold]"
        case 7:
            return "Solicitors [On Hold]"
        case 8:
            return "New"
        case 9:
            return "Cash Only"
        case 10:
            return "Prospect"
        case 11:
            return "Proforma Account [On Hold]"
        case 12:
            return "Factored Account"
        default:
            return "-"
    }
}