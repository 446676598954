/*
 * Crushed Ice - Service Worker Configuration
 */

export function register(config) {

    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {  
  
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  
        if (publicUrl.origin !== window.location.origin) {
            return;
        }    

        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/RedServiceWorker.js`;
            registerValidSW(swUrl, config);
        });  

    }    
}
  
function registerValidSW(swUrl, config) {

    navigator.serviceWorker.register(swUrl).then(registration => {

        registration.onupdatefound = () => {

            const installingWorker = registration.installing;

            if(installingWorker == null) {
                return;
            }

            installingWorker.onstatechange = () => {
                if(installingWorker.state === 'installing') {
                    console.log("MyCrushedIce: Service Worker Installing...");
                }
                if(installingWorker.state === 'installed') {
                    console.log("MyCrushedIce: Service Worker Install Successful");
                    if(config && config.CiSwCallback) {
                        config.CiSwCallback(true);                        
                        setTimeout(() => { 
                            console.log("MyCrushedIce: Service Worker Install Callback Reset");
                            config.CiSwCallback(false) 
                        }, 2500);
                        console.log("MyCrushedIce: Service Worker Install Callback Fired");
                    }
                }                   
            };
        };
    })
    .catch(() => {
        if(config && config.CiSwCallback) {
            return;
        }
    });
}
  
export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister();
        });
    }
}