import React from 'react';
import ReactHowler from 'react-howler';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';

import ClientContentWrapper from 'Components/Layout/ClientLayout/ClientContentWrapper';
import ClientHeader from 'Components/Layout/ClientLayout/ClientHeader';
import ClientNav from 'Components/Layout/ClientLayout/ClientNav';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import Dialog from 'Components/Common/Dialogs/Dialog';
import ErrorDialog from 'Components/Common/ErrorHandling/ErrorDialog'
import Footer from 'Components/Layout/Common/Footer';
import MessageDrawer from 'Components/Messages/MessageDrawer';
import RequireAuth from 'Components/RequireAuth';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import theme from 'Components/Common/Theme/Theme';
import { closeDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { handleAppError } from 'Actions/UI/UI';
import { stopSound } from 'Actions/Sounds/Sounds';

const initialState = {
    appError: {
        key: uuidv4(),
        detail: "",
        state: false,
    },
    confirmation: {
        key: uuidv4(),
        open: false,
        message: '',
        close: () => {},
        success: () => {},
    }, 
    dialog: {
        key: uuidv4(),
        dialogOpen: false,
        disableDialogContent: false,
        dialogContent: '',
        dialogTitle: '',
        dialogVariant: '',
        dialogSize: '',
        dialogFullScreen: false,
        dialogDisableExit: false
    },  
    drawers: {
        nav: true,
        chat: false
    },
    isMobile: false,
    pagePath: '',
    pageTitle: [],
    snackbar: {
        key: uuidv4(),
        message: "",
        variant: "",
        state: false
    },
}
class ClientLayout extends React.PureComponent {
    constructor() {
        super();
        this.state = initialState;
    }

    componentDidMount = () => {
        this.setState({
            appError: {
                ...this.state.appError,
                detail: this.props.ui.appError.detail,
                state: this.props.ui.appError.state
            },
            confirmation: {
                ...this.state.confirmation,
                open: this.props.confirmation.open,
                message: this.props.confirmation.message,
                close: this.props.confirmation.close,
                success: this.props.confirmation.success
            },
            dialog: {
                ...this.state.dialog,
                dialogOpen: this.props.dialog.dialogOpen,
                dialogContent: this.props.dialog.dialogContent,
                dialogTitle: this.props.dialog.dialogTitle,
                disableDialogContent: this.props.dialog.disableDialogContent,
                dialogVariant: this.props.dialog.dialogVariant,
                dialogSize: this.props.dialog.dialogSize,
                dialogFullScreen: this.props.dialog.dialogFullScreen,
                dialogDisableExit: this.props.dialog.dialogDisableExit
            },
            isMobile: this.props.ui.device.isMobile,
            drawers: {
                ...this.state.drawers,
                nav: this.props.ui.device.isMobile ? false : true
            },
            pagePath: this.props.history.location.pathname,
            snackbar: {
                ...this.state.snackbar,
                message: this.props.snackbar.message,
                variant: this.props.snackbar.variant,
                state: this.props.snackbar.message.length > 0 ? true : false
            }
        })        
    }

    componentDidUpdate = prevProps => {
        if(this.props.ui.appError.state !== prevProps.ui.appError.state) {
            this.setState({
                appError: {
                    ...this.state.appError,
                    key: uuidv4(),
                    state: this.props.ui.appError.state,
                    detail: this.props.ui.appError.detail
                }
            });
        }
        if(this.props.confirmation.message !== prevProps.confirmation.message) {
            this.setState({
                confirmation: {
                    ...this.state.confirmation,
                    key: uuidv4(),
                    open: this.props.confirmation.open,
                    message: this.props.confirmation.message,
                    close: this.props.confirmation.close,
                    success: this.props.confirmation.success,
                }
            });
        }
        if(this.props.dialog.dialogContent !== prevProps.dialog.dialogContent) {
            this.setState({
                dialog: {
                    ...this.state.dialog,
                    key: uuidv4(),
                    dialogOpen: this.props.dialog.dialogOpen,
                    dialogContent: this.props.dialog.dialogContent,
                    disableDialogContent: this.props.dialog.disableDialogContent,
                    dialogTitle: this.props.dialog.dialogTitle,
                    dialogVariant: this.props.dialog.dialogVariant,
                    dialogSize: this.props.dialog.dialogSize,
                    dialogFullScreen: this.props.dialog.dialogFullScreen,
                    dialogDisableExit: this.props.dialog.dialogDisableExit
                }
            });
        }
        if(this.props.snackbar.message !== prevProps.snackbar.message) { 
            this.setState({
                snackbar: {
                    ...this.state.snackbar,
                    key: uuidv4(),
                    message: this.props.snackbar.message,
                    variant: this.props.snackbar.variant,
                    state: this.props.snackbar.message.length > 0 ? true : false
                }
            });
        }
        if(this.props.ui.device.isMobile !== prevProps.ui.device.isMobile) {
            this.setState({
                isMobile: this.props.ui.device.isMobile,
                drawers: {
                    ...this.state.drawers,
                    nav: this.props.ui.device.isMobile ? false : true
                }
            });
        }
    }

    toggleDrawer = type => {
        this.setState({
            drawers: {
                ...this.state.drawers,
                [type]: !this.state.drawers[type]
            }
        });
    }

    changePath = path => {
        if(path !== this.state.pagePath){
            this.setState({
                drawers: {
                    ...this.state.drawers,
                    nav: this.state.isMobile === true ? false : this.state.drawers.nav
                },
                pagePath: path,
            });
        } else { 
            this.setState({
                drawers: {
                    ...this.state.drawers,
                    nav: this.state.isMobile === true ? false : this.state.drawers.nav
                }
            });
        }
    }

    handleDialogClose = () => {
        this.props.closeDialog();
    }

    handleSnackbarClose = () => {
        this.props.deploySnackBar("success", "")
    }

    pageTitle = title => {
        if(title !== this.state.pageTitle){
            this.setState({
                pageTitle: title
            });
        } 
    }

    render() {
        const { badges, history, location, sound, stopSound, ui } = this.props;
        const { appError, confirmation, dialog, drawers, pagePath, pageTitle, snackbar } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <ClientHeader    
                    history={history}
                    location={location}
                    toggleDrawer={this.toggleDrawer}
                />
                <ClientNav   
                    history={history}
                    navOpen={drawers.nav}
                    notifications={badges}
                    toggleDrawer={this.toggleDrawer}
                    pagePath={pagePath} 
                    scrollHeight={ui.device.height}
                />
                <MessageDrawer   
                    open={drawers.chat}
                    toggleDrawer={this.toggleDrawer}
                />
                <ClientContentWrapper    
                    changePath={this.changePath}
                    location={location}
                    history={history}
                    drawers={drawers}
                    page={pageTitle}
                    pageTitle={this.pageTitle}    
                    pagePath={pagePath}
                    scrollHeight={ui.device.height}
                />
                <Footer />
                <ConfirmationDialog 
                    open={confirmation.open} 
                    success={confirmation.success} 
                    close={confirmation.close} 
                    message={confirmation.message}
                /> 
                <Dialog 
                    key={dialog.key}
                    dialogOpen={dialog.dialogOpen}
                    dialogContent={dialog.dialogContent}
                    disableDialogContent={dialog.disableDialogContent}
                    dialogTitle={dialog.dialogTitle}
                    dialogVariant={dialog.dialogVariant}
                    dialogSize={dialog.dialogSize}
                    handleDialogClose={this.handleDialogClose}
                    dialogFullScreen={dialog.dialogFullScreen}
                    dialogDisableExit={dialog.dialogDisableExit}
                />
                <SnackBar
                    key={snackbar.key}
                    autoHideDuration={4500}
                    variant={snackbar.variant}
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={snackbar.state}
                    onClose={this.handleSnackbarClose}
                    message={snackbar.message}
                />
                {appError.state && (
                    <ErrorDialog
                        key={appError.key}
                        state={appError.state}
                        detail={appError.detail}
                        history={history}
                        closeError={() => this.props.handleAppError(false, "")}
                    />
                )}
                {sound.src !== null && (
                    <ReactHowler
                        src={sound.src}
                        playing={sound.status === 'playing'}
                        onEnd={stopSound}
                    />
                )}
            </ThemeProvider>
        )
    }
}

function mapStateToProps(state){
    return {
        badges: state.pulse.badges,
        confirmation: state.confirmation,
        dialog: state.dialog,
        sound: state.sound,
        snackbar: state.snackbar,
        ui: state.ui,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeDialog()),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        handleAppError: (error, message) => dispatch(handleAppError(error, message)),
        stopSound: () => dispatch(stopSound())
    };
}

export default RequireAuth(connect(mapStateToProps, mapDispatchToProps)(ClientLayout));