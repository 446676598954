import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Avatar from 'Components/Common/Badges/Avatar';

const ThreadAvatar = ({staff}) => (
    <Grid item>
        <Box ml={1}>
            <Avatar userStatus={staff} size='sm' noStatus />
        </Box>
    </Grid>
)

export default ThreadAvatar;