import React from 'react';
import { connect } from 'react-redux';

import AdminRouteReferences from 'Components/Routes/AdminRoutes/AdminRouteReferences';
import ClientRouteReferences from 'Components/Routes/ClientRoutes/ClientRouteReferences';
import { authStaff } from 'Actions/Auth/Auth';
import { handleAppError } from 'Actions/UI/UI';
import { clearToken, forceStoredProfile, getToken, isAdmin, isTokenExpired, setLastActivePage } from 'Functions/AuthFunctions';

const RequireAuth = ChildComponent => {
    
    class ComposedComponent extends React.Component {

        componentDidMount() {
            this.shouldNavigateAway();
        }
        
        componentDidUpdate(prevProps){
            if(this.props.location.pathname !== prevProps.location.pathname){
                this.shouldNavigateAway();
            }
            return false;
        }
        
        shouldNavigateAway(){
            // Get login / token expiration status (arr [0] => token, arr [1] => expired?)
            const token = getToken();

            if (!token) {

                // User Not Logged In
                this.props.history.replace('/login')

            } else if (isTokenExpired(token)) {

                // Token Expired
                setLastActivePage(window.location.pathname);

                forceStoredProfile();

                clearToken();

                this.props.history.replace('/login/expired')

            } else if(this.props.loggedInUser && this.props.loggedInUser.id === 0) {

                this.props.authStaff(getToken());

            }
            
            let accessRef = null;
            
            if(this.props.location.pathname === '' || this.props.location.pathname === '/' || this.props.location.pathname === '/dashboard') {
                accessRef = 'dashboard';
            } else { // eslint-disable-next-line
                if(isAdmin()) {

                    for (const [key, value] of Object.entries(AdminRouteReferences)) {
                        if(this.props.location.pathname.startsWith(key)) {
                            accessRef = value;
                            break;
                        }
                    }

                    // If access ref is empty - page isn't mapped!
                    if(accessRef !== true) {
                        if(accessRef === false) {
                            // Router unauthorized
                            this.props.handleAppError("UNAUTHORIZED", this.props.location.pathname)
                            this.props.history.replace('/')
                        } else {
                            // this.props.history.push('/404')
                        }
                    }

                } else {

                    const data = ClientRouteReferences();

                    for (const [key, value] of Object.entries(data)) {
                            if(this.props.location.pathname.startsWith(key)) {
                            accessRef = value;
                            break;
                        }
                    }

                    // If access ref is empty - page isn't mapped!
                    if(accessRef !== true) {
                        if(accessRef === false) {
                            // Router unauthorized
                            this.props.handleAppError("UNAUTHORIZED", this.props.location.pathname)
                            this.props.history.replace('/')
                        } else {
                            this.props.history.push('/404')
                        }
                    }
                    
                }

            }
        
        }

        render() {
            return <ChildComponent {...this.props}  />;
        }    

    }

    const mapStateToProps = (state) => {
        return {
            loggedInUser: state.auth.user
        };
    }

    const mapDispatchToProps = (dispatch) => {
        return {
            authStaff: token => dispatch(authStaff(token)),
            handleAppError: (error, message) => dispatch(handleAppError(error, message))
        };
    }
    
    return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent);

}

export default RequireAuth;