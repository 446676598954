import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import MuiAvatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

import defaultAvatar from 'Assets/Images/defaultUser.png';
import { S3_BUCKET_URL } from 'Constants';

const UserOnline = withStyles(theme => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        }
    }
}))(Badge);

const UserIdle = withStyles(theme => ({
    badge: {
        backgroundColor: '#FFA726',
        color: '#FFA726',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        }
    }
}))(Badge);

const UserOffline = withStyles(theme => ({
    badge: {
        backgroundColor: '#d32f2f',
        color: '#d32f2f',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
    }
}))(Badge);

export const Avatar = ({loggedInUser, model, noStatus, size, userStatus}) => {
    const status = useSelector(state => state.pulse.status);

    if(loggedInUser || model || userStatus) {


        let CiAvatar = <React.Fragment />
        let onlineStatus = 0;

        if(loggedInUser) {
            CiAvatar = <MuiAvatar alt={`${loggedInUser.firstName} ${loggedInUser.lastName}`} src={_.isEmpty(loggedInUser.picture) ? defaultAvatar : `${S3_BUCKET_URL}${loggedInUser.picture}`} style={{width: size === "lg" ? 64 : (size === "md" ? 39 : (size === "sm" ? 25 : 32)), height: size === "lg" ? 64 : (size === "md" ? 39 : (size === "sm" ? 25 : 32))}} />
            if(loggedInUser.type === 'ADMIN') {
                onlineStatus = status?.admin?.[loggedInUser?.id]?.status
            } else {
                onlineStatus = status?.user?.[loggedInUser?.id]?.status
            }
        }
    
        if(userStatus) {
            CiAvatar = <MuiAvatar alt={`${userStatus.name}`} src={_.isEmpty(userStatus.picture) ? defaultAvatar : `${S3_BUCKET_URL}${userStatus.picture}`} style={{width: size === "lg" ? 64 : (size === "md" ? 39 : (size === "sm" ? 25 : 32)), height: size === "lg" ? 64 : (size === "md" ? 39 : (size === "sm" ? 25 : 32))}} />
            onlineStatus = userStatus.status
        }

        if(model) {
            CiAvatar = <MuiAvatar alt={`${model.cu_first_name ? `${model.cu_first_name} ${model.cu_last_name}` : `${model.user_first_name} ${model.user_last_name}`}`} src={_.isEmpty(model.cu_profile_photo) && _.isEmpty(model.user_profile_photo) ? defaultAvatar : `${S3_BUCKET_URL}${model.cu_profile_photo ?? model.user_profile_photo}`} style={{width: size === "lg" ? 64 : (size === "md" ? 39 : (size === "sm" ? 25 : 32)), height: size === "lg" ? 64 : (size === "md" ? 39 : (size === "sm" ? 25 : 32))}} />
        }

        if(!status || noStatus) {
            return CiAvatar;
        } else {
            switch(onlineStatus) {
                case 2:
                    return (
                        <UserOnline
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            variant="dot"
                        >
                            {CiAvatar}
                        </UserOnline>
                    )
                case 1: 
                    return (
                        <UserIdle
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            variant="dot"
                        >
                            {CiAvatar}
                        </UserIdle>
                    )
                default:
                    return (
                        <UserOffline
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            variant="dot"
                        >
                            {CiAvatar}
                        </UserOffline>
                    )
            }
        }
    } else {
        return <React.Fragment />
    }
}

export default Avatar;