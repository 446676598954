import { createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#32466c',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
            opaque: 'rgba(50,65,108,0.1)'
        },
        secondary: {
            main: '#91b9d3'
        },
        error: {
            main: '#32466c'
        }
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                padding: '24px 24px 8px 24px'
            }
        },
        MuiAlert: {
            filledWarning: {
                backgroundColor: '#FB8C00'
            }
        },
        MuiAvatar: {
            fallback: {
                display: 'none'
            }
        },
        MuiBadge: {
            colorSecondary: {
                position: 'absolute',
                marginTop: -10,
                marginRight: -20,
                backgroundColor: '#D61F26',
                color: '#fff',
                boxShadow: '0 0 0 1px #fff',
                animation: 'shake 10s infinite',
                transform: 'translate3d(0, 0, 0)!important',
                transformOrigin: 'initial!important',
                backfaceVisibility: 'hidden',
            }
        },
        MuiChip: {
            avatarColorPrimary: {
                background: '#32466c!important'
            }
        },
        MuiFormHelperText: {
            root: {
                marginTop: 0,
                marginBottom: '16px!important',
                color: '#c62828!important',
                fontWeight: 300
            }
        },
        MuiFormLabel: {
            root: {
                fontWeight: 300
            }
        },
        MuiInputBase: {
            colorSecondary: {
                margin: '0!important',
                color: '#fff!important',
            }
        },
        MuiInput: {
            root: {
                marginTop: 16,
                marginBottom: 16,
                "&error": {
                    marginBottom: 8
                }
            },
            underline: {
                '&:before': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
                },
                '&:after': {
                    borderBottom: '2px solid #32466c'
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '2px solid #32466c'
                },
            },
            colorSecondary: {
                '&::placeholder': {
                    color: '#fff',
                    opacity: 1
                },
                '&:before': {
                    borderBottom: '1px solid rgba(255,255,255,0.5)!important'
                },
                '&:after': {
                    borderBottom: '1px solid #fff!important'
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '1px solid #fff!important'
                }
            }
        },
        MuiFilledInput: {
            root: {
                border: '1px solid',
                borderColor: '#e0e0e0',
                borderBottom: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                marginTop: 0,
                marginBottom: 0,
            },
            adornedStart: {
                backgroundColor: '#fff!important',
                borderBottom: 'none'
            },
            input: {
                caretColor: '#32466c',
                padding: '12px 12px 10px',
                backgroundColor: '#fff',
            },
            underline: {
                '&:before': {
                    borderBottom: '1px solid #e0e0e0'
                },
                '&:after': {
                    borderBottom: '1px solid #e0e0e0'
                },
                '&:hover:not($disabled):not($error):before': {
                    borderBottom: '1px solid #e0e0e0'
                },
                '&:focus': {
                    borderBottom: '1px solid #e0e0e0'
                }
            }
        },
        MuiLinearProgress: {
            root: {
                height: 2.5
            }
        },
        MuiTableCell: {
            root: {
                fontWeight: 300
            },
            head: {
                padding: '12px 8px'
            },
            body: {
                padding: '12px 8px'
            }
        },
        MuiTablePagination: {
            selectRoot: {
                marginRight: 12
            }
        },
        MuiExpansionPanelSummary: {
            root: {
                backgroundColor: 'white',
                color:'#32466c'
            },
            expandIcon: {
                color: '#32466c'
            }
        },
        MuiCardContent: {
            root: {
                '&:last-child': {
                    paddingBottom: 16
                }
            }
        },
        MuiFormControlLabel: {
            label: {
                fontSize: 12
            }
        },
        MuiTypography: {
            root: {
                fontWeight: 300,
            },            
            subtitle1: {
                fontWeight: 300,
            },            
            subtitle2: {
                fontWeight: 300,
            },
            body2: {
                fontWeight: 300,
            },
            body1: {
                fontWeight: 300,
            },
            caption: {
                fontWeight: 300,
            },
            button: {
                fontWeight: 300,
            },
            h1: {
                fontWeight: 400,
            },
            h2: {
                fontWeight: 400,
            },
            h3: {
                fontWeight: 400,
            },
            h4: {
                fontSize: '1.75rem',
                fontWeight: 400,
            },
            h5: {
                fontWeight: 300,
                fontSize: '1.45em'
            },
            h6: {
                fontWeight: 400,
                color: '#000'
            },
            overline: {
                fontWeight: 300,
                lineHeight: "initial"
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#000'
            },
            contained: {
                fontSize: '0.833rem',
                transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
                boxShadow: 'none!important',
            },
            containedPrimary: {
                color: '#fff',
                fontSize: '0.9em',
                backgroundColor: '#32466c',
                '&:hover': {
                    backgroundColor: '#2c3d5f',
                }
            },
            root: {
                borderRadius: '.25rem',
                fontWeight: 400,
                padding: '.375rem .75rem',
                lineHeight: 1.5
            }
        },
        MuiDialogActions: {
            root: {
                padding: '12px 24px 24px 24px'
            }
        },
        MuiTab: {
            root: {
                minWidth: '125px!important'
            },
            wrapper: {
                fontWeight: 400
            }
        },
        MuiFormControl:{
            root: {
                marginTop: 0,
                marginBottom: 0,
                padding: 0
            }
        },
        MuiListItemAvatar: {
            root: {
                minWidth: 35
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 35
            }
        },
        MuiListItemText: {
            inset: {
                paddingLeft: 40
            }
        }
    }
});
export default theme;