import React from 'react';
import { useSelector } from 'react-redux';

import Avatar from 'Components/Common/Badges/Avatar';

const ClientUser = () => {
    const loggedInUser = useSelector(state => state.auth.user);
    return (
        (loggedInUser && (
            <Avatar loggedInUser={loggedInUser} size='md' />
        )) || (
            null
        )
    )
}

export default ClientUser;