import * as dialogActionTypes from 'Actions/Dialog/Types/Types';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    key: uuidv4(),
    dialogOpen: false,
    disableDialogContent: false,
    dialogContent: '',
    dialogTitle: '',
    dialogVariant: '',
    dialogSize: '',
    dialogFullScreen: false,
    dialogDisableExit: false,
}

const DialogReducer = (state = initialState, action) => {
    switch(action.type) {
        case dialogActionTypes.DIALOG_OPEN:
            return {
                ...state,
                key: uuidv4(),
                dialogOpen: true,
                dialogContent: action.content,
                disableDialogContent: action.disableDialogContent,
                dialogTitle: action.title,
                dialogVariant: action.variant,
                dialogSize: action.size,
                dialogFullScreen: action.fullscreen,
                dialogDisableExit: action.disableExit
            };
        case dialogActionTypes.DIALOG_CLOSE:
            return {
                ...state,
                dialogOpen: false,
                dialogContent: '',
                dialogTitle: '',
                dialogVariant: '',
                dialogSize: '',
                dialogFullScreen: false,
                dialogDisableExit: false
            };
        default:
            return state;
    }
}
export default DialogReducer;