import React from 'react';
import moment from 'moment';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Tile from 'Components/Common/Tiles/Tile';

class ViewStaffActions extends React.Component {
    render() {
        const { isloading, staff } = this.props;
        return ( 
            <>
                {(isloading && (
                    <LoadingCircle />
                )) || (        
                    <Tile
                        icon=""
                        color="#03A9F4"
                        title="Joined"
                        content={moment(staff.staff_created_datetime).format("DD/MM/YYYY")}
                    />                                           
                )}
            </>
        )
    }
}

export default ViewStaffActions;