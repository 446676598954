import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import PaddedError from 'Components/Common/ErrorHandling/PaddedError';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { S3_BUCKET_URL } from 'Constants';

const useStyles = makeStyles({
    card: {
        borderLeft: '2px solid #fff',
    },
    cardAttentionFao: {
        background: '#ffebee',
        borderRadius: 0
    },
    cardPriority: {
        borderLeft: '1px solid #ef3340',
    },
    cardContent: {
        padding: '0 16px 0 16px!important'
    },
    slimCardContent: {
        // padding: '0!important'
    },
    slimCardHeader: {
        // padding: '4px 0 12px 0!important',
        fontSize: '1px!important'
    }
});

const initialState = {
    access: {
        deleteNotes: false
    },
    deleted: []
}

const ViewNotes = ({button, inline, hideDeleteButton, notes, slim}) => {

    const [state, setState] = useState(initialState);
    const classes           = useStyles();
    const dispatch          = useDispatch();
    const userTeams         = useSelector(state => state.user?.staff?.teams)

    const handleDeleteNote = id => {

        API.put(`/misc/notes/${id}/delete`)
        .then(res => {
            if(res?.data) {

                let deleted = [...state.deleted];

                deleted.push(id)

                setState(state => ({
                    ...state,
                    deleted
                }))

                dispatch(deploySnackBar("success", "The note was successfully deleted"));

            }
        })

    }

    useEffect(() => {
        API.multiAccess([
            'delete-notes'
        ])
        .then(([
            deleteNotes
        ]) => {
            setState(state => ({
                ...state,
                access: {
                    deleteNotes
                }
            }))
        })

    }, []);

    const { access:{deleteNotes}, deleted } = state;

    return (
        (_.isEmpty(_.filter(notes, el => !deleted.includes(el.note_id) && el.note_type !== 'Deleted')) && (
            <PaddedError inline={inline}>
                There are no notes to show
            </PaddedError>
        )) || (
            <Grid container spacing={!inline ? 3 : undefined}>
                {_.map(_.orderBy(notes, el => el.note_type), (note, idx) => (
                    note.note_type !== 'Deleted' && !deleted.includes(note.note_id) && (                       
                        <Grid item xs={12} key={idx}>                                         
                            <Card 
                                classes={{root: `${(note.note_type === 'Important' && classes.cardPriority) || classes.card} ${note.for_team ? (userTeams.includes(note.for_team?.team_name ?? 0) ? classes.cardAttentionFao : '') : ''}`}} 
                                elevation={inline && 0}
                            >
                                <CardHeader
                                    action={
                                        deleteNotes && !hideDeleteButton && (
                                            <Tooltip title="Delete Note">
                                                <IconButton onClick={() => dispatch(deployConfirmation("Are you sure you want to delete this note?", () => handleDeleteNote(note.note_id)))}>
                                                    <FAIcon icon="trash-alt" size={15} button noMargin />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }
                                    avatar={
                                        <Avatar 
                                            alt={`${note.staff?.staff_first_name ?? ''} ${note.staff.staff_last_name ?? ''}`} 
                                            src={`${S3_BUCKET_URL}${note.staff?.staff_profile_photo}`}
                                        >
                                            {note.staff?.staff_first_name?.charAt(0) ?? 'AA'}
                                        </Avatar>
                                    }    
                                    className={slim ? classes.slimCardHeader : undefined}
                                    subheader={moment(note.note_created_datetime, "YYYY-MM-DD HH:mm:ss").fromNow()}
                                    title={`${note.staff?.staff_first_name ?? 'Unknown'} ${note.staff?.staff_last_name}${note.for_team ? ` - FAO: ${note.for_team.team_name} Team` : ''}`}
                                />
                                <CardContent className={slim ? classes.slimCardContent : classes.cardContent}>
                                    {note.note_value.split('\n').map((item, key) => (
                                        <Typography 
                                            key={key} 
                                            variant="caption"
                                            component="div" 
                                            paragraph
                                        >
                                            {item}
                                        </Typography>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                ))}
                {button && (
                    <Grid item xs={12} align='right'>
                        {button}
                    </Grid>
                )}
            </Grid>
        )
    )
}

export default ViewNotes;