import React from 'react';
import { useHistory } from "react-router-dom";

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { hex2rgba } from 'Functions/MiscFunctions';

const Tile = ({color, content, icon, title, noPaper, onClick}) => {
    let tile = <TileContent color={color} content={content} icon={icon} onClick={onClick} noPaper={noPaper} title={title} />
    if(!noPaper) {
        tile = (
            <Paper>
                {tile}
            </Paper>
        )
    }
    return (
        tile
    )
}


const TileContent = ({color, content, icon, noPaper, onClick, title}) => {

    const history = useHistory();
    
    const handleClick = onClick => {
        if(typeof onClick === "function") {
            onClick();
        } else {
            history.push(onClick);
        }
    }

    return (
        <Grid container className={`tileContainer${onClick ? ' link' : ''}`} onClick={() => onClick && handleClick(onClick)}>      
            <Grid item container alignItems='center' justify='center' className="tileSquare" style={{backgroundColor: !noPaper ? hex2rgba(color, 0.75) : undefined, padding: noPaper ? 0 : undefined}}>
                <FAIcon type="light" icon={icon} className={!noPaper ? 'tileIcon' : ''} style={{color: noPaper ? color : undefined}} size={35} noMargin />
            </Grid>
            <Grid item className="tileContent" style={{padding: noPaper ? 0 : undefined}}>
                <Grid container alignItems='center'>
                    <Grid item xs={12} align='left'>
                        <Typography variant='h5' className="textDefault">
                            {content}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align='left'>
                        <Typography variant="caption">
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>  
        </Grid>
    )
}

export default Tile;