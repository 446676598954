import React from 'react';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const getIcon = (icon, size, className, style) => {
    let icons;
    switch(icon) {
        case "address-card-primary":
            icons = (
                <>
                    <FAIcon type="light" icon="address-card" size={size} className={className} style={style} noMargin />
                    <FAIcon type="solid" icon="bookmark" size={size} className={`${className && `${className} `} textWarning`} style={style} transform="shrink-7 up-.75 right-3.75" noMargin />
                </>
            )
        break;
        case "invoice":
            icons = (
                <>
                    <FAIcon type="light" icon="file" size={size} className={className} style={style} noMargin />
                    <FAIcon type="solid" icon="hashtag" size={size} className={className} style={style} transform="shrink-12 left-2 up-4" noMargin />
                    <FAIcon type="solid" icon="bars" size={size} className={className} style={style} transform="shrink-12 down-3 left-2" noMargin />
                    <FAIcon type="solid" icon="pound-sign" size={size} className={className} style={style} transform="shrink-12 down-3 right-2" noMargin />
                </>
            )
        break;
        case "location-circle-primary":
            icons = (
                <>
                    <FAIcon type="light" icon="circle" size={size} className={className} style={style} noMargin />
                    <FAIcon type="solid" icon="location-arrow" size={size} className={`${className && `${className} `} textWarning`} style={style} transform="shrink-8.5 left-0.5 down-0.5" noMargin />
                </>
            )
        break;
        case "order":
            icons = (
                <>
                    <FAIcon type="light" icon="file" size={size} className={className} style={style} noMargin />
                    <FAIcon type="solid" icon="bars" size={size} className={className} style={style} transform="shrink-12 left-2 up-4" noMargin />
                    <FAIcon type="solid" icon="pound-sign" size={size} className={className} style={style} transform="shrink-9 down-2" noMargin />
                </>
            )
        break;
        case "order-slash":
            icons = (
                <>
                    <FAIcon type="light" icon="slash" size={size} className={className} style={style} noMargin />
                    <FAIcon type="light" icon="file" size={size} className={className} style={style} noMargin />
                    <FAIcon type="solid" icon="bars" size={size} className={className} style={style} transform="shrink-12 left-2 up-4" noMargin />
                    <FAIcon type="solid" icon="pound-sign" size={size} className={className} style={style} transform="shrink-9 down-2" noMargin />
                </>
            )
        break;
        case "purchase":
            icons = (
                <>
                    <FAIcon type="light" icon="file" size={size} className={className} style={style} noMargin />
                    <FAIcon type="solid" icon="bars" size={size} className={className} style={style} transform="shrink-12 left-2 up-4" noMargin />
                    <FAIcon type="solid" icon="signature" size={size} className={className} style={style} transform="shrink-7 down-3" noMargin />
                </>
            )
        break;
        case "purchase-slash":
            icons = (
                <>
                    <FAIcon type="light" icon="slash" size={size} className={className} style={style} noMargin />
                    <FAIcon type="light" icon="file" size={size} className={className} style={style} noMargin />
                    <FAIcon type="solid" icon="bars" size={size} className={className} style={style} transform="shrink-12 left-2 up-4" noMargin />
                    <FAIcon type="solid" icon="signature" size={size} className={className} style={style} transform="shrink-7 down-3" noMargin />
                </>
            )
        break;
        case "quote":
            icons = (
                <>
                    <FAIcon type="light" icon="file" size={size} className={className} style={style} noMargin />
                    <FAIcon type="solid" icon="bars" size={size} className={className} style={style} transform="shrink-12 left-2 up-4" noMargin />
                    <FAIcon type="light" icon="boxes" size={size} className={className} style={style} transform="shrink-9 down-2" noMargin />
                </>
            )
        break;
        case "quote-slash":
            icons = (
                <>
                    <FAIcon type="light" icon="slash" size={size} className={className} style={style} noMargin />
                    <FAIcon type="light" icon="file" size={size} className={className} style={style} noMargin />
                    <FAIcon type="solid" icon="bars" size={size} className={className} style={style} transform="shrink-12 left-2 up-4" noMargin />
                    <FAIcon type="light" icon="boxes" size={size} className={className} style={style} transform="shrink-9 down-2" noMargin />
                </>
            )
        break;
        case "sack-pound":
            icons = (
                <>
                    <FAIcon type="light" icon="sack" size={size} className={className} style={style} noMargin />
                    <FAIcon type="solid" icon="pound-sign" size={size} className={className} style={style} transform="shrink-9 down-2" noMargin />
                </>
            )
        break;
        case "search-pound":
            icons = (
                <>
                    <FAIcon type="light" icon="search" size={size} className={className} style={style} noMargin />
                    <FAIcon type="solid" icon="pound-sign" size={size} className={className} style={style} transform="shrink-9 left-2 up-2" noMargin />
                </>
            )
        break;
        case "vat":
            icons = (
                <>
                    <FAIcon type="light" icon="envelope-open" size={size} className={className} style={style} noMargin />
                    <FAIcon type="solid" icon="pound-sign" size={size} className={className} style={style} transform="shrink-9 up-2" noMargin />
                </>
            )
        break;
        default:
    }
    return icons;
}

const FACustomIcon = ({icon, size, className, noMargin, style}) => (
    <div className={!noMargin ? 'iconCustomMargin' : undefined} style={{width: size, height: size, ...style}}>
        <span className="fa-layers iconCustom">
            {getIcon(icon, size, className)}
        </span>
    </div>
)

export default FACustomIcon;