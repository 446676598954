import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector} from 'react-redux';
import { Redirect } from 'react-router';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import _ from 'lodash';

import CssBaseline from '@material-ui/core/CssBaseline';

import AdminLayout from 'Components/Layout/AdminLayout/AdminLayout';
import ClientLayout from 'Components/Layout/ClientLayout/ClientLayout';
import Login from 'Components/Login/Login';
import MessagePopout from 'Components/Messages/MessagePopout';
import { loadPulse } from 'Actions/Pulse/Pulse';
import { authStaff } from 'Actions/Auth/Auth';
import { clearToken, forceStoredProfile, getToken, isAdmin, isClient, isLoggedIn, isTokenExpired, setLastActivePage } from 'Functions/AuthFunctions';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const Layout = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const loggedInUser = useSelector(state => state.auth?.user);

    useEffect(() => {
        
        const getPulseData = () => {
            isLoggedIn() && dispatch(loadPulse())
        }

        let load = setTimeout(getPulseData, 500);
        let notifications = setInterval(getPulseData, 5000);

        return () => {
            if(load)
                clearTimeout(load);
            if(notifications)
                clearInterval(notifications);
        }

    }, [dispatch]);

    useEffect(() => {

        const token = getToken();

        if (token) {

            if (isTokenExpired(token)) {

                // Token Expired
                setLastActivePage(window.location.pathname);

                forceStoredProfile();

                clearToken();

                history.replace('/login/expired')

            } else {
                dispatch(authStaff(token));
            }
        
        }

    }, []);

    return (
        <BrowserRouter>
            <CssBaseline />
            <div className='displayFlex'>
                <Switch>
                    <Route 
                        exact 
                        path="/" 
                        render={
                            () => (
                                (isLoggedIn() && (
                                    <Redirect to="/dashboard" /> 
                                )) || (
                                    <Redirect to="/login" />
                                )
                            )
                        }
                    />
                    <Route 
                        exact 
                        path="/login"
                        render={
                            props => (
                                (isLoggedIn() && (
                                    <Redirect to="/dashboard" />
                                )) || (
                                    <Login {...props} /> 
                                )
                            )
                        }
                    />
                    <Route
                        exact
                        path="/messaging"
                        render={
                            props => (
                                (isLoggedIn() && (
                                    <MessagePopout {...props} />
                                )) || (                                            
                                    <Redirect to="/login" />
                                )
                            )
                        }
                    />
                    <Route 
                        render={
                            props => (
                                (isLoggedIn() && isAdmin() && (
                                    <AdminLayout {...props} />
                                )) || (isLoggedIn() && isClient() && ( 
                                    (!_.isEmpty(loggedInUser?.config) && (
                                        <ClientLayout {...props} />
                                    )) || (
                                        <div style={{margin: '0 auto', marginTop: '15%'}}>
                                            <LoadingCircle size={75} />
                                        </div> 
                                    )
                                )) || (
                                    <Redirect to="/login" />
                                )
                            )
                        }
                    />
                </Switch>
            </div>
        </BrowserRouter>
    )
}


export default Layout;