import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(3),
        },
    }
}));

const PaddedPaper = ({className, elevation, onClick, noPadding, style, children}) => {
    const classes = useStyles();
    return (
        <Paper 
            className={`${noPadding ? '' : classes.paper} ${className}`} 
            onClick={onClick}
            style={style}
            elevation={elevation}
        >
            {children}
        </Paper>
    )
}

export default PaddedPaper;