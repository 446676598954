import * as SnackBarActionTypes from 'Actions/SnackBar/Types/Types';
import { v4 as uuidv4 } from 'uuid';

 const initialState = {    
    key: uuidv4(),
    message: "",
    variant: "",
};

function SnackBarReducer(state = initialState, action) {
    switch (action.type) {
        case SnackBarActionTypes.DEPLOY_SNACKBAR:
            return {
                ...state,
                key: uuidv4(),
                message: action.payload.message,
                variant: action.payload.variant,
            };
        default:
            return state;
    }
}

 export default SnackBarReducer;