import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import WYSIWYG from 'Components/Common/WYSIWYG/WYSIWYG';

import { 
    getFormData,
    handleChange,
    handleCheckedChange,
    handleSelectChange,
    handleMultiSelectChange
} from 'Functions/FormFunctions';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import Textarea from 'Components/Common/Inputs/Textarea';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import Alert from 'Components/Common/Alert/Alert';

const initialState = () => ({
    blog: {},
    editors: {
        short: EditorState.createEmpty(),
        full: EditorState.createEmpty()
    },
    formData: {
        cat: '',
        downloads: [],
        pub: 0,
        fp: 0,
        full: '',
        hl: 0,
        imgs: [],
        short: '',
        slug: '',
        t: '',
        tags: '',
        defImg: '',
        newImg: [],
        newImgFc: [],
        newDownloads: [],
        newDownloadsFc: []
    },
    formErrors: [],
    isLoading: true,
    key: {
        newImg: uuidv4(),
        newDownloads: uuidv4()
    },
    post: {},
    uuid: {
        blogUuid: null,
        postUuid: null
    }
})

class BlogPost extends React.Component {

    constructor(props) {
        super(props)
        this.state = initialState();
        this.getFormData = getFormData.bind(this);
        this.handleChange = handleChange.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleMultiSelectChange = handleMultiSelectChange.bind(this);
    }

    componentDidMount = () => {

        const {
            blogUuid,
            postUuid
        } = this.props.match.params;

        const {
            pageTitle
        } = this.props;

        API.get(`/blogs/${blogUuid}`,
        {
            params: {
                cl: 1
            }
        })
        .then(res => {

            if(res?.data) {

                let blog        = res.data.blog,
                    isLoading   = postUuid ? true : false;
                
                this.setState({
                    uuid: {
                        blogUuid,
                        postUuid
                    },
                    isLoading,
                    blog
                }, () => {

                    if(postUuid) {
                        this.getPost();
                    } else {
                        pageTitle([0, 'Blogs', blog.nm, 'New Blog Post']);
                    }

                })

            }
            
        })

    }

    componentDidUpdate = (prevProps, prevState) => {
        if(!this.state.uuid.postUuid) {
            if(prevState.formData.t !== this.state.formData.t) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        slug: this.state.formData.t.toString().toLowerCase()
                        .replace(/\s+/g, '-')           // Replace spaces with -
                        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                        .replace(/^-+/, '')             // Trim - from start of text
                        .replace(/-+$/, '')
                    }
                })
            }
        }
    }

    getPost = () => {

        const {
            blog,
            uuid:{blogUuid, postUuid}
        } = this.state;

        const {
            pageTitle
        } = this.props;

        API.get(`/blogs/${blogUuid}/posts/${postUuid}`)
        .then(res => {

            if(res?.data) {

                let isLoading  = false,
                    post       = res?.data.post,
                    formData   = {...this.state.formData, ...post},
                    shortHtml  = convertFromHTML(formData?.short),
                    fullHtml   = convertFromHTML(formData?.full),
                    shortState = ContentState.createFromBlockArray(shortHtml.contentBlocks, shortHtml.entityMap),
                    fullState  = ContentState.createFromBlockArray(fullHtml.contentBlocks, fullHtml.entityMap),
                    short      = EditorState.createWithContent(shortState),
                    full       = EditorState.createWithContent(fullState);

                this.setState({
                    isLoading,
                    formData,
                    editors: {
                        short,
                        full
                    },
                    post
                }, () => {
                    pageTitle([0, 'Blogs', blog.nm, `Updating ${post.t}`]);
                });

            }

        })

    }

    handleEditorChange = (type, editor) => {
        this.setState({
            editors: {
                ...this.state.editors,
                [type]: editor
            },
            formData: {
                ...this.state.formData,
                [type]: draftToHtml(convertToRaw(editor.getCurrentContent()))
            }
        });
    }

    
    handleImageDefault = defImg => {
        this.setState({
            formData: {
                ...this.state.formData,
                defImg
            }
        })
    }

    handleDeleteExistingType = (type, idx) => {
        let rows = [...this.state.formData?.[type]];
        rows[idx].del = rows[idx].del === 1 ? 0 : 1;
        this.setState({
            formData: {
                ...this.state.formData,
                [type]: rows
            }
        })
    }

    handleNewType = (type, files) => {

        let rows    = Array.prototype.slice.call(this.state.formData?.[type] ?? []).concat(Array.prototype.slice.call(files)),
            key     = uuidv4();

        let newDownloadsFc = [...this.state.formData.newDownloadsFc]
        if(type === 'newDownloads') {
            _.each(Array.prototype.slice.call(files), f => {
                newDownloadsFc.push({
                    fn: f.name,
                    t: f.name,
                    d: ''
                })
            })
        }

        
        let newImgFc = [...this.state.formData.newImgFc]
        if(type === 'newImg') {
            _.each(Array.prototype.slice.call(files), f => {
                newImgFc.push({
                    t: f.name,
                })
            })
        }

        this.setState({
            formData: {
                ...this.state.formData,
                [type]: rows,
                newDownloadsFc,
                newImgFc,
            },
            key: {
                ...this.state.key,
                [type]: key
            }
        });
    }

    handleNewTypeDelete = (type, idx) => {

        let rows    = Array.from(this.state.formData?.[type] ?? []),
            key     = uuidv4();

        rows.splice(idx, 1);

        let newDownloadsFc = [...this.state.formData.newDownloadsFc]
        if(type === 'newDownloads') {
            newDownloadsFc.splice(idx, 1);
        }

        let newImgFc = [...this.state.formData.newImgFc]
        if(type === 'newImg') {
            newImgFc.splice(idx, 1);
        }

        this.setState({
            formData: {
                ...this.state.formData,
                [type]: rows,
                newDownloadsFc,
                newImgFc,
            },
            key: {
                ...this.state.key,
                [type]: key
            }
        });

    }
    
    handleRowsChange = (stateName, idx, fieldName, value) => {

        let rows    = [...this.state.formData?.[stateName]];

        rows[idx] = {
            ...rows[idx],
            [fieldName]: value 
        }

        this.setState({
            formData: {
                ...this.state.formData,
                [stateName]: rows,
            }
        });

    }

    handleSubmit = () => {
        console.log(this.state.formData)
        const {
            blogUuid,
            postUuid
         } = this.state.uuid;

        const route = postUuid ? `/blogs/${blogUuid}/posts/${postUuid}`: `/blogs/${blogUuid}/posts`; 
                
        this.setState({
            isLoading: true
        }, () => {

            API.post(route, this.getFormData())
            .then(result => {
                if(result.data?.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    })
                } else {
                    this.props.deploySnackBar(`success`, `You have successfully ${postUuid ? 'updated the' : 'created a new'} blog post`)
                    this.props.history.push(`/blogs/${blogUuid}`)
                }
            });

        });

    }

  	render() {

        const {
            postUuid
         } = this.state.uuid;

        const {
            blog,
            editors,
            formData,
            formErrors,
            isLoading,
            key
        } = this.state;

        const {
            deployConfirmation,
            deploySnackBar
        } = this.props;

		return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12} lg={9}>
                            <Grid container item spacing={3}>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Headline
                                        </Typography>
                                        <TextField
                                            name="t"
                                            value={formData.t}
                                            error={formErrors?.['title'] && true}
                                            helperText={formErrors?.['title']}
                                            placeholder='Article Title:'
                                            onChange={this.handleChange}
                                            margin="none"
                                            fullWidth
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Article
                                        </Typography>
                                        <WYSIWYG    
                                            editorState={editors.full}
                                            onChange={(e) => { this.handleEditorChange('full', e) }}
                                            margin="none"
                                            height={400}
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Excerpt
                                        </Typography>
                                        <WYSIWYG    
                                            editorState={editors.short}
                                            onChange={(e) => { this.handleEditorChange('short', e) }}
                                            margin="none"
                                            height={90}
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6" gutterBottom>
                                            Images
                                        </Typography>
                                        <Grid container spacing={3} className="mt-1 mb-1">
                                            {((!_.isEmpty(formData.imgs) || !_.isEmpty(formData.newImg)) && (
                                                <>
                                                    {!_.isEmpty(formData.imgs) && ( 
                                                        _.map(formData.imgs, (image, idx) => {
                                                            const defaultImage = image.del === 1 ? false : (formData.defImg === image.i ? true : false)
                                                            return (
                                                                <Grid item xs={6} lg={3} key={idx}>
                                                                    <Card style={{position: 'relative', background: '#343A40'}}>
                                                                        {defaultImage && (
                                                                            <div style={{position: 'absolute', top: 0, left: 0, textAlign: 'center', width: '100%', zIndex: 99}}>
                                                                                <Box style={{background: 'rgba(0,0,0,0.7)'}}>
                                                                                    <Box p={0.75}>
                                                                                        <FAIcon type="light" icon='star' noMargin size={15} className="textLight" /> 
                                                                                        <Typography variant="caption" component="span" className="textLight fw-400 pl-1">
                                                                                            Default Image
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            </div>
                                                                        )}
                                                                        {image.del === 1 && (
                                                                            <div style={{position: 'absolute', top: 0, left: 0, textAlign: 'center', width: '100%', zIndex: 99}}>
                                                                                <div style={{background: 'rgba(0,0,0,0.7)'}}>
                                                                                    <Box p={0.75}>
                                                                                        <FAIcon type="light" icon='exclamation-triangle' noMargin size={15} className="textLight" /> 
                                                                                        <Typography variant="caption" component="span" className="textLight fw-400 pl-1">
                                                                                            Pending Deletion
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </div>
                                                                            </div>
                                                                        )}                                   
                                                                        <ImageWithError src={image.src} style={{width: '100%', height:250, filter: image.del === 1 ? 'grayscale(100%)' : 'initial'}} alt={image.t} />
                                                                        <CardContent style={{textAlign:'center', padding: 0, paddingBottom: 7.5, position: 'relative'}}>
                                                                            {(image.del === 0 && (
                                                                                <>
                                                                                    <Tooltip title="Download Image">
                                                                                        <IconButton onClick={() => window.open(image.src)}>
                                                                                            <FAIcon type="light" icon='arrow-to-bottom' noMargin fixedHeight buttonPrimary />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    {!defaultImage && (
                                                                                        <>
                                                                                            <Tooltip title="Set Default Image">
                                                                                                <IconButton onClick={() => deployConfirmation(`Are you sure you want to set this as the default image?`, () => this.handleImageDefault(image.i))}>
                                                                                                    <FAIcon type="light" icon='check' noMargin fixedHeight buttonPrimary />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </>
                                                                                    )}
                                                                                    <Tooltip title="Delete Image">
                                                                                        <IconButton onClick={() => deployConfirmation(`Are you sure you want to ${image.del === 1 ? 'restore' : 'delete'} this image?`, () => this.handleDeleteExistingType('imgs', idx))}>
                                                                                            <FAIcon type="light" icon='trash' noMargin fixedHeight buttonPrimary />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </>
                                                                            )) || ( 
                                                                                <IconButton onClick={() => deployConfirmation(`Are you sure you want to restore this image?`, () => this.handleDeleteExistingType('imgs', idx))}>
                                                                                    <FAIcon type="light" icon='undo' noMargin fixedHeight buttonPrimary />
                                                                                </IconButton>
                                                                            )}
                                                                            <Box p={1} pb={0}>
                                                                                <Box style={{background: '#fff'}} mb={1}>
                                                                                    <Box p={1} mb={-1}>
                                                                                        <TextField
                                                                                            label={'Caption'}
                                                                                            value={formData.imgs?.[idx]?.t}
                                                                                            onChange={e => this.handleRowsChange('imgs', idx,  't', e?.target?.value ?? '')}
                                                                                            margin="none"
                                                                                            style={{marginBottom: -14}}
                                                                                            fullWidth
                                                                                        />
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Grid>
                                                            )
                                                        })
                                                    )}
                                                    {_.map(formData.newImg, (image, idx) => (
                                                        <Grid item xs={6} lg={3} key={idx}>
                                                            <Card style={{position: 'relative', background: '#343A40', border: formErrors?.[`newImg|${idx}`] ? '1px solid #ef3340' : undefined}}>                                            
                                                                <ImageWithError src={URL.createObjectURL(image)} style={{width: '100%', height:250}} alt={image.name} />
                                                                <CardContent style={{textAlign:'center', padding: 0, paddingBottom: 7.5, position: 'relative'}}>
                                                                    <Tooltip title="Delete Image">
                                                                        <IconButton onClick={() => deployConfirmation(`Are you sure you want to delete this image?`, () => this.handleNewTypeDelete('newImg', idx))}>
                                                                            <FAIcon type="light" icon='trash' noMargin fixedHeight buttonPrimary />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Box p={1} pb={0}>
                                                                        <Box style={{background: '#fff'}} mb={1}>
                                                                            <Box p={1} mb={-1}>
                                                                                <TextField
                                                                                    label={'Caption'}
                                                                                    value={formData.newImgFc?.[idx]?.t}
                                                                                    onChange={e => this.handleRowsChange('newImgFc', idx, 't', e?.target?.value ?? '')}
                                                                                    margin="none"
                                                                                    style={{marginBottom: -14}}
                                                                                    fullWidth
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    ))}
                                                </>
                                            )) || (
                                                <Grid item xs={12}>
                                                    <Alert severity="warning">
                                                        This post has no images
                                                    </Alert>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <DragFileInput
                                            id="newImg"
                                            key={key.newImg}
                                            name="newImg[]"
                                            label={`Upload ${postUuid ? 'Additional ' : ''}Article Images (.png, .jpg, .jpeg, .bmp, .gif)`}
                                            extensions={['png','jpg','jpeg','bmp','gif']}
                                            file={formData.newImg}
                                            errorText={formErrors?.['newImg']}
                                            onChange={(x, y, z) => this.handleNewType('newImg', z)}
                                            deploySnackBar={deploySnackBar}
                                            multiple
                                            images
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6" paragraph>
                                            Downloads
                                        </Typography>
                                        {((!_.isEmpty(formData.downloads) || !_.isEmpty(formData.newDownloads)) && ( 
                                            <Grid container spacing={3}>
                                                {!_.isEmpty(formData.downloads) && _.map(formData.downloads, (f, idx) => (
                                                    <Grid item xs={12} lg={6} key={idx}>
                                                        <Box border='1px solid #ddd' position="relative">
                                                            <ListItem 
                                                                className="pt-1 pb-1" 
                                                            >
                                                                <ListItemIcon className="pr-2">
                                                                    <Tooltip title="Delete">
                                                                        <IconButton
                                                                            onClick={() => deployConfirmation(`Are you sure you want to ${f.del === 1 ? 'restore' : 'delete'} this download file?`, () => this.handleDeleteExistingType('downloads', idx))}
                                                                        >
                                                                            <FAIcon
                                                                                icon={f.del === 1 ? 'undo' : 'trash'}
                                                                                type="light"
                                                                                size={20}
                                                                                noMargin
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </ListItemIcon>
                                                                <ListItemText 
                                                                    primary={
                                                                        <Box pb={2}>
                                                                            <Typography variant="caption" color="textSecondary">
                                                                                File
                                                                            </Typography>
                                                                            <Typography variant="body2" className="fw-400">
                                                                                {f.fn}
                                                                            </Typography>
                                                                        </Box>
                                                                    }
                                                                    secondary={
                                                                        <Box pr={1} pb={1}>
                                                                            <TextField
                                                                                label={'Title'}
                                                                                value={formData.downloads?.[idx]?.t}
                                                                                error={formErrors?.[`newDownloadsFc|${idx}|d`] && true}
                                                                                helperText={formErrors?.[`newDownloadsFc|${idx}|d`]}
                                                                                onChange={e => this.handleRowsChange('downloads', idx,  't', e?.target?.value ?? '')}
                                                                                margin="none"
                                                                                fullWidth
                                                                            />
                                                                            <Box pt={2} pb={1}>
                                                                                <Textarea
                                                                                    label={'Description'}
                                                                                    value={formData.downloads?.[idx]?.d}
                                                                                    onChange={e => this.handleRowsChange('downloads', idx, 'd', e?.target?.value ?? '')}
                                                                                    margin="none"
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                            />
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            {f.del === 1 && (
                                                                <div style={{position: 'absolute', top: 0, left: 0, textAlign: 'center', width: '100%', zIndex: 99}}>
                                                                    <div style={{background: 'rgba(0,0,0,0.7)'}}>
                                                                        <Box p={0.75}>
                                                                            <FAIcon type="light" icon='exclamation-triangle' noMargin size={15} className="textLight" /> 
                                                                            <Typography variant="caption" component="span" className="textLight fw-400 pl-1">
                                                                                Pending Deletion
                                                                            </Typography>
                                                                        </Box>
                                                                    </div>
                                                                </div>
                                                            )}     
                                                        </Box>
                                                    </Grid>
                                                ))}
                                                {!_.isEmpty(formData.newDownloads) && _.map(Array.from(formData.newDownloads), (f, idx) => (
                                                    <Grid item xs={12} lg={6} key={idx}>
                                                        <Box border='1px solid #ddd' position="relative">
                                                            <ListItem 
                                                                className="pt-1 pb-1" 
                                                            >
                                                                <ListItemIcon className="pr-2">
                                                                    <Tooltip title="Delete">
                                                                        <IconButton
                                                                            onClick={() => deployConfirmation(`Are you sure you want to delete this download?`, () => this.handleNewTypeDelete('newDownloads', idx))}
                                                                        >
                                                                            <FAIcon
                                                                                icon={f.del === 1 ? 'undo' : 'trash'}
                                                                                type="light"
                                                                                size={20}
                                                                                noMargin
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <Box pb={2}>
                                                                            <Typography variant="caption" color="textSecondary">
                                                                                File
                                                                            </Typography>
                                                                            <Typography variant="body2" className="fw-400">
                                                                                {f.name}
                                                                            </Typography>
                                                                        </Box>
                                                                    }
                                                                    secondary={
                                                                        <Box pr={1} pb={1}>
                                                                            <TextField
                                                                                label={'Title'}
                                                                                value={formData.newDownloadsFc?.[idx]?.t}
                                                                                error={formErrors?.[`newDownloadsFc|${idx}|t`] && true}
                                                                                helperText={formErrors?.[`newDownloadsFc|${idx}|t`]}
                                                                                onChange={e => this.handleRowsChange('newDownloadsFc', idx, 't', e?.target?.value ?? '')}
                                                                                margin="none"
                                                                                fullWidth
                                                                            />
                                                                            <Box pt={2} pb={1}>
                                                                                <Textarea
                                                                                    label={'Description'}
                                                                                    value={formData.newDownloadsFc?.[idx]?.d}
                                                                                    onChange={e => this.handleRowsChange('newDownloadsFc', idx, 'd', e?.target?.value ?? '')}
                                                                                    margin="none"
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                            />
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            {f.del === 1 && (
                                                                <div style={{position: 'absolute', top: 0, left: 0, textAlign: 'center', width: '100%', zIndex: 99}}>
                                                                    <div style={{background: 'rgba(0,0,0,0.7)'}}>
                                                                        <Box p={0.75}>
                                                                            <FAIcon type="light" icon='exclamation-triangle' noMargin size={15} className="textLight" /> 
                                                                            <Typography variant="caption" component="span" className="textLight fw-400 pl-1">
                                                                                Pending Deletion
                                                                            </Typography>
                                                                        </Box>
                                                                    </div>
                                                                </div>
                                                            )}    
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )) || (
                                            <Grid item xs={12}>
                                                <Alert severity="info">
                                                    This post has no download files
                                                </Alert>
                                            </Grid>
                                        )}
                                        <DragFileInput
                                            id="newDownloads"
                                            key={key.newDownloads}
                                            name="newDownloads[]"
                                            label={`Upload ${postUuid ? 'Additional ' : ''}Article Downloads`}
                                            file={formData.newDownloads}
                                            errorText={formErrors?.['newDownloads']}
                                            onChange={(x, y, z) => this.handleNewType('newDownloads', z)}
                                            multiple
                                        />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Publish
                                        </Typography>
                                        <Grid container justify="space-between">
                                            <Grid item>
                                                <FormControlLabel
                                                    classes={{
                                                        label: 'fw-400'
                                                    }}
                                                    control={
                                                        <Switch
                                                            checked={formData.pub}
                                                            onChange={this.handleCheckedChange}
                                                            name="pub"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={'Publish Article'}
                                                />
                                            </Grid>
                                            <Grid item align="right">
                                                <Button
                                                    disabled={_.isEmpty(formData.t) || _.isEmpty(formData.full)}
                                                    onClick={() => deployConfirmation(<><Typography variant="body2" className="fw-400">{`Are you sure you want to ${postUuid ? `update this` : `create a new`} blog post?`}</Typography><Typography variant="body2" className={formData.pub === 1 ? 'textError' : undefined}>{`This post ${formData.pub === 1 ? `will be published to your blog immediately` : `will be saved as a draft and not published to your blog`}`}</Typography></>, this.handleSubmit)}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    <FAIcon icon="check" size={15} buttonPrimary />
                                                    {postUuid ? 'Update' : 'Create'} Article
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Settings
                                        </Typography>
                                        <FormControlLabel
                                            classes={{
                                                label: 'fw-400'
                                            }}
                                            control={
                                                <Switch
                                                    checked={formData.hl}
                                                    onChange={this.handleCheckedChange}
                                                    name="hl"
                                                    color="primary"
                                                />
                                            }
                                            label="Headline Post"
                                        />
                                        <FormControlLabel
                                            classes={{
                                                label: 'fw-400'
                                            }}
                                            control={
                                                <Switch
                                                    checked={formData.fp}
                                                    onChange={this.handleCheckedChange}
                                                    name="fp"
                                                    color="primary"
                                                />
                                            }
                                            label="Featured Post"
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6" paragraph>
                                            SEO
                                        </Typography>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    name="slug"
                                                    label={'URL Slug'}
                                                    disabled={true}
                                                    error={formErrors?.[`slug`] && true}
                                                    helperText={formErrors?.[`slug`]}
                                                    value={formData.slug}
                                                    margin="none"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Category
                                        </Typography>
                                        <AutoCompleteSelect
                                            onChange={v => this.handleSelectChange('cat', v)}
                                            value={formData.cat}
                                            placeholder='Select Category:'
                                            options={blog.c}
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Tags
                                        </Typography>
                                        <AutoCompleteMultiSelect
                                            creatable
                                            onChange={v => this.handleMultiSelectChange('tags', v)}
                                            placeholder='Create Tag:'
                                            value={formData.tags}
                                            options={_.map(formData.tags, t => (
                                                _.assign({
                                                    label: t,
                                                    value: t
                                                })
                                            ))}
                                        />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
        
      }

}

const mapDispatchToProps = dispatch => ({
    deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
    deployConfirmation: (message, yes, no = false) => dispatch(deployConfirmation(message, yes, no))
})

export default connect(null, mapDispatchToProps)(BlogPost);