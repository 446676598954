import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingCircle = ({className, color, padding, size}) => (
    <div 
        style={{
            textAlign: 'center', 
            padding: (!isNaN(padding) ? padding : (padding && (padding === 'large' ? 48 : (padding === 'xs' ? 6 : 12))) || 24),
        }}
        className={className && className}
    >
        <CircularProgress 
            size={size && isNaN(size) ? ((size === 'large' ? 70 : (size === 'xlarge' ? 100 : 40))) : size}
            style={{
                color: color
            }}
        />
    </div>
)

export default LoadingCircle;