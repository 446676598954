import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const variantIcon = {
  success: "check-circle",
  warning: "exclamation-triangle",
  error: "exclamation-triangle",
  info: "info-circle"
};

const styles1 = theme => ({
    success: {
        backgroundColor: '#4CAF50',
    },
    error: {
        backgroundColor: '#c62828',
    },
    info: {
        backgroundColor: '#0288D1',
    },
    warning: {
        backgroundColor: '#F57C00',
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <FAIcon type="light" icon={variantIcon[variant]} className={classNames(classes.icon, classes.iconVariant)} size="small" style={{color: '#fff'}} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <FAIcon type="light" icon="times" size="small" buttonPrimary noMargin className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);


class SnackBar extends Component{
  constructor(props) {
    super(props);

    this.state = {
        open: false
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ 
        open: false 
    });
  };

  render(){
    return (
        <Snackbar
          autoHideDuration={this.props.autoHideDuration ? this.props.autoHideDuration : null}
          anchorOrigin={{vertical: this.props.anchorOriginVertical, horizontal: this.props.anchorOriginHorizontal,}}
          open={this.props.open}
          onClose={this.props.onClose}
        >
            <MySnackbarContentWrapper
                onClose={this.props.onClose}
                variant={this.props.variant}
                message={this.props.message}
            />
        </Snackbar>
    );
  }
}

export default (SnackBar);