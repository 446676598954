import { store } from "index";
import _ from 'lodash';

const ClientRouteReferences = () => {
    const config = store?.getState?.().auth?.user?.config;
    return {
        /* Blog*/
        "/blogs": config?.services?.blog?.enabled ?? false,
        /* Support */
        "/support": "support",
        /* Pre-authorized */
        "/my-account/": true,
        "/my-account": true,
        "/dashboard": true
    }
}

export default ClientRouteReferences