import React from 'react';
import { useDispatch } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { closeDialog } from 'Actions/Dialog/Dialog';

const useStyles = makeStyles(theme => ({
    heading: {
        textTransform: 'capitalize'
    },
    content : {
        padding: '0 24px 24px 24px', 
    },
    container: {
        marginTop: 88,
    },
    dialogPaper: {
        backgroundColor: '#fafafa'
    },
    dialogTitleSuccess: {
        backgroundColor: '#5DA14D',
        color: 'white',
        fontWeight: 'bold',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    dialogTitleError: {
        backgroundColor: '#D61F26',
        color: 'white',
        fontWeight: 'bold',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: '#fff'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog = ({dialogOpen, disableDialogContent, dialogContent, dialogDisableExit, dialogFullScreen, dialogTitle, dialogVariant, dialogSize, handleDialogClose}) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const close = () => {
        dispatch(closeDialog())
    }

    const generateDialogTitleClassName = () => {

        let className = '';

        switch(dialogVariant) {

            case 'success':
                className = classes.dialogTitleSuccess;
            break;

            case 'error':
                className = classes.dialogTitleError;
            break;

            default:
            break;
        }

        return className;
    }

    const Element = disableDialogContent ? React.Fragment : DialogContent;
    
    return (
        <MuiDialog
            disableEscapeKeyDown={dialogDisableExit}
            disableBackdropClick={dialogDisableExit}
            open={dialogOpen}
            TransitionComponent={Transition}
            onClose={handleDialogClose}
            maxWidth={dialogSize || 'md'}
            scroll="body"
            fullWidth
            fullScreen={dialogFullScreen}
            PaperProps={{
                className: dialogFullScreen && classes.dialogPaper
            }}
        >
            {(dialogFullScreen && (
                <>
                    <AppBar>
                        <Toolbar>
                            <Tooltip title="Cancel">
                                <IconButton edge="start" color="inherit" onClick={close}>
                                    <FAIcon type="light" icon='times' buttonPrimary noMargin />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h6" className={classes.title}>
                                {dialogTitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Container maxWidth="xl" className={classes.container}>
                        {dialogContent}
                    </Container>
                </>
            )) || (
                <>
                    {dialogTitle && (
                        <DialogTitle disableTypography className={generateDialogTitleClassName()}>
                            <Typography variant="h6" className={dialogVariant === "standard" ? 'textDefault' : 'textLight'}>
                                {dialogTitle}
                            </Typography>
                        </DialogTitle>
                    )}
                    <Element className={classes.content}>
                        {dialogContent}
                    </Element>
                </>
            )}
        </MuiDialog>
    )
}

export default Dialog;