import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

const initialState = () => ({
    isLoading: true,
    blogs: []
})

class Blogs extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState()
    }

    componentDidMount = () => {
        this.props.pageTitle([0, 'Blogs']);
        this.getBlogs();
    }

    getBlogs = () => {
        API.get('/blogs')
        .then(result => {
            if(result?.data?.blogs) {
                this.setState({
                    isLoading: false,
                    blogs: result.data.blogs
                })
            }
        })
        .catch(error => {
        })
    }

  	render() {
        const { isLoading, blogs } = this.state;
		return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12} lg={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            My Blogs
                                        </Typography> 
                                        <DataTable  
                                            config={{
                                                key: 'uuid',
                                                alternatingRowColours: true,
                                                responsiveImportance: true,
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Blog',
                                                    field: rowData => (
                                                        <Box pt={1} pb={1}>
                                                            <div>{rowData.nm}</div>
                                                            <Typography variant="caption">
                                                                {rowData.url}
                                                            </Typography>
                                                        </Box>
                                                    ),
                                                    important: true
                                                },
                                                {
                                                    heading: 'Post Count',
                                                    field: rowData => rowData.posts,
                                                    alignment: 'right',
                                                    important: true,
                                                    sizeToContent: true
                                                },
                                                {
                                                    heading: 'Lastest Post',
                                                    field: rowData => rowData.lpd,
                                                    important: true,
                                                    sizeToContent: true
                                                },
                                                {
                                                    actions: rowData => {
                                                        return [
                                                            {name: 'Visit Blog', icon: 'external-link-alt', onClick: () => window.open(rowData.url)},
                                                            {name: 'Manage', icon: 'arrow-right', link: '/blogs/' + rowData.uuid}
                                                        ]
                                                    }
                                                }
                                            ]}
                                            rows={blogs}
                                        />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
        
      }
      
}

export default Blogs;