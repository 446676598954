import React from 'react';
import moment from 'moment';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const ThreadDetails = ({myMessage, read, sent}) => (
    <>
        <Tooltip title={`Sent at ${moment(sent).format("HH:mm [on] DD/MM/YYYY")}`}>
            <Typography variant="caption" color="textSecondary">
                {moment(sent).fromNow()} 
            </Typography>
        </Tooltip>
        {myMessage && read && (
            <Tooltip title={`Seen at ${moment(read).format("HH:mm [on] DD/MM/YYYY")}`}>
                <Typography variant="caption" color="textSecondary">
                    {` `}&bull; Seen
                </Typography>
            </Tooltip>
        )}
    </>
)

export default ThreadDetails;