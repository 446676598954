import React from 'react';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';

import API from 'API';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import { authStaff } from 'Actions/Auth/Auth';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { handleFileChange } from 'Functions/FormFunctions';
import { S3_BUCKET_URL } from 'Constants';
import { loadStatus } from 'Actions/Pulse/Pulse';
import {
    setToken, 
    getDecodedToken, 
    getStoredProfile, 
    setStoredProfile
} from 'Functions/AuthFunctions';

const initialState = {
    currentProfilePhoto: null,
    formData: {},
    formErrors: {},
    confirmationOpen: false,
}

class UploadStaffProfilePhotoForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleFileChange = handleFileChange.bind(this);
    }

    componentDidMount(){
        this.setState({
            currentProfilePhoto: this.props.current ? `${S3_BUCKET_URL}${this.props.current}` :  this.props.loggedInUser.picture
        });
    }

    handleSubmit = () => {
        let formData = new FormData();
        formData.append('photo', this.state.formData.photo);

        API.post('/staff/' + this.props.staffId + '/profilePhoto', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(result => {

            if(result.data.errors) {

                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });

            } else {

                this.setState({
                    ...initialState,
                    currentProfilePhoto: `${S3_BUCKET_URL}${result.data.key}`
                }, () => {
                    if(result.data && result.data.auth && result.data.auth.token) {
                        setToken(result.data.auth.token);
                        const profile = getDecodedToken();
                        const storedProfile = getStoredProfile();
                        setStoredProfile({
                            active: storedProfile.active,
                            temp: storedProfile.temp,
                            firstName: profile.firstName,
                            lastName: profile.lastName,
                            email: profile.email,
                            avatar: profile.picture
                        });       
                        this.props.authStaff(result.data.auth.token);
                        this.props.deploySnackBar("success", "Your avatar has been changed")
                        this.props.toggleDialog()
                    } else if(result.data.success) {
                        this.props.loadStatus();
                        this.props.deploySnackBar("success", "The staff member's avatar has been changed")
                        this.props.toggleDialog()
                    }
                })
                   
            }

        })
    }

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }

    render() {
        const { formErrors, formData, currentProfilePhoto } = this.state;
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12} align='center'>
                        <Avatar alt="Current Profile Photo" src={(formData.photo && URL.createObjectURL(formData.photo)) || currentProfilePhoto} style={{height: 150, width: 150, margin: 0}} />
                    </Grid>
                    <Grid item xs={12}>
                        <DragFileInput
                            id="photoInput"
                            name="photo"
                            label="Choose Profile Photo (.png, .jpg, .jpeg, .bmp, .gif) *"
                            file={formData.photo}
                            errorText={formErrors && formErrors['generic']}
                            onChange={this.handleFileChange}
                            emptyText='No photo selected'
                        />
                    </Grid>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Upload new avatar?" 
                    message="Are you sure you want to upload a new avatar?"
                />
                <DialogActions className='pr-0 pb-0'>
                    <Button 
                        onClick={() => this.props.toggleDialog()} 
                        variant="text"
                    >
                        Cancel
                    </Button>
                    <Button 
                        color="primary"
                        disabled={!(formData.photo && formData.photo.name)}
                        onClick={this.handleConfirmationOpen} 
                        variant="text" 
                    >
                        Change Avatar
                    </Button>
                </DialogActions>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.auth.user
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        authStaff: (token) => dispatch(authStaff(token)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        loadStatus: () => dispatch(loadStatus())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadStaffProfilePhotoForm);