import _ from 'lodash';

export function handleChange(e) {
    if(this.state.formData[e.target.name] !== e.target.value) {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
}

export function handleChangeUcFirst(e) {
    let value = e.target.value;
    value = value.charAt(0).toUpperCase() + value.slice(1)
    this.setState({
        formData: {
            ...this.state.formData,
            [e.target.name]: value
        }
    });
}

export function handleSortCode(e) {
    let sortCode = e.target.value.replace(/(\d{2})(\d{2})(\d{2})/, "$1-$2-$3")
    this.setState({
        formData: {
            ...this.state.formData,
            [e.target.name]: sortCode
        }
    });
}

export function handleCheckedChange(e) {
    let name = e.target.name, val = e.target.checked;
    this.setState({
        formData: {
            ...this.state.formData,
            [name]: val ? 1 : 0
        }
    });
}

export function handleSelectChange(fieldName, selectedOption, ucFirst = false, callback = null) {
    let value = selectedOption && selectedOption.value;
    if(value && ucFirst) {
        let words = value.split(" ");
        _.each(words, (word, idx) => {
            words[idx] = word.charAt(0).toUpperCase() + word.slice(1)
        });
        value = words.join(" ");
    }
    this.setState({
        formData: {
            ...this.state.formData,
            [fieldName]: value
        }
    }, () => {
        if(callback) {
            callback();
        }
    })
};


export function handleMultiSelectChange(fieldName, selectedOptions, callback = null) {
    console.log(fieldName, selectedOptions, this.state)
    let values = selectedOptions && selectedOptions.map(a => a.value);
    this.setState({
        formData: {
            ...this.state.formData,
            [fieldName]: values
        }
    }, () => {
        if(callback) {
            callback();
        }
    })
}

export function handleFileChange(drop, name, file) {
    this.setState({
        formData: {
            ...this.state.formData,
            [name]: file
        }
    });
}

export function handleFloat(e) {
    this.setState({
        formData: {
            ...this.state.formData,
            [e.target.name]: !_.isEmpty(e.target.value) ? parseFloat(e.target.value).toFixed(2) : ''
        }
    })
}

export function handleUpdateChange(e) {
    this.setState({
        updateData: {
            ...this.state.updateData,
            [e.target.name]: e.target.value
        }
    });
}

export function handleUpdateCheckedChange(e) {
    let name = e.target.name, val = e.target.checked;
    this.setState({
        updateData: {
            ...this.state.updateData,
            [name]: val ? 1 : 0
        }
    });
}

export function handleUpdateSelectChange(fieldName, selectedOption) {
    this.setState({
        updateData: {
            ...this.state.updateData,
            [fieldName]: selectedOption && selectedOption.value
        }
    });
};

export function handleSnackbarClose(type) {
    this.setState({ 
        snackbar: {
        ...this.state.snackbar,
        [type]: false,
        } 
    });
};

export function handleConfirmationOpen(type) {
    this.setState({ 
        confirmation: {
            ...this.state.confirmation,
            [type]: true,
        } 
    });
};

export function handleConfirmationClose(type) {
    this.setState({ 
        confirmation: {
            ...this.state.confirmation,
            [type]: false,
        } 
    });
};

export function handleConfirmationSuccess(type) {
    this.setState({ 
        confirmation: {
            ...this.state.confirmation,
            [type]: false,
        } 
    }, () => {
        if(type === 'submit') {
            this.submit();
        } else if(type === 'update') {
            this.update();
        } else if(type === 'sync') {
            this.sync();
        } else if(type === 'cancel') {
            this.cancel();
        }
    });
}

export function handleDialogClose(type) {
    this.setState({ 
        dialog: {
            ...this.state.dialog,
            [type]: false,
        } 
    });
}

export function cancelSubmit(e) {
    e.preventDefault();
}

function buildFormData(formData, data, parentKey) {
    if(data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}

export function getFormData() {
    const formData = new FormData();
    buildFormData(formData, this.state.formData);
    return formData;
}


export function getFormDataHook(existingFormData) {
    const formData = new FormData();
    buildFormData(formData, existingFormData);
    return formData;
}