import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

class Error404 extends React.PureComponent {
    render(){
        this.props.pageTitle([0, "Page Not Found"])
        return (
            <Grid container justify="center" alignItems="center" style={{height: 'calc(100vh - 210px)'}}>
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={1} style={{padding: '100px 24px 100px 24px'}}>
                        <Grid item xs={12} align='center'>
                            <FAIcon type="light" 
                                icon="exclamation-triangle"
                                size="xlarge"
                                noMargin
                                heading
                            />
                        </Grid>
                        <Grid item xs={12} align='center'>
                            <Typography variant="h6" gutterBottom>
                                Page Not Found
                            </Typography>
                            <Typography variant="caption" component="div" gutterBottom>
                                Sorry, the page you requested could not be found or is no longer part of the Crushed Ice Client Area
                            </Typography>  
                            <Typography variant="caption" component="div" gutterBottom>
                                For help and support, please contact Crushed Ice on 01933 664 344
                            </Typography>  
                        </Grid>
                    </Grid>       
                </Grid>
            </Grid>
        )
    }
}

export default Error404;