import * as authActionTypes from 'Actions/Auth/Types/Types';

const initialState = {
    user: {
        iss: "Crushed Ice Ltd",
        aud: "Crushed Ice - Client Area",
        exp: 0,
        nbf: 0,
        iat: 0,
        type: null,
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        picture: "",
        token: null,
        config: {}
    }
};

function authReducer(state = initialState, action) {
    switch(action.type) {
        case authActionTypes.EXTRACT_TOKEN:
            return {
                ...state,
                user: action.payload
            };
        case authActionTypes.UPDATE_PICTURE:
            return {
                ...state,
                user: {
                    ...state.user,
                    picture: action.payload
                }
            };
        default:
            return state;
    }
}

export default authReducer;