import React from 'react';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const InsightChip = ({border = 0, background, className, color, icon, label, marginLeft, marginRight, paddingLeft, onClick, size = 13, tooltip = null, type = 'solid'}) => {
    switch(tooltip) {
        case null:
        case undefined:
            return (
                <Chip
                    avatar={<FAIcon type={type} className={className} size={size} icon={icon} style={{color: color}} noMargin />}
                    className={className}
                    label={label}
                    size="small"
                    variant="outlined"
                    style={{border: border, background: background, fontWeight: 400, paddingLeft: paddingLeft, marginLeft: marginLeft, marginRight: marginRight, color: color, cursor: onClick ? 'pointer' : undefined}}
                    onClick={onClick}
                />
            )
        default:
            return (
                <Tooltip title={tooltip}>
                    <Chip
                        avatar={<FAIcon type={type} className={className} size={size} icon={icon} style={{color: color}} noMargin />}
                        className={className}
                        label={label}
                        size="small"
                        variant="outlined"
                        style={{border: border, background: background, fontWeight: 400, paddingLeft: paddingLeft, marginRight: marginRight, color: color, cursor: onClick ? 'pointer' : undefined}}
                        onClick={onClick}
                    />
                </Tooltip>
            )
    }
}

export default InsightChip;